import React from 'react';
import { ChevronLeft } from 'lucide-react';
import { 
  Box, 
  SvgIcon, 
  Typography 
} from '@mui/material';

const SidebarToggle = ({ isExpanded, isPinned, onClick, IconComponent }) => {
  // Text changes based on whether the sidebar is pinned
  const actionText = isPinned ? "Unpin" : "Pin";

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: 2,
        py: 1.5,
        cursor: 'pointer',
        position: 'relative',
        '&:hover .hover-text': {
          opacity: 1,
          transform: 'translateX(0)',
        }
      }}
      onClick={onClick}
    >
      {/* Main Content */}
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          flex: 1,
        }}
      >
        {/* Logo */}
        <SvgIcon
          component={IconComponent}
          inheritViewBox
          sx={{
            width: 32,
            height: 32,
            fill: 'white',
            color: 'white',
            filter: 'brightness(0) invert(1)',
            transition: 'transform 0.2s ease',
            transform: isExpanded ? 'scale(1.05)' : 'scale(1)',
          }}
        />

        {/* Brand Text */}
        <Typography
          variant="h6"
          sx={{
            color: 'white',
            opacity: isExpanded ? 1 : 0,
            width: isExpanded ? 'auto' : 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            transition: 'all 0.2s ease-in-out',
            fontWeight: 600,
          }}
        >
          pokamind
        </Typography>
      </Box>

      {/* Hover Text with Action */}
      <Box
        className="hover-text"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          position: 'absolute',
          right: isExpanded ? 8 : 'auto',
          left: !isExpanded ? 12 : 'auto',
          opacity: 0,
          transform: isExpanded ? 'translateX(-10px)' : 'translateX(10px)',
          transition: 'all 0.2s ease-in-out',
          color: 'rgba(255, 255, 255, 0.8)',
          bgcolor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '4px',
          px: 1,
          py: 0.5,
        }}
      >
        <Typography
          variant="caption"
          sx={{ 
            fontWeight: 500,
            fontSize: '0.75rem',
            letterSpacing: '0.4px',
            whiteSpace: 'nowrap'
          }}
        >
          {actionText}
        </Typography>
        <ChevronLeft 
          size={14}
          style={{
            transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: 'transform 0.2s ease'
          }}
        />
      </Box>
    </Box>
  );
};

export default SidebarToggle;