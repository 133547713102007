// HomeFooter.jsx
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';

// Styled Components
const FooterContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  minHeight: '1px', // Ensures the footer has minimal height but isn't completely collapsed
  background: 'transparent',
}));

// Main Footer Component
const HomeFooter = () => {
  return (
    <FooterContainer
      container
      component="footer"
      sx={{
        maxWidth: '100%',
        mx: 'auto',
      }}
    >
      <Grid 
        container 
        sx={{ 
          maxWidth: '1200px', 
          mx: 'auto',
        }}
      />
    </FooterContainer>
  );
};

export default HomeFooter;