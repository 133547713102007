import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Alert, Spinner } from 'react-bootstrap';
import { useUser } from '../../contexts/UserContext';
import { useErrorBoundary } from "react-error-boundary";
import "./charts/AdminView.scss"; // Ensure this file and styles are updated accordingly
import { getDownloadURL, ref, storage } from "../../Firebase";
import { URL } from "../../Helpers";

const CollectionPage = () => {
    const { user } = useUser();
    const [loading, setLoading] = useState(true);
    const [moodrepURLs, setMoodrepURLs] = useState([]);
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        const fetchCalendar = async () => {
            if (!user) return;
            setLoading(true);
            try {
                const idToken = await user.getIdToken();
                const response = await fetch(`${URL}/user_calendar`, {
                    headers: { 'Authorization': `Bearer ${idToken}` },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const docs = await response.json();
                const imagePromises = docs.map(async doc => {
                    if (!doc.moodrep) return null;
                    try {
                        const moodrepRef = ref(storage, doc.moodrep);
                        return await getDownloadURL(moodrepRef);
                    } catch (error) {
                        showBoundary(error);
                        console.error(`Failed to get download URL for ${doc.moodrep}`, error);
                        return null;
                    }
                });

                const urls = (await Promise.all(imagePromises)).filter(Boolean);
                setMoodrepURLs(urls);
            } catch (error) {
                showBoundary(error);
                console.error("Error occurred: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCalendar();
    }, [user, showBoundary]);

    return (
        <div className="prevent_overflow">
            <div className="content-wrapper">
                <Container fluid="md" className="my-4">
                    <h1 className="text-blue comfortaa_font text-center mb-4">MoodRep Collection</h1>
                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <Spinner animation="border" />
                        </div>
                    ) : moodrepURLs.length > 0 ? (
                        <Row xs={1} md={2} lg={4} className="g-5">
                            {moodrepURLs.map((url, index) => (
                                <Col key={index}>
                                  {/* remove the white background */}
                                    <Card className="border-0 bg-transparent">
                                        <Card.Img variant="top" src={url} alt={`MoodRep ${index}`} />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Alert variant="info" className="text-center">
                            Seems like you've never had a session before. <br />
                            Start your first session to begin collecting your moodReps!
                        </Alert>
                    )}
                </Container>
            </div>
        </div>
    );
};

export default CollectionPage;