import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Select, MenuItem, IconButton, Typography, CircularProgress, Box } from "@mui/material";
import { Globe } from 'lucide-react';
import axios from 'axios';
import { URL } from '../../../Helpers';
import { useLocation } from 'react-router-dom';

const SUPPORTED_LANGUAGES = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  ar: 'العربية',
  sv: 'Svenska'
};

const DEFAULT_STYLES = {
  button: {
    color: 'inherit',
    padding: 1,
  },
  text: {
    marginLeft: 1,
  }
};

const TranslateComponent = ({ customStyles = {} }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  const [translations, setTranslations] = useState(() => JSON.parse(localStorage.getItem('translations') || '{}'));
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const originalTexts = useRef(new Map());
  const isInitialMount = useRef(true);
  const translationInProgress = useRef(false);
  const translationQueue = useRef([]);

  const styles = {
    button: { ...DEFAULT_STYLES.button, ...customStyles.button },
    text: { ...DEFAULT_STYLES.text, ...customStyles.text }
  };

  const translateWebsite = useCallback(async (targetLanguage) => {
    if (translationInProgress.current) {
      translationQueue.current.push(targetLanguage);
      return;
    }

    setIsLoading(true);
    translationInProgress.current = true;

    try {
      if (targetLanguage === 'en') {
        resetToOriginalTexts();
        setLanguage('en');
        localStorage.setItem('language', 'en');
        return;
      }

      const textNodes = getTextNodes(document.body);
      const textsToTranslate = [];
      const batchSize = 50;
      const batches = [];

      textNodes.forEach(node => {
        const text = node.textContent.trim();
        if (text && text.length > 1) {  // Skip single characters
          if (!originalTexts.current.has(node)) {
            originalTexts.current.set(node, text);
          }
          const originalText = originalTexts.current.get(node);
          if (!translations[targetLanguage] || !translations[targetLanguage][originalText]) {
            textsToTranslate.push(originalText);
          }
        }
      });

      // Split texts into batches
      for (let i = 0; i < textsToTranslate.length; i += batchSize) {
        batches.push(textsToTranslate.slice(i, i + batchSize));
      }

      // Process batches sequentially
      const results = {};
      for (const batch of batches) {
        const response = await axios.post(`${URL}/translate`, {
          texts: batch,
          targetLanguage,
          sourceLanguage: 'en'
        });
        Object.assign(results, response.data);
      }

      const newTranslations = {
        ...translations,
        [targetLanguage]: {
          ...(translations[targetLanguage] || {}),
          ...results
        }
      };

      setTranslations(newTranslations);
      localStorage.setItem('translations', JSON.stringify(newTranslations));
      applyTranslations(targetLanguage);
      setLanguage(targetLanguage);
      localStorage.setItem('language', targetLanguage);

    } catch (error) {
      console.error('Translation error:', error);
      // Revert to previous language on error
      setLanguage(prevLanguage => {
        localStorage.setItem('language', prevLanguage);
        return prevLanguage;
      });
    } finally {
      setIsLoading(false);
      translationInProgress.current = false;
      
      // Process next in queue if any
      if (translationQueue.current.length > 0) {
        const nextLanguage = translationQueue.current.shift();
        translateWebsite(nextLanguage);
      }
    }
  }, [translations]);

  const applyTranslations = useCallback((targetLanguage) => {
    originalTexts.current.forEach((originalText, node) => {
      if (translations[targetLanguage]?.[originalText]) {
        node.textContent = translations[targetLanguage][originalText];
      }
    });
  }, [translations]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage && storedLanguage !== language) {
      setLanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      translateWebsite(language);
    }
  }, [language, translateWebsite]);

  useEffect(() => {
    if (!isInitialMount.current) {
      const timeoutId = setTimeout(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage && storedLanguage !== language) {
          setLanguage(storedLanguage);
        } else {
          translateWebsite(language);
        }
      }, 300); // Debounce time
      return () => clearTimeout(timeoutId);
    }
  }, [location.pathname, translateWebsite, language]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    translateWebsite(newLanguage);
    handleClose();
  };

  const resetToOriginalTexts = () => {
    originalTexts.current.forEach((originalText, node) => {
      node.textContent = originalText;
    });
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton
        onClick={handleClick}
        disabled={isLoading}
        sx={styles.button}
      >
        {isLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <>
            <Globe size={20} />
            {customStyles.text?.display !== 'none' && (
              <Typography
                variant="body2"
                sx={styles.text}
              >
                {SUPPORTED_LANGUAGES[language]}
              </Typography>
            )}
          </>
        )}
      </IconButton>
      <Select
        open={Boolean(anchorEl)}
        onClose={handleClose}
        value={language}
        onChange={(e) => handleLanguageChange(e.target.value)}
        anchorEl={anchorEl}
        sx={{
          visibility: 'hidden',
          position: 'fixed',
          '& .MuiPaper-root': {
            minWidth: 150,
            mt: 1,
            borderRadius: '20px',
            bgcolor: 'background.paper',
            boxShadow: 4,
          }
        }}
      >
        {Object.entries(SUPPORTED_LANGUAGES).map(([code, name]) => (
          <MenuItem 
            key={code} 
            value={code}
            sx={{
              py: 1.5,
              px: 2,
              minWidth: 150,
              justifyContent: 'center',
              '&:hover': {
                bgcolor: 'rgba(92, 131, 191, 0.08)',
              },
              '&.Mui-selected': {
                bgcolor: 'rgba(92, 131, 191, 0.12)',
                '&:hover': {
                  bgcolor: 'rgba(92, 131, 191, 0.16)',
                }
              }
            }}
          >
            <Typography variant="body2">
              {name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

function getTextNodes(node) {
  const textNodes = [];
  const walker = document.createTreeWalker(
    node,
    NodeFilter.SHOW_TEXT,
    {
      acceptNode: function(node) {
        return node.textContent.trim() !== '' && !isHiddenNode(node)
          ? NodeFilter.FILTER_ACCEPT
          : NodeFilter.FILTER_REJECT;
      }
    },
    false
  );

  let currentNode;
  while (currentNode = walker.nextNode()) {
    textNodes.push(currentNode);
  }

  return textNodes;
}

function isHiddenNode(node) {
  if (node.nodeType === Node.TEXT_NODE && node.parentElement) {
    node = node.parentElement;
  }

  let current = node;
  while (current && current !== document.body) {
    if (current.nodeType === Node.ELEMENT_NODE) {
      const style = window.getComputedStyle(current);
      if (style.display === 'none' || 
          style.visibility === 'hidden' || 
          style.opacity === '0' ||
          current.getAttribute('aria-hidden') === 'true') {
        return true;
      }
    }
    current = current.parentNode;
  }
  return false;
}

export default TranslateComponent;