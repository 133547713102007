import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Box, Checkbox, SvgIcon, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import './HomeCarousel.scss';
import { BlogData, intros } from '../../data/BlogData'; // Import both from the same file
import sessionImg from "../../data/session.png";
import micImg from "../../data/mic2.png";
import articlesImg from "../../data/article2.png";
import { useUser } from '../../../contexts/UserContext';
import axios from 'axios';
import { URL } from "../../../Helpers";
import podcastImg from "../../data/pokapod.png";
import { ReactComponent as Checked } from '../../data/task_alt.svg';
import { ReactComponent as Pending } from '../../data/Pending.svg';
import { ReactComponent as Pending_2 } from '../../data/Pending_2.svg';
import { doc, getDoc, db, onSnapshot } from '../../../Firebase'
import LoadingSpinner from '../shared/LoadingSpinner';
import { useErrorBoundary } from 'react-error-boundary';


const HomeCarousel = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const { showBoundary } = useErrorBoundary();

    const [blogs, setBlogs] = useState([]);
    const [blogID, setBlogID] = useState(null);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [sessionsDone, setSessionsDone] = useState(0);
    const [podcastLinks, setPodcastLinks] = useState([]);
    const [podcastTitles, setPodcastTitles] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    useEffect(() => {
        const fetchWeeklyChallenge = async () => {
            try {
                const idToken = await user.getIdToken();
                const currentDate = new Date();
                const currentWeekStart = new Date(
                    currentDate.setUTCDate(currentDate.getUTCDate() - currentDate.getUTCDay())
                ).toISOString().split('T')[0];

                const userDocRef = doc(db, 'users', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (!userDocSnap.exists()) {
                    // User document doesn't exist, create it
                    fetchDataFromBackend(idToken,currentWeekStart)
                }

                const sessionCount = userDocSnap.data()?.session_count || {};
                const thisWeek = sessionCount.this_week || {};

                if (!thisWeek.week_start_challenge || thisWeek.week_start_challenge !== currentWeekStart) {
                    // It's a new week, fetch data from the backend
                    // console.log(thisWeek.week_start_challenge, currentWeekStart, currentDate.getUTCDate(), currentDate.getUTCDay())
                    fetchDataFromBackend(idToken, currentWeekStart);
                } else {
                    // It's the same week, read data from Firestore
                    setPodcastLinks(thisWeek.podcast_links);
                    setPodcastTitles(thisWeek.podcast_titles);
                    setSessionsDone(thisWeek.count);
                    setBlogID(thisWeek.blog_id);
                    setLoading(false)
                }

            } catch (error) {
                console.error('Error updating weekly:', error);
                showBoundary(error)
            }
        };

        const fetchDataFromBackend = async (idToken, currentWeekStart) => {
            try {
                const response = await axios.get(`${URL}/update_weekly_challenge`, {
                    headers: { 'Authorization': `Bearer ${idToken}` },
                });
                const data = response.data;
                setPodcastLinks(data.podcast_links);
                setPodcastTitles(data.podcast_titles);
                setSessionsDone(data.sessions_done);
                setBlogID(data.blog_id);
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data from backend:', error);
                showBoundary(error)
            }
        };

        fetchWeeklyChallenge();
        setBlogs(BlogData);
    }, [user]);

    const handlePodcastClick = (url) => {
        window.open(url, '_blank');
    };

    const handleTherapist = () => {
        navigate("/therapist");
    };

    const handleClose = () => setSelectedBlog(null);

    const SessionCard = () => (
        <Card variant="outlined" className='home-card'>
            <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                <Box display="flex" flexDirection="column" alignItems="start" gap={"10px"} mt={2}>
                    <Typography mb={4} color={"#4C6481"} gutterBottom component="div" className='home-cardcontent'>
                        <Avatar alt="Sessions Done" src={sessionImg} />
                        <Typography variant='sb24Bold'>Sessions Done</Typography>
                    </Typography>
                    {Array.from({ length: 3 }, (_, i) => (
                        <>
                            <Box key={i} display="flex" alignItems="center" mb={1} justifyContent={'center'}>
                                {i < sessionsDone ? (
                                    // <Checkbox
                                    //     checked={true}
                                    //     style={{ color: 'green', padding: '4px' }}
                                    //     size="small"
                                    // />
                                    <Box bgcolor={"#E3F8E8"} border={"2px solid #429756"} borderRadius={"100%"} padding={"4px"}>
                                        <SvgIcon component={Checked} />
                                    </Box>
                                ) : (
                                    <Box bgcolor={"#E1EDFF"} border={"2px solid #5C83BF"} borderRadius={"100%"} padding={"4px 2.5px 4px 6px"}>
                                        <SvgIcon component={Pending} />
                                    </Box>
                                )}
                                <Typography color={"#4C6481"} variant="body1Medium" className='home-cardcontent' ml={1}>
                                    Session {i + 1} {i < sessionsDone ? 'completed' : 'remaining'}
                                </Typography>
                            </Box>
                            <div className="horizontal-line" style={{ background: "#E5EAF6" }} />
                        </>
                    ))}
                </Box>
                <Button onClick={() => navigate('/mymood')} variant='primary' sx={{ width: "100%" }}>
                    <Typography variant='sb16Bolder'>Go to Calendar</Typography>
                </Button>
            </CardContent>
        </Card>
    );

    const PodcastCard = () => (
        <Card variant="outlined" className='home-card'>
            <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <Grid gap={"10px"} mt={2} display={'flex'} flexDirection={'column'}>
                    <Typography mb={4} color={"#4C6481"} gutterBottom component="div" className='home-cardcontent'>
                        <Avatar alt="Weekly Podcasts" src={micImg} />
                        <Typography variant='sb24Bold'>Weekly Podcasts</Typography>
                    </Typography>
                    <Grid display={'flex'} flexDirection={'column'} gap={"10px"}>
                        {podcastTitles.slice(0, 2).map((title, index) => (
                            <Box key={index} display={'flex'} gap={"10px"} flexDirection={'column'} sx={{ cursor: "pointer" }} onClick={() => handlePodcastClick(podcastLinks[index])}
                                onMouseEnter={(e) => e.currentTarget.querySelector('.horizontal-line').style.background = '#4C6EA2'}
                                onMouseLeave={(e) => e.currentTarget.querySelector('.horizontal-line').style.background = '#E5EAF6'}
                            >
                                <Box display="flex" alignItems="center" mb={1}>
                                    <img src={podcastImg} alt="Podcast" style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '14px' }} />
                                    <Typography color={"#4C6481"} variant="body1Medium" className='home-cardcontent'>
                                        {title}
                                    </Typography>
                                </Box>
                                <Grid mb={2} className="horizontal-line" style={{ background: "#E5EAF6" }} />
                            </Box>
                        ))}
                    </Grid>
                </Grid>
                <Button onClick={() => window.location.href = 'https://rss.com/podcasts/pokapod/'} variant='secondary' sx={{ width: "100%" }}>
                    <Typography variant='sb16Bolder'>View All Podcasts</Typography>
                </Button>
            </CardContent>
        </Card>
    );

    const ArticleCard = () => (
        <Card variant="outlined" className='home-card'>
            <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <Grid gap={"10px"} mt={2} display={'flex'} flexDirection={'column'}>
                    <Typography mb={4} color={"#4C6481"} gutterBottom component="div" className='home-cardcontent'>
                        <Avatar alt="Latest Articles" src={articlesImg} />
                        <Typography variant='sb24Bold'>Weekly Article</Typography>
                    </Typography>
                    <Box sx={{ cursor: "pointer" }} onClick={() => setSelectedBlog(blogs[blogID])}
                        onMouseEnter={(e) => e.currentTarget.querySelector('.horizontal-line').style.background = '#4C6EA2'}
                        onMouseLeave={(e) => e.currentTarget.querySelector('.horizontal-line').style.background = '#E5EAF6'}
                    >
                        <Box display="flex" alignItems="center">
                            {/* push the image up */}
                            <img src={blogs[blogID]?.imageUrl} alt="Blog" style={{ width: '100px', height: '150px', objectFit: 'cover', marginRight: '14px' }} />
                            <Grid display={'flex'} flexDirection={'column'} gap={"5px"}>
                                <Typography color={"#4C6481"} variant="sb20SemiBold" className='home-cardcontent'>
                                    {blogs[blogID]?.title}
                                </Typography>
                                <Typography color={"#4C6481"} variant="body1Medium" className='home-cardcontent'>
                                    {blogs[blogID]?.shortIntro}
                                </Typography>
                            </Grid>
                        </Box>
                        <Grid mb={2} mt={1} className="horizontal-line" style={{ background: "#E5EAF6" }} />
                    </Box>
                </Grid>
                <Button onClick={() => navigate('/blogs')} variant='secondary' sx={{ width: "100%" }}>
                    <Typography variant='sb16Bolder'>View All Articles</Typography>
                </Button>
            </CardContent>
        </Card>
    );

    return (
        <>
            {loading ? <LoadingSpinner text="Fetching Weekly Milestones" />
                :
                <Grid container justifyContent="center" mb={5} mx={3} mt={2}>

                    <Grid container item justifyContent="center" spacing={isMobile ? 2 : 4}>
                        <Grid item xs={12} sm={8} md={5.5} lg={3.7} xl={3.2}>
                            <SessionCard />
                        </Grid>
                        <Grid item xs={12} sm={8} md={5.5} lg={3.7} xl={3.2}>
                            <PodcastCard />
                        </Grid>
                        <Grid item xs={12} sm={8} md={5.5} lg={3.7} xl={3.2}>
                            <ArticleCard />
                        </Grid>
                    </Grid>
                    <Dialog open={selectedBlog !== null} onClose={handleClose} fullWidth maxWidth="lg">
                        <DialogTitle>{selectedBlog?.title}</DialogTitle>
                        <DialogContent dividers>
                            <img src={selectedBlog?.imageUrl} className="img-fluid blog-img" alt="blog_image" />
                            <div className="mt-4">{selectedBlog?.content}</div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
            }
        </>
    );
};

export default HomeCarousel;
