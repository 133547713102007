import React, { useRef, useState, useCallback } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, SvgIcon, Box, Button } from "@mui/material";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as videoIcon } from '../../data/videoIcon.svg';
import { ReactComponent as stopCircle } from '../../data/stop_circle.svg';
import { BarLoader } from 'react-spinners';
import Webcam from 'react-webcam';
import { useUser } from '../../../contexts/UserContext';
import { URL } from '../../../Helpers';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AudioVisualizer from './AudioVisualizer';
// Language voice mapping for Google TTS
const LANGUAGE_VOICES = {
  'en-GB': { 
    languageCode: 'en-US',
    name: 'en-US-Journey-D',
    ssmlGender: 'MALE'
  }
};

export const RecordingUI = ({
  countdown,
  currentQuestion,
  buttonDisabled,
  showQuestions,
  toggleQuestions,
  canvasParentRef,
  webcamVisible,
  showAvatar,
  videoAccess,
  camRef,
  micCanvasRef,
  audioCanvasRef,
  audioRef,
  audioEndingRef,
  WarningMessage,
  overviewData,
  toggleWebcam,
  setShowAvatar,
  stopRecording,
  responseLoading,
  handleScreenshots,
  formatTime,
  onMediaError,
  language = 'en-GB'
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioElement = useRef(new Audio());
  const isFirstMount = useRef(true);
  const previousQuestionRef = useRef('');
  const { user, tier, role } = useUser();

  const getGoogleTTSAudio = async (text) => {
    try {
      const response = await fetch(`${URL}/text-to-speech`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify({
          input: { text },
          voice: {
            languageCode: LANGUAGE_VOICES[language].languageCode,
            name: LANGUAGE_VOICES[language].name,
            ssmlGender: LANGUAGE_VOICES[language].ssmlGender
          },
          audioConfig: {
            audioEncoding: 'MP3',
            pitch: 0,
            speakingRate: 1.0
          }
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to get TTS audio');
      }
  
      const audioBlob = await response.blob();
      // Use window.URL instead of imported URL
      return window.URL.createObjectURL(audioBlob);
    } catch (error) {
      console.error('Error getting TTS audio:', error);
      throw error;
    }
  };

  // Enhanced speech function
// Modified speakQuestion function
const speakQuestion = useCallback(async (text) => {
  if (!text) return;
  
  // Stop any ongoing speech
  if (audioElement.current) {
    if (audioElement.current.src) {
      // Remove all existing event listeners before changing source
      audioElement.current.onplay = null;
      audioElement.current.onended = null;
      audioElement.current.onerror = null;
      window.URL.revokeObjectURL(audioElement.current.src);
    }
    audioElement.current.pause();
    audioElement.current.currentTime = 0;
    setIsPlaying(false);
  }

  try {
    const audioUrl = await getGoogleTTSAudio(text);
    
    // Create a new promise to handle the audio loading
    const playAudio = new Promise((resolve, reject) => {
      const handleError = (error) => {
        // Only log the error once
        console.error('Audio playback error:', error);
        setIsPlaying(false);
        window.URL.revokeObjectURL(audioUrl);
        audioElement.current.src = '';
        // Remove error listener after first error
        audioElement.current.onerror = null;
        reject(error);
      };

      audioElement.current.onplay = () => setIsPlaying(true);
      audioElement.current.onended = () => {
        setIsPlaying(false);
        window.URL.revokeObjectURL(audioUrl);
        audioElement.current.src = '';
        resolve();
      };
      audioElement.current.onerror = handleError;
    });

    audioElement.current.src = audioUrl;
    await audioElement.current.play();
    await playAudio;

  } catch (error) {
    // Only log non-playback errors
    if (!error.target || error.target.tagName !== 'AUDIO') {
      console.error('Error speaking question:', error);
    }
    setIsPlaying(false);
  }
}, [language, user, getGoogleTTSAudio]);

  // Handle manual play/pause
  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      audioElement.current.pause();
      setIsPlaying(false);
    } else if (currentQuestion) {
      speakQuestion(currentQuestion);
    }
  }, [isPlaying, currentQuestion, speakQuestion]);

  // Handle initial text speech
  React.useEffect(() => {
    if (isFirstMount.current && currentQuestion) {
      speakQuestion(currentQuestion);
      isFirstMount.current = false;
      previousQuestionRef.current = currentQuestion;
    }
  }, [currentQuestion, speakQuestion]);

  // Handle question changes
  React.useEffect(() => {
    if (!isFirstMount.current && currentQuestion && currentQuestion !== previousQuestionRef.current) {
      speakQuestion(currentQuestion);
      previousQuestionRef.current = currentQuestion;
    }
  }, [currentQuestion, speakQuestion]);

  // Enhanced toggle questions handler
  const handleToggleQuestions = useCallback(() => {
    toggleQuestions();
  }, [toggleQuestions]);

  // Cleanup effect
// Cleanup effect
React.useEffect(() => {
  return () => {
    if (audioElement.current) {
      if (audioElement.current.src) {
        window.URL.revokeObjectURL(audioElement.current.src);
      }
      audioElement.current.pause();
      audioElement.current.src = '';
      setIsPlaying(false);
    }
  };
}, []);

  return (
    <Grid gap={5} marginBottom={"100px"} container display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
      <Grid md={7.5} lg={6.7} xl={5} xs={10} alignItems={"center"} border={"solid 1px #E3DBD5"} borderRadius={"10px"} justifyContent={"center"} sx={{ background: "#FFFFFF" }}>
        {/* Header */}
        <Grid md={7.5} lg={6.7} xl={5} xs={10} padding={"16px 24px 16px 24px"} borderRadius={"10px 10px 0px 0px"} sx={{ background: "#5C83BF" }} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography color={"white"} variant="sb24Bold">Session in progress</Typography>
          <Grid sx={{ '@media (max-width: 450px)': { display: "flex", flexDirection: "column", justifyContent: "center" } }} display={"flex"} gap={"10px"} alignItems={"center"}>
            <div className="heart-container">
              <RadioButtonCheckedIcon className="heart-icon" />
            </div>
            <Typography variant="sb20Bold" color={"white"}>
              {formatTime(countdown)}
            </Typography>
          </Grid>
        </Grid>

        {/* Main Content */}
        <Grid justifyContent={"center"} alignItems={"center"} padding={"20px 24px 20px 24px"} display={"flex"} flexDirection={"column"}>
          <Box display="flex" alignItems="center" gap={2} justifyContent="center" width="100%">
            <Typography my={1} variant="h32Bold" color={"#4C6EA2"} textAlign={"center"}>
              {currentQuestion}
            </Typography>
            
            {/* Audio control button */}
            {showQuestions && currentQuestion && (
              <Button
                onClick={handlePlayPause}
                sx={{
                  minWidth: '40px',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  padding: 0,
                  backgroundColor: '#5C83BF',
                  '&:hover': {
                    backgroundColor: '#4C6EA2',
                  },
                }}
              >
                <FontAwesomeIcon
                  icon={isPlaying ? "fa-solid fa-pause" : "fa-solid fa-play"}
                  style={{ color: 'white', width: '16px', height: '16px' }}
                />
              </Button>
            )}
          </Box>
          
          <BarLoader
            color={"#5C83BF"}
            height={6}
            width={130}
            loading={buttonDisabled}
            css={{
              display: 'flex',
              margin: 'auto',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          />
          
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleToggleQuestions}
            disabled={buttonDisabled}
            endIcon={<NavigateNextIcon />}
            sx={{
              visibility: buttonDisabled ? 'hidden' : 'visible',
              opacity: buttonDisabled ? 0 : 1,
              transition: 'all 0.3s ease',
              position: 'relative',  // Add relative positioning
              zIndex: 10,           // Add z-index through sx prop
              marginY: 2,           // Add vertical margin
              ...(buttonDisabled && { 
                transform: 'scale(0.9)',
                pointerEvents: 'none'
              }),
            }}
          >
            Next Question {showQuestions}
          </Button>

          {/* Webcam and Audio Visualizer Container */}
          <AudioVisualizer
            micCanvasRef={micCanvasRef}
            canvasParentRef={canvasParentRef}
            webcamVisible={webcamVisible}
            showAvatar={showAvatar}
            videoAccess={videoAccess}
            camRef={camRef}
          />

          {/* Warning Message */}
          <Box 
            bgcolor={WarningMessage ? "rgba(76, 110, 162, 0.1)" : "transparent"} 
            alignSelf={"flex-end"} 
            borderRadius={"8px"} 
            padding={WarningMessage ? "12px" : "24px"} 
            gap={"16px"}
          >
            {WarningMessage && (
              <Typography color={"#4C6EA2"} variant="sb16Bold">{WarningMessage}</Typography>
            )}
          </Box>
        </Grid>

        {/* Footer */}
        <Grid display={"flex"} md={7} lg={6} xl={5} xs={10} gap={"10px"} alignItems={"center"} justifyContent={"space-between"} padding={"16px 24px 16px 24px"} borderRadius={"0px 0px 10px 10px"} sx={{ background: "#5C83BF" }}>
          <Grid sx={{ '@media (max-width: 450px)': { display: "flex", flexDirection: "column", justifyContent: "center" } }} display={"flex"} gap={"10px"} alignItems={"center"}>
            <div className="heart-container">
              <RadioButtonCheckedIcon className="heart-icon" />
            </div>
            <Typography variant="sb20Bold" color={"white"}>
              {formatTime(countdown)}
            </Typography>
          </Grid>
          
          <Grid display={"flex"} gap={"20px"} alignItems={"center"}>
            {!overviewData && videoAccess && (
              <Button
                onClick={() => { setShowAvatar(!showAvatar); toggleWebcam(); }}
              >
                {webcamVisible ? (
                  <Grid>
                    <SvgIcon component={videoIcon} sx={{ width: "56px", height: "56px" }} inheritViewBox />
                  </Grid>
                ) : (
                  <Box width={"56px"} height={"56px"} padding={"16px"} borderRadius={"100%"} bgcolor={"white"}>
                    <FontAwesomeIcon 
                      style={{ width: "24px", height: "24px", color: "#718BB1" }} 
                      icon="fa-solid fa-video-slash" 
                    />
                  </Box>
                )}
              </Button>
            )}
          </Grid>

          <Grid>
            <Button
              variant="withIcon"
              sx={{ border: "1.5px solid white" }}
              onClick={() => {
                if (!responseLoading) {
                  stopRecording();
                }
              }}
              disabled={responseLoading}
            >
              <SvgIcon component={stopCircle} sx={{ width: "24px", height: "24px" }} inheritViewBox />
              <Typography color={"white"} variant="sb16Bold">End Session</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};