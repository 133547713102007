import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Box,
  Chip,
  Container,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
  Tabs,
  Tab,
  Tooltip,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";

// Icons
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GroupIcon from '@mui/icons-material/Group';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

import { getDocs, collection, db } from '../../../Firebase';
import LoadingSpinner from '../shared/LoadingSpinner';
import { useUser } from '../../../contexts/UserContext';

export const TopicSelection = ({ onTopicSelect }) => {
  // State management
  const [hoveredTopic, setHoveredTopic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentTab, setCurrentTab] = useState(0);
  const [pinnedTopics, setPinnedTopics] = useState([]);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [sortBy, setSortBy] = useState('default');
  const { user } = useUser();

  const topicIcons = {
    "GroupIcon": GroupIcon,
    "TrendingUpIcon": TrendingUpIcon,
    "GpsFixedIcon": GpsFixedIcon
  };

  // Load pinned topics from localStorage on mount
  useEffect(() => {
    const savedPinnedTopics = localStorage.getItem('pinnedTopics');
    if (savedPinnedTopics) {
      setPinnedTopics(JSON.parse(savedPinnedTopics));
    }
  }, []);

  // Fetch topics from firestore
  useEffect(() => {
    console.log(user);
    setLoading(true);
    const topicsRef = collection(db, 'modules');
  
    getDocs(topicsRef).then((snapshot) => {
      const topicsArr = [];
      snapshot?.forEach((doc) => {
        const data = doc.data();
        topicsArr.push({
          ...data,
          docId: doc.id,  // Store Firestore doc ID in a different field
          id: data.id     // Keep the numeric ID from your data
        });
      });
  
      setTopics(topicsArr);
      setFilteredTopics(topicsArr);
      setLoading(false);
    }).catch((error) => {
      console.error('Error fetching topics:', error);
      setLoading(false);
    });
  }, [user]);

  // Filter and sort topics
  useEffect(() => {
    let result = [...topics];

    // Apply search filter
    if (searchQuery) {
      result = result.filter(topic =>
        topic.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        topic.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        topic.category.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply category filter
    if (selectedCategory !== 'all') {
      result = result.filter(topic => topic.category === selectedCategory);
    }

    // Apply sorting
    switch (sortBy) {
      case 'scenarios':
        result.sort((a, b) => b.scenarios - a.scenarios);
        break;
      case 'title':
        result.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'category':
        result.sort((a, b) => a.category.localeCompare(b.category));
        break;
      default:
        break;
    }

    setFilteredTopics(result);
  }, [topics, searchQuery, selectedCategory, sortBy]);

  // Handle pinning/unpinning
  const handlePinTopic = (topicId, event) => {
    event.stopPropagation();
    const newPinnedTopics = pinnedTopics.includes(topicId)
      ? pinnedTopics.filter(id => id !== topicId)
      : [...pinnedTopics, topicId];
    
    setPinnedTopics(newPinnedTopics);
    localStorage.setItem('pinnedTopics', JSON.stringify(newPinnedTopics));
  };

  const renderTopicCard = (topic) => {
    const TopicIcon = topicIcons[topic.icon];
    const isPinned = pinnedTopics.includes(topic.id);

    return (
      <Card
        onClick={() => onTopicSelect(topic.id)}
        onMouseEnter={() => setHoveredTopic(topic.id)}
        onMouseLeave={() => setHoveredTopic(null)}
        sx={{
          height: '100%',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          borderRadius: 3,
          border: '1px solid rgba(92, 131, 191, 0.1)',
          boxShadow: '0 2px 12px rgba(92, 131, 191, 0.05)',
          position: 'relative',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 24px rgba(92, 131, 191, 0.12)',
            borderColor: '#5C83BF',
            '& .explore-text': {
              color: '#5C83BF',
              transform: 'translateX(4px)'
            }
          }
        }}
      >
        <CardContent sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TopicIcon sx={{
                color: '#4C6EA2',
                fontSize: 32,
                p: 0.8,
                borderRadius: 2,
                bgcolor: 'rgba(92, 131, 191, 0.1)'
              }} />
              <Tooltip title={isPinned ? "Unpin module" : "Pin module"}>
                <IconButton 
                  onClick={(e) => handlePinTopic(topic.id, e)}
                  sx={{ 
                    color: isPinned ? '#5C83BF' : '#718BB1',
                    '&:hover': {
                      backgroundColor: 'rgba(92, 131, 191, 0.1)'
                    }
                  }}
                >
                  {isPinned ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                </IconButton>
              </Tooltip>
            </Box>
            <Chip
              label={`${topic.scenarios} scenarios`}
              size="small"
              sx={{
                bgcolor: 'rgba(92, 131, 191, 0.08)',
                color: '#4C6EA2',
                borderRadius: '12px',
                '& .MuiChip-label': {
                  px: 2,
                  py: 0.5,
                  fontWeight: 500
                }
              }}
            />
          </Box>

          <Typography
            variant="h6"
            sx={{
              color: '#4C6EA2',
              mb: 1.5,
              fontWeight: 600,
              fontSize: '1.25rem'
            }}
          >
            {topic.title}
          </Typography>

          <Chip
            label={topic.category}
            size="small"
            sx={{
              mb: 2,
              bgcolor: 'rgba(92, 131, 191, 0.08)',
              color: '#4C6EA2',
              borderRadius: '12px'
            }}
          />

          <Typography
            variant="body1"
            sx={{
              color: '#718BB1',
              mb: 2,
              lineHeight: 1.6
            }}
          >
            {topic.description}
          </Typography>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            p: 1.5,
            borderRadius: 2,
            bgcolor: 'rgba(92, 131, 191, 0.05)'
          }}>
            <EmojiObjectsIcon sx={{ fontSize: 20, color: '#5C83BF', mr: 1 }} />
            <Typography variant="body2" sx={{ color: '#5C83BF', fontWeight: 500 }}>
              {topic.tip}
            </Typography>
          </Box>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: 2
          }}>
            <Typography
              variant="button"
              className="explore-text"
              sx={{
                color: '#718BB1',
                fontWeight: 500,
                transition: 'all 0.3s ease',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              Start scenario
              <ArrowForwardIcon sx={{ ml: 1, fontSize: 18 }} />
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const uniqueCategories = ['all', ...new Set(topics.map(topic => topic.category))];

  return (
    <Box sx={{ bgcolor: '#FFF7F1', minHeight: '100vh', py: 6 }}>
      <Container maxWidth="lg">
        <Paper
          elevation={0}
          sx={{
            borderRadius: 4,
            overflow: 'hidden',
            border: '1px solid rgba(92, 131, 191, 0.1)',
            bgcolor: 'white',
            boxShadow: '0 4px 20px rgba(92, 131, 191, 0.08)'
          }}
        >
          {/* Header */}
          <Box
            sx={{
              bgcolor: '#5C83BF',
              p: 4,
              borderRadius: '20px 20px 0 0',
              background: 'linear-gradient(135deg, #5C83BF 0%, #4C6EA2 100%)'
            }}
          >
            <Typography variant="h4" align="center" sx={{ color: 'white', mb: 1, fontWeight: 600 }}>
              Management Scenarios
            </Typography>
            <Typography variant="subtitle1" align="center" sx={{ color: 'rgba(255, 255, 255, 0.9)', mb: 3 }}>
              Each category offers unique challenges to help you grow as a leader
            </Typography>

            {/* Search and Filter Controls */}
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
              <TextField
                placeholder="Search scenarios..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                variant="outlined"
                sx={{
                  width: '100%',
                  maxWidth: 400,
                  bgcolor: 'white',
                  borderRadius: 2,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#718BB1' }} />
                    </InputAdornment>
                  )
                }}
              />

              <Box 
                sx={{ 
                  bgcolor: 'white',
                  borderRadius: 2,
                  display: 'flex',
                  gap: '2px',
                  p: '2px',
                  border: '1px solid rgba(255, 255, 255, 0.2)'
                }}
              >
                <Button
                  onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                  startIcon={<FilterListIcon />}
                  sx={{
                    minWidth: '120px',
                    color: selectedCategory !== 'all' ? '#4C6EA2' : '#718BB1',
                    bgcolor: selectedCategory !== 'all' ? 'rgba(92, 131, 191, 0.1)' : 'transparent',
                    borderRadius: 1.5,
                    textTransform: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      bgcolor: selectedCategory !== 'all' 
                        ? 'rgba(92, 131, 191, 0.15)' 
                        : 'rgba(92, 131, 191, 0.05)',
                    },
                    '&::after': selectedCategory !== 'all' ? {
                      content: '""',
                      width: '6px',
                      height: '6px',
                      bgcolor: '#4C6EA2',
                      borderRadius: '50%',
                      position: 'absolute',
                      top: '8px',
                      right: '8px'
                    } : {}
                  }}
                >
                  Filter
                  {selectedCategory !== 'all' && (
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        ml: 1,
                        bgcolor: 'rgba(92, 131, 191, 0.2)',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: '0.75rem'
                      }}
                    >
                      1
                    </Typography>
                  )}
                </Button>

                <Button
                  onClick={(e) => setSortAnchorEl(e.currentTarget)}
                  startIcon={<SortIcon />}
                  sx={{
                    minWidth: '120px',
                    color: sortBy !== 'default' ? '#4C6EA2' : '#718BB1',
                    bgcolor: sortBy !== 'default' ? 'rgba(92, 131, 191, 0.1)' : 'transparent',
                    borderRadius: 1.5,
                    textTransform: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      bgcolor: sortBy !== 'default' 
                        ? 'rgba(92, 131, 191, 0.15)' 
                        : 'rgba(92, 131, 191, 0.05)',
                    },
                    '&::after': sortBy !== 'default' ? {
                      content: '""',
                      width: '6px',
                      height: '6px',
                      bgcolor: '#4C6EA2',
                      borderRadius: '50%',
                      position: 'absolute',
                      top: '8px',
                      right: '8px'
                    } : {}
                  }}
                >
                  {sortBy === 'default' ? 'Sort' : (
                    <>
                      Sorted by
                      <Typography 
                        component="span" 
                        sx={{ 
                          ml: 1,
                          color: '#4C6EA2',
                          fontWeight: 600
                        }}
                      >
                        {sortBy === 'scenarios' ? '# of scenarios' :
                         sortBy === 'title' ? 'name' :
                         sortBy === 'category' ? 'category' : 'default'}
                      </Typography>
                    </>
                  )}
                </Button>
              </Box>
            </Box>

            {/* Filter Menu */}
            <Menu
              anchorEl={filterAnchorEl}
              open={Boolean(filterAnchorEl)}
              onClose={() => setFilterAnchorEl(null)}
              PaperProps={{
                elevation: 3,
                sx: {
                  mt: 1,
                  minWidth: 220,
                  borderRadius: 2,
                  border: '1px solid rgba(92, 131, 191, 0.1)',
                  '& .MuiList-root': {
                    py: 1
                  }
                }
              }}
            >
              <Typography
                sx={{
                  px: 2,
                  py: 1,
                  color: '#4C6EA2',
                  fontWeight: 600,
                  borderBottom: '1px solid rgba(92, 131, 191, 0.1)'
                }}
              >
                Filter by Category
              </Typography>
              {uniqueCategories.map((category) => (
                <MenuItem
                  key={category}
                  onClick={() => {
                    setSelectedCategory(category);
                    setFilterAnchorEl(null);
                  }}
                  selected={selectedCategory === category}
                  sx={{
                    mx: 1,
                    borderRadius: 1,
                    '&.Mui-selected': {
                      bgcolor: 'rgba(92, 131, 191, 0.1)',
                      '&:hover': {
                        bgcolor: 'rgba(92, 131, 191, 0.15)'
                      }
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 1 }}>
                    {category === 'all' ? 'All Categories' : category}
                    {selectedCategory === category && (
                      <Box sx={{ ml: 'auto' }}>
                        <FilterListIcon sx={{ fontSize: 18, color: '#4C6EA2' }} />
                      </Box>
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Menu>

            {/* Sort Menu */}
            <Menu
              anchorEl={sortAnchorEl}
              open={Boolean(sortAnchorEl)}
              onClose={() => setSortAnchorEl(null)}
              PaperProps={{
                elevation: 3,
                sx: {
                  mt: 1,
                  minWidth: 220,
                  borderRadius: 2,
                  border: '1px solid rgba(92, 131, 191, 0.1)',
                  '& .MuiList-root': {
                    py: 1
                  }
                }
              }}
            >
              <Typography
                sx={{
                  px: 2,
                  py: 1,
                  color: '#4C6EA2',
                  fontWeight: 600,
                  borderBottom: '1px solid rgba(92, 131, 191, 0.1)'
                }}
              >
                Sort Modules
              </Typography>
              {[
                { value: 'default', label: 'Default Order' },
                { value: 'scenarios', label: 'Most Scenarios' },
                { value: 'title', label: 'Name (A to Z)' },
                { value: 'category', label: 'Category' }
              ].map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => {
                    setSortBy(option.value);
                    setSortAnchorEl(null);
                  }}
                  selected={sortBy === option.value}
                  sx={{
                    mx: 1,
                    borderRadius: 1,
                    '&.Mui-selected': {
                      bgcolor: 'rgba(92, 131, 191, 0.1)',
                      '&:hover': {
                        bgcolor: 'rgba(92, 131, 191, 0.15)'
                      }
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 1 }}>
                    {option.label}
                    {sortBy === option.value && (
                      <Box sx={{ ml: 'auto' }}>
                        <SortIcon sx={{ fontSize: 18, color: '#4C6EA2' }} />
                      </Box>
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Tabs */}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              sx={{
                '& .MuiTab-root': {
                  color: '#718BB1',
                  '&.Mui-selected': {
                    color: '#4C6EA2'
                  }
                }
              }}
            >
              <Tab
                label="All Modules"
                icon={<FolderSpecialIcon />}
                iconPosition="start"
              />
              <Tab
                label={`Pinned (${pinnedTopics.length})`}
                icon={<BookmarkIcon />}
                iconPosition="start"
              />
            </Tabs>
          </Box>

          {/* Topics Grid */}
          <Box sx={{ p: 4 }}>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                {/* No results message */}
                {filteredTopics.length === 0 && (
                  <Box sx={{ textAlign: 'center', py: 8 }}>
                    <Typography variant="h6" sx={{ color: '#4C6EA2', mb: 2 }}>
                      No modules found
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#718BB1' }}>
                      Try adjusting your search or filters
                    </Typography>
                  </Box>
                )}

                {/* Active filters */}
                {(selectedCategory !== 'all' || sortBy !== 'default' || searchQuery) && (
                  <Box sx={{ mb: 3, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {selectedCategory !== 'all' && (
                      <Chip
                        label={`Category: ${selectedCategory}`}
                        onDelete={() => setSelectedCategory('all')}
                        sx={{ bgcolor: 'rgba(92, 131, 191, 0.1)', color: '#4C6EA2' }}
                      />
                    )}
                    {sortBy !== 'default' && (
                      <Chip
                        label={`Sort: ${sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}`}
                        onDelete={() => setSortBy('default')}
                        sx={{ bgcolor: 'rgba(92, 131, 191, 0.1)', color: '#4C6EA2' }}
                      />
                    )}
                    {searchQuery && (
                      <Chip
                        label={`Search: ${searchQuery}`}
                        onDelete={() => setSearchQuery('')}
                        sx={{ bgcolor: 'rgba(92, 131, 191, 0.1)', color: '#4C6EA2' }}
                      />
                    )}
                  </Box>
                )}

                <Grid container spacing={3}>
                  {/* Show either all topics or pinned topics based on current tab */}
                  {(currentTab === 0 ? filteredTopics : topics.filter(topic => pinnedTopics.includes(topic.id)))
                    .map((topic) => (
                      <Grid item xs={12} md={4} key={topic.id}>
                        {renderTopicCard(topic)}
                      </Grid>
                    ))}
                </Grid>
              </>
            )}
          </Box>
        </Paper>

        {/* Help text */}
        <Box
          sx={{
            mt: 4,
            p: 2,
            borderRadius: 3,
            bgcolor: 'rgba(92, 131, 191, 0.05)',
            border: '1px solid rgba(92, 131, 191, 0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1
          }}
        >
          <InfoIcon sx={{ color: '#5C83BF' }} />
          <Typography
            variant="body1"
            sx={{
              color: '#718BB1',
              fontWeight: 500
            }}
          >
            Select any category to begin exploring scenarios and enhance your management skills
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default TopicSelection;