import React, { useState, useEffect, useCallback } from 'react';
import { 
  Button, 
  Grid, 
  Typography, 
  Card, 
  CardContent,
  IconButton,
  Box,
  Paper,
  Avatar,
  Chip,
  LinearProgress,
  Tabs,
  Tab,
  Divider
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Modal from "react-bootstrap/Modal";
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useErrorBoundary } from "react-error-boundary";
import { db, collection, query, getDocs, where, storage, ref, getDownloadURL } from "../../Firebase";
import LoadingSpinner from "./shared/LoadingSpinner";
import { 
  getStorageMonthDays, 
  getStorageMonths, 
  getStorageSessionCalendar, 
  saveStorageMonthDays, 
  saveStorageMonths, 
  saveStorageSessionCalendar 
} from "../../Helpers";

// Extend dayjs with required plugins
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);

// Styled Components
const InsightCard = styled(Card)({
  background: '#ffffff',
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  height: '100%'
});

const SessionCard = styled(Paper)({
  padding: '16px',
  borderRadius: '12px',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 24px rgba(92, 131, 191, 0.2)'
  }
});

const GradientProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  background: '#E0E7FF',
  '& .MuiLinearProgress-bar': {
    background: 'linear-gradient(90deg, #5C83BF 0%, #4A6A9B 100%)'
  }
});

const LeadershipDashboard = () => {
  const [currentWeek, setCurrentWeek] = useState(dayjs());
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSession, setSelectedSession] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [moodrepURLs, setMoodrepURLs] = useState({});
  const { user } = useUser();
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  const fetchWeekSessions = useCallback(async () => {
    if (!user?.uid) return;

    try {
      const startOfWeek = currentWeek.startOf('week').toDate();
      const endOfWeek = currentWeek.endOf('week').toDate();

      const sessionQuery = query(
        collection(db, 'users', user.uid, 'sessions'),
        where('session_time', '>=', startOfWeek),
        where('session_time', '<=', endOfWeek)
      );

      const sessionsSnapshot = await getDocs(sessionQuery);
      const sessionsData = [];
      const moodrepPromises = {};

      for (const doc of sessionsSnapshot.docs) {
        const data = doc.data();
        const date = doc.id;

        // Fetch moodrep URL
        if (data.moodrep) {
          const moodrepRef = ref(storage, data.moodrep);
          moodrepPromises[date] = getDownloadURL(moodrepRef);
        }

        sessionsData.push({
          id: doc.id,
          date: dayjs(date).format('MMMM D'),
          dayOfWeek: dayjs(date).format('dddd'),
          ...data
        });

        saveStorageSessionCalendar(date, data);
      }

      // Resolve all moodrep URLs
      const resolvedMoodreps = {};
      for (const [date, promise] of Object.entries(moodrepPromises)) {
        try {
          resolvedMoodreps[date] = await promise;
        } catch (error) {
          console.error(`Error fetching moodrep for ${date}:`, error);
        }
      }

      setMoodrepURLs(resolvedMoodreps);
      setSessions(sessionsData.sort((a, b) => dayjs(b.date).diff(dayjs(a.date))));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching sessions:", error);
      showBoundary(error);
      setLoading(false);
    }
  }, [currentWeek, user]);

  useEffect(() => {
    fetchWeekSessions();
  }, [fetchWeekSessions]);

  const handlePrevWeek = () => {
    setCurrentWeek(prev => prev.subtract(1, 'week'));
  };

  const handleNextWeek = () => {
    const nextWeek = currentWeek.add(1, 'week');
    if (nextWeek.isSameOrBefore(dayjs(), 'week')) {
      setCurrentWeek(nextWeek);
    }
  };

  const getAverageScore = () => {
    const scores = sessions.map(s => parseFloat(s.energy_level)).filter(Boolean);
    return scores.length ? scores.reduce((a, b) => a + b, 0) / scores.length : 0;
  };

  const getImprovementTrend = () => {
    if (sessions.length < 2) return 0;
    const sortedScores = sessions
      .map(s => parseFloat(s.energy_level))
      .filter(Boolean)
      .sort((a, b) => a - b);
    return ((sortedScores[sortedScores.length - 1] - sortedScores[0]) / sortedScores[0]) * 100;
  };

  if (loading) return <LoadingSpinner />;

  return (
    <Box sx={{ p: 3, maxWidth: '1400px', margin: '0 auto' }}>
      {/* Header */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            bgcolor: '#5C83BF',
            p: 3,
            borderRadius: '16px',
            color: 'white'
          }}>
            <Box>
              <Typography variant="h4" fontWeight="bold">
                Leadership Insights
              </Typography>
              <Typography variant="subtitle1">
                {currentWeek.format("MMMM D")} - {currentWeek.endOf('week').format("MMMM D, YYYY")}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <IconButton onClick={handlePrevWeek} sx={{ color: 'white' }}>
                <FontAwesomeIcon icon="fa-solid fa-less-than" />
              </IconButton>
              <IconButton 
                onClick={handleNextWeek}
                disabled={currentWeek.add(1, 'week').isAfter(dayjs(), 'week')}
                sx={{ color: 'white' }}
              >
                <FontAwesomeIcon icon="fa-solid fa-greater-than" />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Quick Stats */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <InsightCard>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Weekly Average Score
              </Typography>
              <Typography variant="h3" color="primary" sx={{ mb: 2 }}>
                {getAverageScore().toFixed(1)}
              </Typography>
              <GradientProgress 
                variant="determinate" 
                value={(getAverageScore() / 5) * 100} 
              />
            </CardContent>
          </InsightCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <InsightCard>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Sessions Completed
              </Typography>
              <Typography variant="h3" color="primary">
                {sessions.length}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                of 7 recommended sessions
              </Typography>
            </CardContent>
          </InsightCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <InsightCard>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Improvement Trend
              </Typography>
              <Typography variant="h3" sx={{ color: getImprovementTrend() >= 0 ? '#10B981' : '#EF4444' }}>
                {getImprovementTrend().toFixed(1)}%
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {getImprovementTrend() >= 0 ? 'Improvement' : 'Decline'} this week, based on your communication
              </Typography>
            </CardContent>
          </InsightCard>
        </Grid>
      </Grid>

      {/* Weekly Sessions */}
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <InsightCard>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Weekly Sessions
              </Typography>
              <Grid container spacing={2}>
                {sessions.map((session) => (
                  <Grid item xs={12} key={session.id}>
                    <SessionCard 
                      onClick={() => {
                        setSelectedSession(session);
                        setShowModal(true);
                      }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          {moodrepURLs[session.id] && (
                            <Avatar 
                              src={moodrepURLs[session.id]} 
                              sx={{ width: 56, height: 56 }}
                            />
                          )}
                        </Grid>
                        <Grid item xs>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {session.dayOfWeek} - {session.date}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {session.session_topic || 'General Session'}
                          </Typography>
                          <Box sx={{ mt: 1 }}>
                            {session.emotions_readable?.split(' -- ')[0]
                              .split(' ')
                              .slice(0, 6)
                              .map((emotion, index) => (
                                <Chip 
                                  key={index}
                                  label={emotion}
                                  size="small"
                                  sx={{ mr: 1, mb: 1 }}
                                />
                              ))
                            }
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography 
                            variant="h6"
                            sx={{ 
                              color: parseFloat(session.energy_level) >= 3.5 ? '#10B981' : '#EF4444'
                            }}
                          >
                            {session.energy_level}
                          </Typography>
                        </Grid>
                      </Grid>
                    </SessionCard>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </InsightCard>
        </Grid>

        {/* Trends and Insights */}
        <Grid item xs={12} lg={4}>
          <InsightCard>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs 
                value={activeTab} 
                onChange={(e, newValue) => setActiveTab(newValue)}
                variant="fullWidth"
              >
                <Tab label="Score Trends" />
                <Tab label="Key Insights" />
              </Tabs>
            </Box>
            <CardContent>
              {activeTab === 0 ? (
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={sessions}>
                      <XAxis dataKey="date" />
                      <YAxis domain={[1, 5]} />
                      <Tooltip />
                      <Line 
                        type="monotone" 
                        dataKey="energy_level" 
                        // datakey name
                        name="Score"
                        stroke="#5C83BF"
                        strokeWidth={2}
                        dot={{ fill: '#5C83BF' }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              ) : (
                <Box>
                  {sessions.map((session, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Typography variant="subtitle2" color="primary" gutterBottom>
                        {session.date}
                      </Typography>
                      <Typography variant="body2">
                        {session.session_analysis?.split('DEVELOPMENT AREAS:')[0].replace('STRENGTHS:', '')}
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                    </Box>
                  ))}
                </Box>
              )}
            </CardContent>
          </InsightCard>
        </Grid>
      </Grid>

      {/* Session Detail Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: '16px' }}>
          {selectedSession && (
            <>
              <Typography variant="h5" sx={{ mb: 3 }}>
                Session Details - {selectedSession.date}
              </Typography>
              
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  {moodrepURLs[selectedSession.id] && (
                    <img 
                      src={moodrepURLs[selectedSession.id]} 
                      alt="Session Mood Representation"
                      style={{ 
                        width: '100%', 
                        borderRadius: '12px',
                        marginBottom: '16px'
                      }}
                    />
                  )}
                  <Typography variant="h6" gutterBottom>
                    Score: {selectedSession.energy_level}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    Duration: {selectedSession.session_duration} seconds
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    Topic: {selectedSession.session_topic}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                      Communication Analysis
                    </Typography>
                    <Typography variant="body2">
                      {selectedSession.emotions_readable?.split(' -- ')[0]}
                    </Typography>
                  </Box>

                  <Divider sx={{ my: 3 }} />

                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                      Key Improvements
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Most Improved: {selectedSession.improved_emotion}
                      {selectedSession.improved_by && `(${selectedSession.improved_by})`}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Session Analysis
                    </Typography>
                    <Typography variant="body2" paragraph>
                      {selectedSession.session_analysis?.split('STRENGTHS:')[1]?.split('DEVELOPMENT AREAS:')[0]}
                    </Typography>
                    <Typography variant="subtitle1" color="primary" gutterBottom>
                      Development Areas
                    </Typography>
                    <Typography variant="body2" paragraph>
                      {selectedSession.session_analysis?.split('DEVELOPMENT AREAS:')[1]?.split('KEY CONSIDERATIONS:')[0]}
                    </Typography>
                    <Typography variant="subtitle1" color="primary" gutterBottom>
                      Action Items
                    </Typography>
                    <Typography variant="body2">
                      {selectedSession.session_analysis?.split('KEY CONSIDERATIONS:')[1]}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {/* align the buttons in the center of the page */}
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="outlined"
                  onClick={() => setShowModal(false)}
                  sx={{ mr: 2 }}
                >
                  Close
                </Button>
                {/* add space to push the button down very little */}
                {/* <Box sx={{ display: 'inline-block', width: 10 }} /> */}
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(`/overview?selectedDate=${selectedSession.id}`);
                    setShowModal(false);
                  }}
                  sx={{ bgcolor: '#5C83BF', mr: 2 }}
                >
                  View Full Analysis
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default LeadershipDashboard;