import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import EmailIcon from '@mui/icons-material/Email';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5C83BF',
      light: '#7C9DCF',
      dark: '#4B6A9F',
    },
    secondary: {
      main: '#EEC230',
      light: '#F4D160',
      dark: '#CC9E20',
    },
    background: {
      default: '#F8FAFC',
      paper: '#FFFFFF',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          marginBottom: '16px',
          borderRadius: '8px',
          border: '1px solid rgba(92, 131, 191, 0.2)',
          background: '#FFFFFF',
          transition: 'all 0.3s ease',
          '&:hover': {
            borderColor: '#5C83BF',
          },
          '&.Mui-expanded': {
            margin: '0 0 16px 0',
            background: 'linear-gradient(to right, #FFFFFF, #F8FAFC)',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
          '&.Mui-expanded': {
            borderBottom: '1px solid rgba(92, 131, 191, 0.1)',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '24px',
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);
  const faqs = [
    {
      id: 1,
      question: 'What makes PokaMind different from other training platforms?',
      answer: 'Unlike traditional e-learning platforms or expensive workshops, PokaMind offers practice-first learning through 5-minute real-world scenarios. Think of it as your personal leadership coach that provides immediate, objective feedback on your performance and offers practical suggestions for improvement.'
    },
    {
      id: 2,
      question: 'How much time do I need to commit?',
      answer: 'We recommend practicing 3-4 times per week, with each scenario taking just 5 minutes to complete. The beauty of PokaMind is that you can integrate it seamlessly into your daily routine, practicing whenever it fits your schedule.'
    },
    {
      id: 3,
      question: 'How does the AI feedback work?',
      answer: 'Our AI analyzes your responses across multiple dimensions - from your communication style to decision-making patterns. It\'s like having a team of expert coaches watching your performance and offering personalized insights. You\'ll receive specific, actionable feedback that you can apply immediately in your work.'
    },
    {
      id: 4,
      question: 'I need quick help during my workday. How can PokaMind assist?',
      answer: 'We\'ve got you covered with our Chrome extension and Slack integration. Whether you need guidance on handling a difficult conversation or quick tips on company policies, simply ask through either platform and get immediate, relevant support.'
    },
    {
      id: 5,
      question: 'Can you customize scenarios for our industry?',
      answer: 'Absolutely! We understand that leadership challenges vary across industries. Our Enterprise tier includes custom scenario development tailored to your organization\'s specific context, culture, and common situations your leaders face.'
    },
    {
      id: 6,
      question: 'How do you protect our data?',
      answer: 'Your data security is our top priority. We use end-to-end encryption, maintain strict GDPR compliance, and offer local data processing options. All your practice sessions and interactions remain confidential and secure.'
    },
    {
      id: 7,
      question: 'Can PokaMind work with our existing systems?',
      answer: 'Yes! We integrate smoothly with major HR platforms like Workday, BambooHR, and SuccessFactors. Our Enterprise tier also includes API access for custom integrations with your specific tools.'
    },
    {
      id: 8,
      question: 'What\'s included in the different pricing tiers?',
      answer: 'We offer Business and Enterprise tiers to suit different needs. The Business tier includes our core platform features, while Enterprise adds custom scenarios, advanced analytics, dedicated support, and integration options. Contact us for specific pricing based on your team size.'
    }
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box 
        sx={{
          minHeight: '100vh',
          background: 'linear-gradient(180deg, rgba(92, 131, 191, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
          pt: 8,
          pb: 12,
        }}
      >
        <Container maxWidth="md">
          {/* Header Section */}
          <Paper
            elevation={0}
            sx={{
              p: 4,
              mb: 6,
              borderRadius: '16px',
              background: '#FFFFFF',
              position: 'relative',
              overflow: 'hidden',
              border: '1px solid rgba(92, 131, 191, 0.2)',
            }}
          >
            <QuestionAnswerIcon 
              sx={{
                position: 'absolute',
                top: -20,
                right: -20,
                fontSize: 120,
                color: 'rgba(92, 131, 191, 0.1)',
                transform: 'rotate(15deg)',
              }}
            />
            <Typography 
              variant="h3" 
              component="h1"
              sx={{
                fontWeight: 700,
                mb: 2,
                color: theme.palette.primary.main,
              }}
            >
              Frequently Asked Questions
            </Typography>
            <Typography 
              variant="subtitle1" 
              color="text.secondary"
              sx={{ maxWidth: '600px' }}
            >
              Everything you need to know about PokaMind's AI-powered leadership development platform
            </Typography>
          </Paper>

          {/* FAQ Section */}
          <Box>
            {faqs.map((faq, index) => (
              <Accordion
                key={faq.id}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon 
                      sx={{ 
                        color: expanded === `panel${index}` ? 'primary.main' : 'text.secondary',
                      }}
                    />
                  }
                >
                  <Typography 
                    sx={{ 
                      fontWeight: 600,
                      color: expanded === `panel${index}` ? 'primary.main' : 'text.primary',
                    }}
                  >
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography 
                    color="text.secondary"
                    sx={{ lineHeight: 1.7 }}
                  >
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>

          {/* Contact Section */}
          <Paper
            elevation={0}
            sx={{
              mt: 6,
              p: 4,
              borderRadius: '12px',
              background: '#FFFFFF',
              border: '1px solid rgba(92, 131, 191, 0.2)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2, color: 'text.primary' }}>
              Still have questions?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
              }}
            >
              <Typography color="text.secondary">
                Contact us at
              </Typography>
              <Tooltip title="Send us an email">
                <IconButton
                  component="a"
                  href="mailto:team@pokamind.com"
                  color="primary"
                  size="small"
                >
                  <EmailIcon />
                </IconButton>
              </Tooltip>
              <Typography 
                component="a"
                href="mailto:team@pokamind.com"
                sx={{
                  color: 'primary.main',
                  textDecoration: 'none',
                  fontWeight: 600,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                team@pokamind.com
              </Typography>
            </Box>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default FAQ;