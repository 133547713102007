import React, { lazy, Suspense } from 'react';
import {
  Route,
  Routes,
  BrowserRouter,
  useLocation
} from 'react-router-dom';
import { UserProvider, useUser } from './contexts/UserContext';
import { SessionProvider } from "./contexts/SessionContext";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { ErrorBoundary } from "react-error-boundary";
import UserGeneralError from './user/pages/GeneralError';
import UserAuth from "./user/pages/Auth";
import UserGetStarted from './user/pages/GetStarted';
import UserSOS from "./user/pages/SOS";
import UserSessionResponseSpinner from './user/pages/shared/SessionResponseSpinner';
import UserLoadingSpinner from './user/pages/shared/LoadingSpinner';
import UserCalendarPage from "./user/pages/Calender";
import UserFAQ from "./user/pages/FAQ";
import UserBlogs from "./user/pages/Blogs";
import UserBookTherapy from './user/pages/BookTherapy';
import UserViewTherapistProfile from './user/pages/ViewTherapistProfile';
import UserEditTherapistProfile from './user/pages/EditTherapistProfile';
import UserSuccessPage from './user/pages/BookSuccess';
import UserFailurePage from './user/pages/BookFailed';
import UserAdminView from './user/pages/AdminView';
import UserAddCompanyUsers from './user/pages/AddCompanyUsers';
import UserNotFound404 from './user/pages/NotFound404';
import UserTherapistView from './user/pages/TherapistView';
import ProtectedTherapistRoute from './ProtectedTherapistRoute';
import ProtectedUserRoute from './ProtectedUserRoute';
import ProtectedAuthRoute from './ProtectedAuthRoute';
import ProtectedSlackAuthRoute from './ProtectedSlackRoute';
import ProtectedAdminRoute from './ProtectedAdminRoute';
import ProtectedAnalyticsRoute from './ProtectedAnalyticsRoute';
import ProtectedConsultantRoute from './ProtectedConsultantRoute';
import UserOnboardingSuccessPage from './user/pages/OnBoardingSuccess';
import UserOnboardingPendingPage from './user/pages/OnBoardingPending';
import UserProfile from './user/pages/UserProfile';
import UserPlans from './user/pages/PlansIndv';
import UserAdminReport from './user/pages/AdminReport';
import UserCalmCorner from './user/pages/CalmCorner';
import UserNavbar from './user/pages/shared/Navbar';
import UserHomeNavbar from './user/pages/home/HomeNavbar';
import UserFooter from './user/pages/shared/Footer';
import UserHomeFooter from './user/pages/home/HomeFooter';
import UserCollectionPage from './user/pages/CollectionPage';
import UserMySession from './user/pages/session/MySession';
import UserSubscriptionSuccess from './user/pages/SubscriptionSuccess';
import UserSubscriptionFail from './user/pages/SubscriptionFail';
import ScrollToTop from './components/ScrollToTop';
import "./Google-Nunito.css";
import { Analytics } from './components/Analytics';
import UserSlackIntegration from './user/pages/SlackIntegration';
import SlackCallback from './components/SlackCallback';
import SlackAuthCallback from './components/SlackAuthCallback';
import UserAuthSignup from './user/pages/AuthSignup';
import UserWhyPokaMind from './user/pages/Why';
import AdminGeneralError from './admin/pages/GeneralError';
import AdminAuth from "./admin/pages/Auth";
import AdminGetStarted from './admin/pages/GetStarted';
import AdminSOS from "./admin/pages/SOS";
import AdminSessionResponseSpinner from './admin/pages/shared/SessionResponseSpinner';
import AdminLoadingSpinner from './admin/pages/shared/LoadingSpinner';
import AdminCalendarPage from "./admin/pages/Calender";
import AdminFAQ from "./admin/pages/FAQ";
import AdminBlogs from "./admin/pages/Blogs";
import AdminBookTherapy from './admin/pages/BookTherapy';
import AdminViewTherapistProfile from './admin/pages/ViewTherapistProfile';
import AdminEditTherapistProfile from './admin/pages/EditTherapistProfile';
import AdminSuccessPage from './admin/pages/BookSuccess';
import AdminFailurePage from './admin/pages/BookFailed';
import AdminAdminView from './admin/pages/AdminView';
import AdminAddCompanyUsers from './admin/pages/AddCompanyUsers';
import AdminNotFound404 from './admin/pages/NotFound404';
import AdminTherapistView from './admin/pages/TherapistView';
import AdminOnboardingSuccessPage from './admin/pages/OnBoardingSuccess';
import AdminOnboardingPendingPage from './admin/pages/OnBoardingPending';
import AdminProfile from './admin/pages/UserProfile';
import AdminPlans from './admin/pages/PlansIndv';
import AdminAdminReport from './admin/pages/AdminReport';
import AdminCalmCorner from './admin/pages/CalmCorner';
import AdminNavbar from './admin/pages/shared/Navbar';
import AdminHomeNavbar from './admin/pages/home/HomeNavbar';
import AdminFooter from './admin/pages/shared/Footer';
import AdminHomeFooter from './admin/pages/home/HomeFooter';
import AdminCollectionPage from './admin/pages/CollectionPage';
import AdminMySession from './admin/pages/session/MySession';
import AdminSubscriptionSuccess from './admin/pages/SubscriptionSuccess';
import AdminSubscriptionFail from './admin/pages/SubscriptionFail';
import "./Google-Nunito.css";
import AdminSlackIntegration from './admin/pages/SlackIntegration';
import AdminAuthSignup from './admin/pages/AuthSignup';
import AdminWhyPokaMind from './admin/pages/Why';
import ConsultantGetStarted from './consultant/pages/GetStarted';
import ConsultantAuth from './consultant/pages/Auth';
import CounsultantNotFound404 from './consultant/pages/NotFound404';
// import ConsultantAdminView from './consultant/pages/AdminView';
import ConsultantAdminReport from './consultant/pages/AdminReport';
import ConsultantAddCompanyUsers from './consultant/pages/AddCompanyUsers';
import ConsultantHomeNavbar from './consultant/pages/home/HomeNavbar';
import ConsultantHomeFooter from './consultant/pages/home/HomeFooter';
// import ConsultantNavbar from './consultant/pages/shared/Navbar';

library.add(fas, fab, far);

const UserOverview = lazy(() => import('./user/pages/session/Overview'))
const UserAnalysis = lazy(() => import('./user/pages/Analysis'))
const UserDBTAdvice = lazy(() => import('./user/pages/DBTAdvice'))
const AdminOverview = lazy(() => import('./admin/pages/session/Overview'))
const AdminAnalysis = lazy(() => import('./admin/pages/Analysis'))
const AdminDBTAdvice = lazy(() => import('./admin/pages/DBTAdvice'))

const App = () => {
  return (
    <UserProvider>
      <SessionProvider>
        <BrowserRouter>
          <ErrorBoundary
            FallbackComponent={UserGeneralError}
            onError={(error) => console.error("Error occurred:", error)}
          >
            <ScrollToTop />
            <Analytics />
            <ContentWrapper />
          </ErrorBoundary>
        </BrowserRouter>
      </SessionProvider>
    </UserProvider>
  );
};

const RoleBasedComponent = ({ UserComponent, AdminComponent, ConsultantComponent = CounsultantNotFound404 }) => {
  const { role } = useUser();
  if (role === 'admin') {
    return <AdminComponent />;
  }
  if (role === 'consultant') {
    return <ConsultantComponent />;
  }
  return <UserComponent />;

};

const ContentWrapper = () => {
  const location = useLocation();

  const hideNavbarAndFooter = ["/why-pokamind", "/auth/signup", "/", "/auth", "/auth/slack-callback"].includes(location.pathname);

  return (
    <>
      {!hideNavbarAndFooter && <NavbarSwitcher />}
      <Routes>
        {/* Public routes */}
        <Route exact path="/" element={
          <RoleBasedComponent
            UserComponent={UserAuth}
            AdminComponent={AdminAuth}
            ConsultantComponent={ConsultantAuth}
          />
        } />
        <Route path="/auth" element={
          <RoleBasedComponent
            UserComponent={UserAuth}
            AdminComponent={AdminAuth}
            ConsultantComponent={ConsultantAuth}
          />
        } />
        <Route path="/auth/slack-callback" element={<SlackAuthCallback />} />
        <Route path="/auth/signup" element={
          <RoleBasedComponent
            UserComponent={UserAuthSignup}
            AdminComponent={AdminAuthSignup}
          />
        } />
        <Route path="/why-pokamind" element={
          <RoleBasedComponent
            UserComponent={UserWhyPokaMind}
            AdminComponent={AdminWhyPokaMind} />
        } />

        <Route path="/faq" element={
          <RoleBasedComponent
            UserComponent={UserFAQ}
            AdminComponent={AdminFAQ}
          />
        } />

        {/* Slack authentication routes */}
        <Route element={<ProtectedSlackAuthRoute />}>
          <Route path="/link-slack" element={
            <RoleBasedComponent
              UserComponent={UserSlackIntegration}
              AdminComponent={AdminSlackIntegration}
            />
          } />
          <Route path="/slack/callback" element={<SlackCallback />} />
        </Route>

        {/* Protected routes */}
        <Route element={<ProtectedAuthRoute />}>
          <Route path="/get-started" element={
            <RoleBasedComponent
              UserComponent={UserGetStarted}
              AdminComponent={AdminGetStarted}
              ConsultantComponent={ConsultantGetStarted}
            />
          } />
          <Route path="/blogs" element={
            <RoleBasedComponent
              UserComponent={UserBlogs}
              AdminComponent={AdminBlogs}
            />
          } />
          <Route path="/sos" element={
            <RoleBasedComponent
              UserComponent={UserSOS}
              AdminComponent={AdminSOS}
            />
          } />

          <Route path="/plans" element={
            <RoleBasedComponent
              UserComponent={UserPlans}
              AdminComponent={AdminPlans}
            />
          } />

          <Route path="/session" element={
            <RoleBasedComponent
              UserComponent={UserMySession}
              AdminComponent={AdminMySession} />} />

          <Route path="/user-profile" element={
            <RoleBasedComponent
              UserComponent={UserProfile}
              AdminComponent={AdminProfile}
            />
          } />

          <Route path="/mymood" element={
            <RoleBasedComponent
              UserComponent={UserCalendarPage}
              AdminComponent={AdminCalendarPage}
            />
          } />


          <Route path="/subscription_success/:session_id" element={
            <Suspense fallback={<RoleBasedComponent UserComponent={UserLoadingSpinner} AdminComponent={AdminLoadingSpinner} />}>
              <RoleBasedComponent
                UserComponent={UserSubscriptionSuccess}
                AdminComponent={AdminSubscriptionSuccess}
              />
            </Suspense>
          } />
          <Route path="/overview" element={
            <Suspense fallback={<RoleBasedComponent UserComponent={UserSessionResponseSpinner} AdminComponent={AdminSessionResponseSpinner} />}>
              <RoleBasedComponent
                UserComponent={UserOverview}
                AdminComponent={AdminOverview} />
            </Suspense>
          } />
          <Route path="/analysis" element={
            <Suspense fallback={<RoleBasedComponent UserComponent={UserLoadingSpinner} AdminComponent={AdminLoadingSpinner} />}>
              <RoleBasedComponent
                UserComponent={UserAnalysis}
                AdminComponent={AdminAnalysis} />
            </Suspense>
          } />
          <Route path="/dbt" element={
            <Suspense fallback={<RoleBasedComponent UserComponent={UserLoadingSpinner} AdminComponent={AdminLoadingSpinner} />}>
              <RoleBasedComponent
                UserComponent={UserDBTAdvice}
                AdminComponent={AdminDBTAdvice} />
            </Suspense>
          } />

          <Route path="/my-collection" element={<UserCollectionPage />} />


          {/* User-role protected routes */}
          <Route element={<ProtectedUserRoute />} >
            <Route path="/book-success/:therapist_id/:timeslot_id/:share_consent/:session_id" element={
              <UserSuccessPage />
            } />
            <Route path="/book-failure" element={
              <UserFailurePage />
            } />

            <Route path="/book-therapist" element={
              <UserBookTherapy />
            } />
            <Route path='/view-profile/:id' element={
              <UserViewTherapistProfile />
            } />
            <Route path="/calm-corner" element={
              <UserCalmCorner />
            } />

          </Route>

          {/* Therapist-role protected routes */}
          <Route element={<ProtectedTherapistRoute />} >
            <Route path="/therapist-view" element={
              <RoleBasedComponent
                UserComponent={UserEditTherapistProfile}
                AdminComponent={AdminNotFound404}
              />
            } />
            <Route path="/my-profile" element={
              <RoleBasedComponent
                UserComponent={UserViewTherapistProfile}
                AdminComponent={AdminNotFound404}
              />
            } />
            <Route path="/onboarding-success" element={
              <RoleBasedComponent
                UserComponent={UserOnboardingSuccessPage}
                AdminComponent={AdminOnboardingSuccessPage}
              />
            } />
            <Route path="/onboarding-pending" element={
              <RoleBasedComponent
                UserComponent={UserOnboardingPendingPage}
                AdminComponent={AdminOnboardingPendingPage}
              />
            } />
          </Route>

          {/* Admin-role protected routes */}
          <Route element={<ProtectedAdminRoute />} >
          
          </Route>

          {/* Analytics-role protected routes */}
          {/* <Route element={<ProtectedAnalyticsRoute />} >
            <Route path="/admin-view" element={<AdminAdminView />} />
            <Route path="/admin-report" element={<AdminAdminReport />} />
          </Route> */}

          {/* Consultant protected Route */}
          <Route element={<ProtectedConsultantRoute />}>
            {/* <Route path="/admin-view" element={<ConsultantAdminView />} /> */}
            <Route path="/admin-report" element={<ConsultantAdminReport />} />
            <Route path="/add-employee" element={<ConsultantAddCompanyUsers />} />
          </Route>

        </Route>

        {/* 404 route */}
        <Route path="*" element={
          <RoleBasedComponent
            UserComponent={UserNotFound404}
            AdminComponent={AdminNotFound404}
          />
        } />
      </Routes>

      {!hideNavbarAndFooter && <FooterSwitcher />}
    </>
  );
};

const NavbarSwitcher = () => {
  const location = useLocation();
  const { role } = useUser();

  if (location.pathname === '/get-started') {
    if (role === "user") return <UserHomeNavbar />;
    if (role === "admin") return <AdminHomeNavbar />;
    if (role === "consultant") return <ConsultantHomeNavbar />;

  }

  if (role === "user") return <UserNavbar />;
  if (role === "admin") return <AdminNavbar />;
  if (role === "consultant") return <ConsultantHomeNavbar />;
};

const FooterSwitcher = () => {
  const location = useLocation();
  const { role } = useUser();

  if (location.pathname === '/get-started') {
    if (role === 'admin') return <AdminHomeFooter />;
    if (role === 'consultant') return <ConsultantHomeFooter />;
    if (role === 'user') return <UserHomeFooter />;
    return role === 'admin' ? <AdminHomeFooter /> : <UserHomeFooter />;
  }

  if (role === "user") return <UserFooter />;
  if (role === "admin") return <AdminHomeFooter />
  if (role === "consultant") return <ConsultantHomeFooter />
};

export default App;
