import {
  Grid, Typography, Box, Paper, Card, Button,
  Avatar, Chip, Divider, TextField, MenuItem,
  IconButton, Tooltip, Dialog, DialogTitle,
  DialogContent, DialogContentText, Tabs, Tab,
  List, ListItem, Collapse, Badge, Menu
} from '@mui/material';
import { styled, alpha } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
// Icons
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SortIcon from '@mui/icons-material/Sort';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LinearProgress } from '@mui/material';
import { useUser } from '../../contexts/UserContext';
import { URL } from '../../Helpers';
import LoadingSpinner from './shared/LoadingSpinner';
import LeaderProgressMetrics from './shared/progress';
const COLORS = {
  primary: '#5C83BF',
  secondary: '#FFF7F1',
  accent: '#FF8B67',
  success: '#4CAF50',
  warning: '#FFA726',
  error: '#EF5350'
};

// Styled Components
const DashboardContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: COLORS.secondary,
  minHeight: '100vh'
}));

const MainCard = styled(Card)({
  padding: '2rem',
  backgroundColor: '#fff',
  borderRadius: '24px',
  boxShadow: `0 8px 32px ${alpha(COLORS.primary, 0.08)}`
});

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '16px',
  backgroundColor: '#fff',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: `0 4px 20px ${alpha(COLORS.primary, 0.08)}`,
  '&:hover': {
    boxShadow: `0 8px 28px ${alpha(COLORS.primary, 0.12)}`
  }
}));

const LeaderCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '16px',
  marginBottom: theme.spacing(2),
  backgroundColor: '#fff',
  boxShadow: `0 4px 12px ${alpha(COLORS.primary, 0.06)}`,
  '&:hover': {
    boxShadow: `0 6px 16px ${alpha(COLORS.primary, 0.1)}`
  }
}));

const SessionItem = styled(ListItem)(({ theme }) => ({
  borderRadius: '12px',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: alpha(COLORS.primary, 0.04)
  }
}));

const ProgressTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    border: `1px solid ${alpha(COLORS.primary, 0.2)}`,
    borderRadius: '12px',
    padding: '16px',
    boxShadow: `0 4px 20px ${alpha(COLORS.primary, 0.15)}`,
    '& .MuiTooltip-arrow': {
      color: '#fff',
    },
  },
}));

const ProgressComponent = ({ value, type, total, label }) => (
  <Box sx={{ mb: 1 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>{label}</Typography>
      <Typography variant="body2" sx={{ color: COLORS.primary, fontWeight: 500 }}>
        {Math.round(value)}%
      </Typography>
    </Box>
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        height: 6,
        borderRadius: 3,
        backgroundColor: alpha(COLORS.primary, 0.1),
        '& .MuiLinearProgress-bar': {
          backgroundColor: type === 'consistency' ? alpha(COLORS.primary, 0.4) :
                         type === 'energy' ? alpha(COLORS.primary, 0.7) :
                         COLORS.primary
        }
      }}
    />
    <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block', mt: 0.5 }}>
      {type === 'consistency' ? `${total} sessions over time` :
       type === 'energy' ? `Average energy: ${(value * 5 / 100).toFixed(1)}/5` :
       `${Math.round(total)} improved sessions`}
    </Typography>
  </Box>
);


const SessionGroup = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '12px',
  backgroundColor: alpha(COLORS.primary, 0.02),
  marginBottom: theme.spacing(2),
  '&:hover': {
    backgroundColor: alpha(COLORS.primary, 0.04),
  }
}));


const ConsultantAdmin = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDepartment, setFilterDepartment] = useState('all');
  const [leaderSortBy, setLeaderSortBy] = useState('progress');
  const [expandedLeader, setExpandedLeader] = useState(null);
  const [sessionSortBy, setSessionSortBy] = useState('date');
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [currentSummary, setCurrentSummary] = useState('');
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();


  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;
      
      setLoading(true);
      try {
        const cachedReport = sessionStorage.getItem('adminReport');
        const cachedTimestamp = sessionStorage.getItem('reportTimestamp');
        const cachedData = sessionStorage.getItem('userData');
        
        if (cachedReport && cachedTimestamp && cachedData) {
          const timestamp = new Date(cachedTimestamp);
          const now = new Date();
          const timeDiff = now.getTime() - timestamp.getTime();
          const thirtyMinutes = 30 * 60 * 1000;
          
          if (timeDiff < thirtyMinutes) {
            setUserData(JSON.parse(cachedData));
            setLoading(false);
            return;
          }
        }
  
        const idToken = await user.getIdToken();
        const response = await fetch(`${URL}/get_users_sessions_by_company_consultant`, {
          headers: { Authorization: `Bearer ${idToken}` }
        });
  
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
  
        sessionStorage.setItem('adminReport', data.admin_report);
        sessionStorage.setItem('reportTimestamp', new Date().toISOString());
        sessionStorage.setItem('userData', JSON.stringify(data));
        
        setUserData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [user]);

  // Helper functions
  const formatDuration = (seconds) => {
    if (!seconds) return '0 min';
    const minutes = Math.floor(seconds / 60);
    if (minutes < 1) return '< 1 min';
    return `${minutes} min`;
  };

  const calculateMonthsDiff = (leader) => {
    if (!leader.sessions.length) return 1;
    
    const sessions = [...leader.sessions].sort((a, b) => 
      new Date(b.session_time) - new Date(a.session_time)
    );
    
    const latestDate = new Date(sessions[0].session_time);
    const oldestDate = new Date(sessions[sessions.length - 1].session_time);
    
    const monthDiff = (latestDate.getMonth() - oldestDate.getMonth()) + 
                     (12 * (latestDate.getFullYear() - oldestDate.getFullYear()));
                     
    return monthDiff + 1; // Add 1 to include current month
  };
  

  const calculateLeaderProgress = (leader) => {
    if (!leader.sessions.length) return 0;
    
    // Session Consistency (30%)
    const monthlyTarget = 4;
    const sessions = [...leader.sessions].sort((a, b) => new Date(b.session_time) - new Date(a.session_time));
    const latestDate = new Date(sessions[0].session_time);
    const oldestDate = new Date(sessions[sessions.length - 1].session_time);
    const monthsDiff = (latestDate.getMonth() - oldestDate.getMonth()) + 
                      (12 * (latestDate.getFullYear() - oldestDate.getFullYear())) + 1;
    const consistencyScore = Math.min(100, (leader.sessions.length / (monthlyTarget * monthsDiff)) * 100) * 0.3;

    // Energy Level Progress (40%)
    const energyScore = (leader.stats.avg_energy_level / 5) * 100 * 0.4;

    // Improvement Rate (30%)
    const improvementCount = leader.sessions.filter(s => s.improved_emotion).length;
    const improvementScore = (improvementCount / leader.sessions.length) * 100 * 0.3;

    return Math.round(consistencyScore + energyScore + improvementScore);
  };

  const getMetricColor = (value) => {
    if (value >= 4 || value >= 75) return COLORS.success;
    if (value >= 2.5 || value >= 50) return COLORS.warning;
    return COLORS.error;
  };

  const sortSessions = (sessions) => {
    return [...sessions].sort((a, b) => {
      switch (sessionSortBy) {
        case 'date':
          return new Date(b.session_time) - new Date(a.session_time);
        case 'duration':
          return (b.session_duration || 0) - (a.session_duration || 0);
        case 'score':
          return (b.energy_level || 0) - (a.energy_level || 0);
        default:
          return 0;
      }
    });
  };

  const calculateLeaderMetrics = (leader) => {
    if (!leader.sessions.length) return { 
      total: 0,
      consistency: 0,
      energy: 0,
      improvement: 0
    };

    // Consistency Score (30%)
    const monthlyTarget = 4;
    const monthsDiff = calculateMonthsDiff(leader);
    const consistencyScore = Math.min(100, (leader.sessions.length / (monthlyTarget * monthsDiff)) * 100);

    // Energy Score (40%)
    const energyScore = (leader.stats.avg_energy_level / 5) * 100;

    // Improvement Score (30%)
    const improvedSessions = leader.sessions.filter(s => s.improved_emotion).length;
    const improvementScore = (improvedSessions / leader.sessions.length) * 100;

    return {
      total: Math.round(consistencyScore * 0.3 + energyScore * 0.4 + improvementScore * 0.3),
      consistency: consistencyScore,
      energy: energyScore,
      improvement: improvementScore,
      totalSessions: leader.sessions.length,
      improvedSessions
    };
  };

  const groupSessionsByMonth = (sessions) => {
    return sessions.reduce((groups, session) => {
      const date = new Date(session.session_time);
      const key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(session);
      return groups;
    }, {});
  };

  // Filtered and sorted leaders
  const processedLeaders = useMemo(() => {
    if (!userData?.users) return [];
    
    return userData.users
      .filter(leader => {
        const matchesSearch = leader.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            leader.department.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesDepartment = filterDepartment === 'all' || leader.department === filterDepartment;
        return matchesSearch && matchesDepartment;
      })
      .map(leader => ({
        ...leader,
        progress: calculateLeaderProgress(leader)
      }))
      .sort((a, b) => {
        switch (leaderSortBy) {
          case 'progress':
            return b.progress - a.progress;
          case 'sessions':
            return b.stats.total_sessions - a.stats.total_sessions;
          case 'score':
            return b.stats.avg_energy_level - a.stats.avg_energy_level;
          case 'name':
            return a.email.localeCompare(b.email);
          default:
            return 0;
        }
      });
  }, [userData, searchTerm, filterDepartment, leaderSortBy]);

  const departments = useMemo(() => {
    if (!userData?.users) return [];
    return ['all', ...new Set(userData.users.map(user => user.department))];
  }, [userData]);

  const handleShowReport = () => {
    const cachedReport = sessionStorage.getItem('adminReport');
    navigate("/admin-report", { 
      state: { adminReport: cachedReport || userData?.admin_report } 
    });
  };

  const handleShowSummary = (summary) => {
    setCurrentSummary(summary);
    setSummaryDialogOpen(true);
  };

  const handleSortMenuOpen = (event) => {
    setSortMenuAnchor(event.currentTarget);
  };

  if (loading) {
    return (
      <Box sx={{ 
        height: '100vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        backgroundColor: COLORS.secondary 
      }}>
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <DashboardContainer>
      <MainCard>
        {/* Header Section */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 4, 
          flexWrap: 'wrap', 
          gap: 2 
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Avatar sx={{ 
              width: 56, 
              height: 56, 
              bgcolor: COLORS.primary,
              boxShadow: `0 8px 16px ${alpha(COLORS.primary, 0.2)}`
            }}>
              <TimelineIcon sx={{ fontSize: 32 }} />
            </Avatar>
            <Box>
              <Typography variant="h4" sx={{ 
                color: COLORS.primary, 
                fontWeight: 600, 
                letterSpacing: '-0.5px' 
              }}>
                Leadership Dashboard
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                {`Managing ${userData.total_stats.total_users} leaders across ${userData.total_stats.departments.length} departments`}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              startIcon={<PersonAddIcon />}
              onClick={() => navigate("/add-employee")}
              sx={{
                backgroundColor: COLORS.primary,
                '&:hover': { backgroundColor: alpha(COLORS.primary, 0.9) }
              }}
            >
              Add Leader
            </Button>
            <Button
              variant="contained"
              startIcon={<AssessmentIcon />}
              onClick={handleShowReport}
              sx={{
                backgroundColor: COLORS.accent,
                '&:hover': { backgroundColor: alpha(COLORS.accent, 0.9) }
              }}
            >
              View Report
            </Button>
          </Box>
        </Box>

        {/* Stats Overview */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
                    Total Leaders
                  </Typography>
                  <Typography variant="h4" sx={{ color: COLORS.primary, fontWeight: 600 }}>
                    {userData.total_stats.total_users}
                  </Typography>
                </Box>
                <Avatar sx={{ bgcolor: alpha(COLORS.primary, 0.1), color: COLORS.primary }}>
                  <BusinessIcon />
                </Avatar>
              </Box>
              <Typography variant="body2" sx={{ color: 'text.secondary', mt: 2 }}>
                {`Across ${userData.total_stats.departments.length} departments`}
              </Typography>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>Total Sessions
                  </Typography>
                  <Typography variant="h4" sx={{ color: COLORS.primary, fontWeight: 600 }}>
                    {userData.total_stats.total_sessions}
                  </Typography>
                </Box>
                <Avatar sx={{ bgcolor: alpha(COLORS.primary, 0.1), color: COLORS.primary }}>
                  <TimelineIcon />
                </Avatar>
              </Box>
              <Typography variant="body2" sx={{ color: 'text.secondary', mt: 2 }}>
                Average {formatDuration(userData.total_stats.avg_company_duration)} per session
              </Typography>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
                    Average Score
                  </Typography>
                  <Typography variant="h4" sx={{ 
                    fontWeight: 600,
                    color: getMetricColor(userData.total_stats.avg_company_energy)
                  }}>
                    {userData.total_stats.avg_company_energy.toFixed(1)}
                  </Typography>
                </Box>
                <Avatar sx={{ 
                  bgcolor: alpha(getMetricColor(userData.total_stats.avg_company_energy), 0.1), 
                  color: getMetricColor(userData.total_stats.avg_company_energy)
                }}>
                  <EmojiEmotionsIcon />
                </Avatar>
              </Box>
              <Typography variant="body2" sx={{ color: 'text.secondary', mt: 2 }}>
                Company-wide average energy level
              </Typography>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
                    Average Progress
                  </Typography>
                  <Typography variant="h4" sx={{ 
                    fontWeight: 600,
                    color: getMetricColor(
                      processedLeaders.reduce((acc, leader) => acc + leader.progress, 0) / processedLeaders.length
                    )
                  }}>
                    {Math.round(processedLeaders.reduce((acc, leader) => acc + leader.progress, 0) / processedLeaders.length)}%
                  </Typography>
                </Box>
                <Avatar sx={{ bgcolor: alpha(COLORS.primary, 0.1), color: COLORS.primary }}>
                  <TrendingUpIcon />
                </Avatar>
              </Box>
              <Typography variant="body2" sx={{ color: 'text.secondary', mt: 2 }}>
                Overall leadership development
              </Typography>
            </StatCard>
          </Grid>
        </Grid>

        {/* Filters and Controls */}
        <Box sx={{ mb: 4, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <TextField
            size="small"
            placeholder="Search leaders..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
            }}
            sx={{ flexGrow: 1, maxWidth: 300 }}
          />
          <TextField
            select
            size="small"
            value={filterDepartment}
            onChange={(e) => setFilterDepartment(e.target.value)}
            sx={{ minWidth: 200 }}
            InputProps={{
              startAdornment: <FilterListIcon sx={{ color: 'text.secondary', mr: 1 }} />,
            }}
          >
            {departments.map((dept) => (
              <MenuItem key={dept} value={dept}>
                {dept === 'all' ? 'All Departments' : dept}
              </MenuItem>
            ))}
          </TextField>
          <Button
            size="small"
            startIcon={<SortIcon />}
            endIcon={<ExpandMoreIcon />}
            onClick={handleSortMenuOpen}
            sx={{
              bgcolor: alpha(COLORS.primary, 0.1),
              color: COLORS.primary,
              '&:hover': {
                bgcolor: alpha(COLORS.primary, 0.15),
              }
            }}
          >
            Sort Leaders
          </Button>
        </Box>


        {/* Leaders List */}
        <Box>
          {processedLeaders.map((leader) => {
            const metrics = calculateLeaderMetrics(leader);
            const groupedSessions = groupSessionsByMonth(leader.sessions);
            const monthKeys = Object.keys(groupedSessions).sort().reverse();

            return (
              <LeaderCard key={leader.id}>
                {/* Leader Header */}
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'flex-start',
                  gap: 3,
                  mb: 3
                }}>
                  <Avatar 
                    sx={{ 
                      width: 56,
                      height: 56,
                      bgcolor: alpha(COLORS.primary, 0.1), 
                      color: COLORS.primary 
                    }}
                  >
                    {leader.email.substring(0, 2).toUpperCase()}
                  </Avatar>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 0.5 }}>
                      <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        {leader.email}
                      </Typography>
                      <ProgressTooltip
                        arrow
                        placement="right"
                        title={
                          <Box>
                            <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
                              Progress Breakdown
                            </Typography>
                            <ProgressComponent
                              value={metrics.consistency}
                              type="consistency"
                              total={metrics.totalSessions}
                              label="Session Consistency (30%)"
                            />
                            <ProgressComponent
                              value={metrics.energy}
                              type="energy"
                              total={metrics.totalSessions}
                              label="Energy Level Progress (40%)"
                            />
                            <ProgressComponent
                              value={metrics.improvement}
                              type="improvement"
                              total={metrics.improvedSessions}
                              label="Improvement Rate (30%)"
                            />
                            <Divider sx={{ my: 1 }} />
                            <Typography variant="body2" sx={{ fontWeight: 500, color: COLORS.primary }}>
                              Total Progress: {metrics.total}%
                            </Typography>
                          </Box>
                        }
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <IconButton size="small" sx={{ color: COLORS.primary }}>
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: getMetricColor(metrics.total),
                              fontWeight: 600 
                            }}
                          >
                            {metrics.total}% Overall Progress
                          </Typography>
                        </Box>
                      </ProgressTooltip>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <BusinessIcon sx={{ color: COLORS.primary, fontSize: 16 }} />
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {leader.department}
                        </Typography>
                      </Box>
                      <Chip
                        size="small"
                        icon={<AccessTimeIcon sx={{ fontSize: '16px' }} />}
                        label={`${leader.stats.total_sessions} sessions`}
                        sx={{
                          backgroundColor: alpha(COLORS.primary, 0.1),
                          color: COLORS.primary
                        }}
                      />
                      <Chip
                        size="small"
                        icon={<EmojiEmotionsIcon sx={{ fontSize: '16px' }} />}
                        label={`${leader.stats.avg_energy_level.toFixed(1)} avg`}
                        sx={{
                          backgroundColor: alpha(getMetricColor(leader.stats.avg_energy_level), 0.1),
                          color: getMetricColor(leader.stats.avg_energy_level)
                        }}
                      />
                    </Box>
                    
                      {/* Progress Bars Section */}
                      <LeaderProgressMetrics 
                        leader={leader} 
                        metrics={metrics} 
                      />

                  </Box>
                </Box>

                {/* Sessions by Month */}
                <Tabs
                    value={expandedLeader === leader.id}
                    onChange={(e, newValue) => {
                      // If it's already expanded, collapse it
                      if (expandedLeader === leader.id) {
                        setExpandedLeader(null);
                      } else {
                        // If it's not expanded, expand it
                        setExpandedLeader(leader.id);
                      }
                    }}
                    sx={{ mb: 2 }}
                  >
                    <Tab 
                      label={expandedLeader === leader.id ? "Hide Sessions" : "View Sessions"}
                      icon={<TimelineIcon />} 
                      iconPosition="start"
                    />
                  </Tabs>

                {expandedLeader === leader.id && (
                  <Box>
                    {monthKeys.map((monthKey) => {
                      const sessions = groupedSessions[monthKey];
                      const [year, month] = monthKey.split('-');
                      const monthName = new Date(year, month - 1).toLocaleString('default', { month: 'long' });
                      
                      return (
                        <SessionGroup key={monthKey}>
                          <Typography 
                            variant="subtitle1" 
                            sx={{ 
                              mb: 2, 
                              color: COLORS.primary,
                              fontWeight: 500,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1
                            }}
                          >
                            <CalendarTodayIcon sx={{ fontSize: 20 }} />
                            {`${monthName} ${year}`}
                            <Chip 
                              size="small"
                              label={`${sessions.length} sessions`}
                              sx={{ 
                                ml: 1,
                                bgcolor: alpha(COLORS.primary, 0.1),
                                color: COLORS.primary
                              }}
                            />
                          </Typography>

                          {sessions.map((session) => (
                            <Box
                              key={session.session_id}
                              sx={{
                                p: 2,
                                mb: 1,
                                borderRadius: 2,
                                bgcolor: '#fff',
                                '&:hover': {
                                  bgcolor: alpha(COLORS.primary, 0.02)
                                }
                              }}
                            >
                              <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: session.improved_emotion ? 1 : 0
                              }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                  <Typography variant="body2">
                                    {new Date(session.session_time).toLocaleDateString(undefined, {
                                      day: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit'
                                    })}
                                  </Typography>
                                  <Chip
                                    size="small"
                                    label={session.session_topic || 'No Topic'}
                                    sx={{
                                      backgroundColor: alpha(COLORS.primary, 0.1),
                                      color: COLORS.primary
                                    }}
                                  />
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                  {session.energy_level && (
                                    <Tooltip title="Energy Level">
                                      <Chip
                                        size="small"
                                        label={`Score: ${session.energy_level}`}
                                        sx={{
                                          backgroundColor: alpha(getMetricColor(session.energy_level), 0.1),
                                          color: getMetricColor(session.energy_level)
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                  {session.session_duration && (
                                    <Tooltip title="Session Duration">
                                      <Chip
                                        size="small"
                                        icon={<AccessTimeIcon sx={{ fontSize: '16px' }} />}
                                        label={formatDuration(session.session_duration)}
                                        sx={{
                                          backgroundColor: alpha(COLORS.primary, 0.1),
                                          color: COLORS.primary
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                  {session.summary && (
                                    <Tooltip title="View Summary">
                                      <IconButton
                                        size="small"
                                        onClick={() => handleShowSummary(session.summary)}
                                        sx={{ color: COLORS.primary }}
                                      >
                                        <DescriptionIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </Box>

                              {session.improved_emotion && (
                                <Box sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  mt: 1,
                                  backgroundColor: alpha(COLORS.success, 0.1),
                                  borderRadius: '8px',
                                  padding: '8px 16px',
                                  width: 'fit-content'
                                }}>
                                  <TrendingUpIcon fontSize="small" sx={{ color: COLORS.success }} />
                                  <Typography variant="body2" sx={{ color: COLORS.success }}>
                                    {`${session.improved_emotion} improved by ${session.improved_by}`}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          ))}
                        </SessionGroup>
                      );
                    })}
                  </Box>
                )}
              </LeaderCard>
            );
          })}
        </Box>
        {/* Summary Dialog */}
        <Dialog
          open={summaryDialogOpen}
          onClose={() => setSummaryDialogOpen(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: '16px',
              padding: '8px'
            }
          }}
        >
          <DialogTitle sx={{ 
            backgroundColor: alpha(COLORS.primary, 0.05),
            color: COLORS.primary,
            borderRadius: '8px'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <DescriptionIcon />
              Session Summary
            </Box>
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <DialogContentText sx={{ 
              whiteSpace: 'pre-line',
              color: 'text.primary',
              fontSize: '1rem',
              lineHeight: 1.6
            }}>
              {currentSummary}
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/* Sort Menu */}
        <Menu
          anchorEl={sortMenuAnchor}
          open={Boolean(sortMenuAnchor)}
          onClose={() => setSortMenuAnchor(null)}
          PaperProps={{
            elevation: 3,
            sx: {
              borderRadius: '12px',
              mt: 1
            }
          }}
        >
          <MenuItem 
            onClick={() => {
              setLeaderSortBy('progress');
              setSortMenuAnchor(null);
            }}
            sx={{ minWidth: 160 }}
          >
            Sort by Progress
          </MenuItem>
          <MenuItem 
            onClick={() => {
              setLeaderSortBy('sessions');
              setSortMenuAnchor(null);
            }}
          >
            Sort by Sessions
          </MenuItem>
          <MenuItem 
            onClick={() => {
              setLeaderSortBy('score');
              setSortMenuAnchor(null);
            }}
          >
            Sort by Score
          </MenuItem>
          <MenuItem 
            onClick={() => {
              setLeaderSortBy('name');
              setSortMenuAnchor(null);
            }}
          >
            Sort by Name
          </MenuItem>
        </Menu>

      </MainCard>
    </DashboardContainer>
  );
};

export default ConsultantAdmin;