import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  Avatar,
  Fade,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy'; // For role-play
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExtensionIcon from '@mui/icons-material/Extension';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GroupsIcon from '@mui/icons-material/Groups';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BuildIcon from '@mui/icons-material/Build';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SettingsIcon from '@mui/icons-material/Settings';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  height: '100%',
  backgroundColor: '#5C83BF',
  color: 'white',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 20px rgba(92, 131, 191, 0.2)',
  },
}));

const FeaturePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  height: '100%',
  backgroundColor: '#FFFFFF',
  transition: 'all 0.3s ease-in-out',
  border: '1px solid rgba(92, 131, 191, 0.1)',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 20px rgba(92, 131, 191, 0.1)',
  },
}));

const CTAButton = styled(Button)({
  backgroundColor: '#5C83BF',
  color: 'white',
  textTransform: 'none',
  fontSize: '18px',
  padding: '16px 48px',
  borderRadius: '12px',
  transition: 'all 0.3s ease',
  boxShadow: '0 4px 14px rgba(92, 131, 191, 0.2)',
  '&:hover': {
    backgroundColor: '#4A699C',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(92, 131, 191, 0.3)',
  },
});

const ValueProposition = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: '0 2px 8px rgba(92, 131, 191, 0.1)',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'translateX(5px)',
  },
}));

const StatBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  textAlign: 'center',
  height: '100%',
  boxShadow: '0 2px 8px rgba(92, 131, 191, 0.1)',
}));

const WhyPokaMind = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const valueProps = [
    "82% improvement in managerial decision-making through AI-powered scenarios",
    "5-minute daily training sessions that fit into any schedule",
    "Real-time feedback and analytics on leadership performance",
    "Instant access to company policies and best practices via Chrome extension"
  ];

  const features = [
    {
      icon: <TheaterComedyIcon />,
      title: "AI Role-Play Training",
      description: "Practice leadership scenarios in safe, personalized 5-minute sessions. Get immediate feedback on your communication style and decision-making."
    },
    {
      icon: <ExtensionIcon />,
      title: "Smart Chrome Extension",
      description: "Access company policies and leadership best practices instantly. Get AI-powered guidance for real-world situations as they happen."
    },
    {
      icon: <AnalyticsIcon />,
      title: "Real-Time Analytics",
      description: "Track leadership development progress with comprehensive analytics."
    }
  ];

  const services = [
    {
      icon: <PersonalVideoIcon />,
      title: 'Interactive Scenarios',
      description: 'Custom role-play situations based on your industry and organizational challenges'
    },
    {
      icon: <TrendingUpIcon />,
      title: 'Performance Tracking',
      description: 'Detailed analytics on leadership skills development and team engagement metrics'
    },
    {
      icon: <GroupsIcon />,
      title: 'Team Wellness Insights',
      description: 'Monitor and improve team wellbeing through daily one-question feedback'
    },
    {
      icon: <BuildIcon />,
      title: 'Customization Tools',
      description: 'Tailor training scenarios and metrics to your organizations specific needs'
    }
  ];

  const stats = [
    { number: '82%', text: 'Better Decision Making' },
    { number: '70%', text: 'Team Engagement Impact' },
    { number: '5min', text: 'Daily Training Time' },
    { number: '24/7', text: 'AI Support Access' }
  ];

  return (
    <Box sx={{ backgroundColor: '#FFF7F1', minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ pt: { xs: 8, md: 12 }, pb: 6 }}>
        <Container maxWidth="lg">
          <Fade in timeout={800}>
            <Box textAlign="center" mb={8}>
              <Typography
                variant="overline"
                sx={{
                  color: '#5C83BF',
                  fontWeight: 600,
                  letterSpacing: 2,
                  mb: 2,
                  display: 'block'
                }}
              >
                AI-POWERED LEADERSHIP DEVELOPMENT PLATFORM
              </Typography>
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  color: '#2A2A2A',
                  fontWeight: 700,
                  mb: 3,
                  fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem' },
                }}
              >
                Transform Your Leadership Impact
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: '#666666',
                  mb: 6,
                  maxWidth: '800px',
                  mx: 'auto',
                  lineHeight: 1.6
                }}
              >
                Practice and perfect your leadership skills through personalized AI role-play scenarios. 
                Get instant feedback, track team wellbeing, and access policy guidance 24/7.
              </Typography>

              <Box mb={6}>
                {valueProps.map((prop, index) => (
                  <ValueProposition key={index}>
                    <CheckCircleIcon sx={{ color: '#5C83BF' }} />
                    <Typography variant="body1" color="#2A2A2A" fontWeight={500}>
                      {prop}
                    </Typography>
                  </ValueProposition>
                ))}
              </Box>

              <CTAButton
                size="large"
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigate('/auth')}
              >
                Start Training Now
              </CTAButton>
            </Box>
          </Fade>
        </Container>
      </Box>

      {/* Stats Section */}
      <Container maxWidth="lg" sx={{ mb: 8 }}>
        <Grid container spacing={3}>
          {stats.map((stat, index) => (
            <Grid item xs={6} md={3} key={index}>
              <StatBox>
                <Typography variant="h3" color="#5C83BF" fontWeight={700} mb={1}>
                  {stat.number}
                </Typography>
                <Typography variant="body1" color="#666666">
                  {stat.text}
                </Typography>
              </StatBox>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Core Features */}
      <Container maxWidth="lg" sx={{ mb: 12 }}>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{ 
            color: '#2A2A2A',
            mb: 6,
            fontWeight: 700,
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
          }}
        >
          Develop Better Leaders
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <StyledPaper elevation={3}>
                <Box sx={{ mb: 3 }}>
                  <Avatar sx={{ bgcolor: 'white', width: 56, height: 56 }}>
                    {React.cloneElement(feature.icon, { sx: { fontSize: 32, color: '#5C83BF' } })}
                  </Avatar>
                </Box>
                <Typography variant="h5" fontWeight={600} mb={2}>
                  {feature.title}
                </Typography>
                <Typography variant="body1" sx={{ opacity: 0.9 }}>
                  {feature.description}
                </Typography>
              </StyledPaper>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Services Section */}
      <Container maxWidth="lg" sx={{ mb: 12 }}>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{ 
            color: '#2A2A2A',
            mb: 6,
            fontWeight: 700,
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
          }}
        >
          Comprehensive Leadership Tools
        </Typography>
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} md={6} key={index}>
              <FeaturePaper elevation={0}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 3 }}>
                  <Avatar 
                    sx={{ 
                      bgcolor: 'rgba(92, 131, 191, 0.1)', 
                      width: 56, 
                      height: 56 
                    }}
                  >
                    {React.cloneElement(service.icon, { 
                      sx: { fontSize: 32, color: '#5C83BF' } 
                    })}
                  </Avatar>
                  <Box>
                    <Typography variant="h5" color="#2A2A2A" fontWeight={600} mb={1}>
                      {service.title}
                    </Typography>
                    <Typography variant="body1" color="#666666">
                      {service.description}
                    </Typography>
                  </Box>
                </Box>
              </FeaturePaper>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Final CTA Section */}
      <Container maxWidth="lg" sx={{ pb: 12 }}>
        <Fade in timeout={1000}>
          <Box 
            sx={{
              backgroundColor: '#5C83BF',
              borderRadius: 4,
              p: { xs: 4, md: 8 },
              color: 'white',
              textAlign: 'center',
            }}
          >
            <Typography variant="h3" mb={3} fontWeight={700}>
              Ready to Transform Your Leadership?
            </Typography>
            <Typography variant="h6" mb={4} sx={{ opacity: 0.9, maxWidth: 600, mx: 'auto' }}>
              Join forward-thinking organizations that are developing exceptional leaders through AI-powered training
            </Typography>
            <Button
              size="large"
              className="mx-auto"
              variant="contained"
              onClick={() => navigate('/auth')}
              sx={{
                backgroundColor: 'white',
                color: '#5C83BF',
                fontSize: '18px',
                padding: '16px 48px',
                borderRadius: '12px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                  transform: 'translateY(-2px)',
                }
              }}
            >
              Start Training Now
            </Button>
          </Box>
        </Fade>
      </Container>
    </Box>
  );
};

export default WhyPokaMind;