import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Grid, 
  Typography, 
  IconButton, 
  Paper, 
  Box,
  useTheme, 
  useMediaQuery,
  Alert,
  CircularProgress,
  Divider,
  Chip,
  Button
} from "@mui/material";
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HistoryIcon from '@mui/icons-material/History';
import UpdateIcon from '@mui/icons-material/Update';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useUser } from "../../contexts/UserContext";
import { URL } from '../../Helpers';

// Styled Components
const ReportContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  minHeight: '100vh',
  backgroundColor: '#FFF7F1'
}));

const ReportCard = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 24px rgba(0, 0, 0, 0.12)'
  }
}));

const ReportHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(3),
  color: 'white',
  borderRadius: '16px 16px 0 0'
}));


const MarkdownContent = styled(Box)(({ theme }) => ({
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    fontWeight: 600
  },
  '& h1': { fontSize: '2rem' },
  '& h2': { fontSize: '1.75rem' },
  '& h3': { fontSize: '1.5rem' },
  '& h4': { fontSize: '1.25rem' },
  '& p': {
    marginBottom: theme.spacing(2),
    lineHeight: 1.7,
    color: theme.palette.text.primary
  },
  '& ul, & ol': {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  '& li': {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary
  },
  '& strong': {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  '& blockquote': {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    marginLeft: 0,
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}));

const ReportMeta = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2)
}));

const WellbeingReport = () => {
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useUser();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  const goBack = () => navigate("/get-started");

  useEffect(() => {
    const fetchReport = async () => {
      try {
        setLoading(true);
        
        // Check sessionStorage first
        const cachedReport = sessionStorage.getItem('adminReport');
        const cachedTimestamp = sessionStorage.getItem('reportTimestamp');
        
        if (cachedReport && cachedTimestamp) {
          setReport(cachedReport);
          setLastUpdated(new Date(cachedTimestamp));
          setLoading(false);
          return;
        }

        // If no cached report or from location state, fetch new one
        if (!location.state?.adminReport) {
          const idToken = await user.getIdToken();
          const response = await fetch(`${URL}/get_users_sessions_by_company_consultant`, {
            headers: { Authorization: `Bearer ${idToken}` }
          });

          if (!response.ok) throw new Error('Failed to fetch report');
          const data = await response.json();
          
          if (data.admin_report) {
            setReport(data.admin_report);
            setLastUpdated(new Date());
            
            // Cache the report
            sessionStorage.setItem('adminReport', data.admin_report);
            sessionStorage.setItem('reportTimestamp', new Date().toISOString());
          } else {
            throw new Error('No report data available');
          }
        } else {
          setReport(location.state.adminReport);
          setLastUpdated(new Date());
          
          // Cache the report from location state
          sessionStorage.setItem('adminReport', location.state.adminReport);
          sessionStorage.setItem('reportTimestamp', new Date().toISOString());
        }

        setError(null);
      } catch (err) {
        console.error('Error fetching report:', err);
        setError('There was an error loading the report. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [user, location.state]);

  if (loading) {
    return (
      <ReportContainer>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      </ReportContainer>
    );
  }

  return (
    <ReportContainer>
      {/* blue pill button */}
      <Button onClick={goBack} startIcon={<ArrowBackIcon />} sx={{ mb: 2 }} variant="outlined" color="primary">
        {!isSmallScreen && (
          <Typography variant="subtitle1" sx={{ ml: 1, color: 'text.primary' }}>
            Back to Dashboard
          </Typography>
        )}
      </Button>

      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={11} md={10} lg={8}>
          <ReportCard>
            <ReportHeader>
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                Leadership Insights Report
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 1, opacity: 0.9 }}>
                Comprehensive analysis of employee leadership and engagement
              </Typography>
            </ReportHeader>

            {lastUpdated && (
              <ReportMeta>
                <Chip
                  icon={<HistoryIcon />}
                  label={`Generated: ${lastUpdated.toLocaleString()}`}
                  variant="outlined"
                  size="small"
                />
                <Chip
                  icon={<UpdateIcon />}
                  label="Auto-saved"
                  variant="outlined"
                  size="small"
                  color="success"
                />
              </ReportMeta>
            )}

            <Box p={isSmallScreen ? 2 : 4}>
              {error ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              ) : report ? (
                <MarkdownContent>
                  <ReactMarkdown 
                    remarkPlugins={[remarkGfm]}
                    components={{
                      h1: ({node, ...props}) => <Typography variant="h1" {...props} />,
                      h2: ({node, ...props}) => <Typography variant="h2" {...props} />,
                      h3: ({node, ...props}) => <Typography variant="h3" {...props} />,
                      h4: ({node, ...props}) => <Typography variant="h4" {...props} />,
                      p: ({node, ...props}) => <Typography variant="body1" {...props} />,
                      li: ({node, ...props}) => <Typography component="li" variant="body1" {...props} />
                    }}
                  >
                    {report}
                  </ReactMarkdown>
                </MarkdownContent>
              ) : (
                <Alert severity="info">
                  No report data available. Please generate a new report from the dashboard.
                </Alert>
              )}
            </Box>
          </ReportCard>
        </Grid>
      </Grid>
    </ReportContainer>
  );
};

export default WellbeingReport;