// HomePage.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import HomeCarousel from './HomeCarousel';
import './HomePage.scss';

const HomePage = () => {
  return (
    <div className="prevent_overflow">
      <Grid 
        className='home-slide' 
        container 
        alignItems='stretch'
        sx={{ 
          p: 0, 
          m: 0,
          minHeight: '100vh',
          width: '100vw'
        }}
      >
        <HomeCarousel />
      </Grid>
    </div>
  );
};

export default HomePage;