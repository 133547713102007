import React, { useState, useEffect, useMemo } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import './WellnessLoader.scss';

const WellnessLoader = () => {
  const [progress, setProgress] = useState(0);
  const [isBreathing, setIsBreathing] = useState(true);
  const [breathCount, setBreathCount] = useState(0);
  
  // Memoize messages array without emojis
  const messages = useMemo(() => [
    "Calibrating your leadership compass",
    "Analyzing management potential",
    "Loading best practices",
    "Preparing your growth mindset",
    "Assembling team insights",
    "Optimizing decision matrices",
    "Enhancing communication channels",
    "Building leadership confidence",
    "Mapping success strategies",
    "Unlocking management wisdom",
    "Processing leadership patterns",
    "Activating coaching mode",
    "Syncing team dynamics",
    "Gathering leadership insights",
    "Empowering your potential"
  ], []);

  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const [nextMessage, setNextMessage] = useState(messages[0]);
  const [isMessageFading, setIsMessageFading] = useState(false);

  // Progress timer effect
  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          return 100;
        }
        return prev + (100 / 200); // 20 seconds total
      });
    }, 100);

    return () => clearInterval(progressInterval);
  }, []);

  // Message rotation effect with smooth transition
  useEffect(() => {
    let messageIndex = 0;
    const messageInterval = setInterval(() => {
      messageIndex = (messageIndex + 1) % messages.length;
      setIsMessageFading(true);
      
      // Start fade out
      setTimeout(() => {
        setNextMessage(messages[messageIndex]);
        // Complete transition
        setTimeout(() => {
          setCurrentMessage(messages[messageIndex]);
          setIsMessageFading(false);
        }, 300);
      }, 300);
    }, 3500);

    return () => clearInterval(messageInterval);
  }, [messages]);

  // Breathing animation effect with smooth text transition
  useEffect(() => {
    const breathingInterval = setInterval(() => {
      setIsBreathing((prev) => !prev);
      setBreathCount((prev) => prev + 1);
    }, 4000);

    return () => clearInterval(breathingInterval);
  }, []);

  return (
    <div className="wellness-loader">
      <div className="loader-background">
        {[1, 2, 3].map((index) => (
          <div 
            key={index}
            className={`background-circle circle-${index} ${isBreathing ? 'expand' : 'contract'}`}
            style={{
              animationDelay: `${index * 0.2}s`,
              opacity: 0.7 - (index * 0.2)
            }}
          />
        ))}
      </div>
      
      <div className="loader-content">
        <div className="progress-section">
          <div className="progress-wrapper">
            <CircularProgress
              variant="determinate"
              value={100}
              size={100}
              className="progress-background"
            />
            <CircularProgress
              variant="determinate"
              value={progress}
              size={100}
              className="progress-indicator"
            />
          </div>

          <div className="message-container">
            <Typography 
              className={`loading-message ${isMessageFading ? 'fade-out' : 'fade-in'}`}
              variant="h5"
            >
              {isMessageFading ? nextMessage : currentMessage}
            </Typography>
          </div>

          <Typography className="progress-text" variant="body1">
            {Math.round(progress)}%
          </Typography>
        </div>

        <div className="breathing-section">
          <div className="breathing-guide">
            <div 
              className={`breathing-circle ${isBreathing ? 'breathe-in' : 'breathe-out'}`}
              style={{
                transform: `scale(${isBreathing ? 1.3 : 1})`,
                transition: 'all 4s cubic-bezier(0.4, 0, 0.2, 1)'
              }}
            >
              <div className="breath-text-wrapper">
                <span 
                  className={`breath-text ${isBreathing ? 'text-fade-in' : 'text-fade-out'}`}
                >
                  breathe {isBreathing ? 'in' : 'out'}
                </span>
              </div>
              {[...Array(3)].map((_, i) => (
                <div 
                  key={i}
                  className="breath-ripple"
                  style={{ 
                    animationDelay: `${i * 0.5}s`,
                    animationName: isBreathing ? 'rippleExpand' : 'rippleContract'
                  }}
                />
              ))}
            </div>
            <Typography className="breath-count" variant="caption">
              Breaths: {breathCount}
            </Typography>
          </div>
        </div>

        <Typography className="instruction-text" variant="body2">
          Take a moment to breathe while we prepare your experience.
          <br />
          Please don't refresh - we're crafting something special for you.
        </Typography>
      </div>
    </div>
  );
};

export default WellnessLoader;