import React, { useState, useEffect, useCallback } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  Button,
  LinearProgress,
  Avatar,
  Chip,
  Tooltip,
  Badge,
  Zoom
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, db } from '../../../Firebase';
import { getDownloadURL, ref, storage } from '../../../Firebase';
import { useUser } from '../../../contexts/UserContext';
import { URL } from "../../../Helpers";
import { useErrorBoundary } from 'react-error-boundary';
import CollectionsIcon from '@mui/icons-material/Collections';
import StarIcon from '@mui/icons-material/Star';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

import sessionImg from "../../data/session.png";
import micImg from "../../data/mic2.png";
import podcastImg from "../../data/pokapod.png";
import { ReactComponent as Checked } from '../../data/task_alt.svg';
import { ReactComponent as Pending } from '../../data/Pending.svg';
import LoadingSpinner from '../shared/LoadingSpinner';
import axios from 'axios';

const TOTAL_WEEKLY_TARGET = 3;

// Color constants for better maintenance
const COLORS = {
  primary: '#4C6481',
  secondary: '#5C83BF',
  success: '#4CAF50',
  warning: '#FF9800',
  gold: '#FFD700',
  lightBlue: '#E1EDFF',
  darkBlue: '#2C3E50',
  red: '#FF6B6B',
  green: '#81C784',
  highlight: '#FFB74D'
};

const GRADIENTS = {
  success: `linear-gradient(45deg, ${COLORS.success}, ${COLORS.green})`,
  gold: `linear-gradient(45deg, ${COLORS.gold}, ${COLORS.warning})`,
  primary: `linear-gradient(45deg, ${COLORS.primary}, ${COLORS.secondary})`,
  red: `linear-gradient(45deg, ${COLORS.red}, #FF8E8E)`,
  dark: `linear-gradient(45deg, ${COLORS.darkBlue}, ${COLORS.primary})`
};

const ANIMATIONS = {
  duration: {
    fast: '0.2s',
    normal: '0.3s',
    slow: '0.5s'
  },
  timing: 'cubic-bezier(0.4, 0, 0.2, 1)'
};

const PRESTIGE_LEVELS = {
  BEGINNER: { 
    min: 0, 
    label: ' Beginner', 
    icon: '🌱', 
    color: COLORS.success,
    gradient: GRADIENTS.success
  },
  INTERMEDIATE: { 
    min: 10, 
    label: ' Rising Star', 
    icon: '⭐', 
    color: COLORS.primary,
    gradient: `linear-gradient(45deg, ${COLORS.primary}, ${COLORS.highlight})`
  },
  ADVANCED: { 
    min: 25, 
    label: ' Expert', 
    icon: '🌟', 
    color: COLORS.warning,
    gradient: `linear-gradient(45deg, ${COLORS.warning}, ${COLORS.highlight})`
  },
  MASTER: { 
    min: 50, 
    label: ' Master', 
    icon: '👑', 
    color: COLORS.gold,
    gradient: GRADIENTS.gold
  }
};

const ACHIEVEMENTS = {
  extraordinary: {
    threshold: 5,
    emoji: '🔥',
    label: 'Extraordinary',
    color: COLORS.red,
    gradient: GRADIENTS.red,
    glow: '0 0 15px rgba(255, 107, 107, 0.3)',
    rewards: '+5 Bonus Points'
  },
  exceptional: {
    threshold: 4,
    emoji: '🌟',
    label: 'Exceptional',
    color: COLORS.gold,
    gradient: GRADIENTS.gold,
    glow: '0 0 12px rgba(255, 215, 0, 0.2)',
    rewards: '+4 Bonus Points'
  },
  outstanding: {
    threshold: 3,
    emoji: '⭐',
    label: 'Outstanding',
    color: COLORS.success,
    gradient: GRADIENTS.success,
    glow: '0 0 10px rgba(76, 175, 80, 0.2)',
    rewards: '+3 Bonus Points'
  },
  great: {
    threshold: 2,
    emoji: '✨',
    label: 'Great Work',
    color: COLORS.primary,
    gradient: GRADIENTS.primary,
    glow: '0 0 8px rgba(76, 110, 162, 0.2)',
    rewards: '+2 Bonus Points'
  },
  complete: {
    threshold: 1,
    emoji: '✓',
    label: 'Complete',
    color: COLORS.secondary,
    gradient: `linear-gradient(45deg, ${COLORS.secondary}, #A9BEDA)`,
    glow: '0 0 5px rgba(92, 131, 191, 0.2)',
    rewards: '+1 Point'
  }
};

const MILESTONES = {
  champion: {
    threshold: 15,
    icon: '🏆',
    title: 'Weekly Champion',
    message: 'Incredible dedication!',
    gradient: GRADIENTS.dark
  },
  star: {
    threshold: 10,
    icon: '🌟',
    title: 'Star Achiever',
    message: 'Outstanding progress!',
    gradient: GRADIENTS.primary
  },
  fire: {
    threshold: 7,
    icon: '🔥',
    title: 'On Fire',
    message: 'You\'re unstoppable!',
    gradient: `linear-gradient(135deg, ${COLORS.secondary}, #6B8BB8)`
  },
  power: {
    threshold: 5,
    icon: '⚡',
    title: 'Power User',
    message: 'Building momentum!',
    gradient: `linear-gradient(135deg, #6B8BB8, #7FA1D4)`
  },
  target: {
    threshold: 3,
    icon: '🎯',
    title: 'On Target',
    message: 'Right on track!',
    gradient: `linear-gradient(135deg, #8BA4C6, #A9BEDA)`
  },
  starting: {
    threshold: 0,
    icon: '🌱',
    title: 'Getting Started',
    message: 'Keep going!',
    gradient: `linear-gradient(135deg, #A9BEDA, #C7D4E6)`
  }
};
const HomeCarousel = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const { showBoundary } = useErrorBoundary();
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [achievementAnimation, setAchievementAnimation] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [sessionsDone, setSessionsDone] = useState(0);

  
    const [sessionData, setSessionData] = useState({
      dailySessions: {},
      weeklyStreak: 0,
      totalSessions: 0,
      averageSessionsPerWeek: 0,
      bestStreak: 0,
      maxSessionsInDay: 0,
      activeDaysThisWeek: 0,
      thisWeekSessions: [],
      prestige: PRESTIGE_LEVELS.BEGINNER
    });
  
    const [podcastLinks, setPodcastLinks] = useState([]);
    const [podcastTitles, setPodcastTitles] = useState([]);
    const [blogID, setBlogID] = useState(null);
  
    const getPrestigeLevel = useCallback((totalSessions) => {
      return Object.values(PRESTIGE_LEVELS)
        .reverse()
        .find(level => totalSessions >= level.min) || PRESTIGE_LEVELS.BEGINNER;
    }, []);
  
    const getDailyAchievement = useCallback((count) => {
      if (count >= ACHIEVEMENTS.extraordinary.threshold) return ACHIEVEMENTS.extraordinary;
      if (count >= ACHIEVEMENTS.exceptional.threshold) return ACHIEVEMENTS.exceptional;
      if (count === ACHIEVEMENTS.outstanding.threshold) return ACHIEVEMENTS.outstanding;
      if (count === ACHIEVEMENTS.great.threshold) return ACHIEVEMENTS.great;
      return ACHIEVEMENTS.complete;
    }, []);
  
    const getWeeklyMilestone = useCallback((sessions) => {
      if (sessions >= MILESTONES.champion.threshold) return MILESTONES.champion;
      if (sessions >= MILESTONES.star.threshold) return MILESTONES.star;
      if (sessions >= MILESTONES.fire.threshold) return MILESTONES.fire;
      if (sessions >= MILESTONES.power.threshold) return MILESTONES.power;
      if (sessions >= MILESTONES.target.threshold) return MILESTONES.target;
      return MILESTONES.starting;
    }, []);
  
    const calculateStreak = useCallback((sessionCounts) => {
      let currentStreak = 0;
      let bestStreak = 0;
      let totalSessions = 0;
      let weeksWithSessions = 0;
  
      const weeks = Object.entries(sessionCounts)
        .filter(([key]) => key !== 'total_session' && key !== 'this_week')
        .sort(([weekA], [weekB]) => {
          const dateA = weekA.split('_')[0];
          const dateB = weekB.split('_')[0];
          return new Date(dateB) - new Date(dateA);
        });
  
      for (const [_, weekData] of weeks) {
        if (weekData.count > 0) {
          currentStreak++;
          totalSessions += weekData.count;
          weeksWithSessions++;
        } else break;
      }
  
      if (sessionCounts.this_week?.count) {
        totalSessions += sessionCounts.this_week.count;
        weeksWithSessions++;
      }
  
      let tempStreak = 0;
      for (const [_, weekData] of weeks) {
        if (weekData.count > 0) {
          tempStreak++;
          bestStreak = Math.max(bestStreak, tempStreak);
        } else tempStreak = 0;
      }
  
      return {
        currentStreak,
        bestStreak,
        totalSessions,
        averageSessionsPerWeek: weeksWithSessions > 0 
          ? (totalSessions / weeksWithSessions).toFixed(1) 
          : 0
      };
    }, []);
  
    const processSessionsData = useCallback((sessions) => {
      const dailySessions = {};
      let maxSessionsInDay = 0;
  
      sessions.forEach(session => {
        const date = session.split('T')[0];
        if (!dailySessions[date]) {
          dailySessions[date] = { count: 1, moodRep: null };
        } else {
          dailySessions[date].count++;
        }
        maxSessionsInDay = Math.max(maxSessionsInDay, dailySessions[date].count);
      });
  
      return { dailySessions, maxSessionsInDay };
    }, []);


    useEffect(() => {
      const fetchWeeklyChallenge = async () => {
          try {
              const idToken = await user.getIdToken();
              const currentDate = new Date();
              const currentWeekStart = new Date(
                  currentDate.setUTCDate(currentDate.getUTCDate() - currentDate.getUTCDay())
              ).toISOString().split('T')[0];

              const userDocRef = doc(db, 'users', user.uid);
              const userDocSnap = await getDoc(userDocRef);
              if (!userDocSnap.exists()) {
                  // User document doesn't exist, create it
                  fetchDataFromBackend(idToken,currentWeekStart)
              }

              const sessionCount = userDocSnap.data()?.session_count || {};
              const thisWeek = sessionCount.this_week || {};

              if (!thisWeek.week_start_challenge || thisWeek.week_start_challenge !== currentWeekStart) {
                  // It's a new week, fetch data from the backend
                  // console.log(thisWeek.week_start_challenge, currentWeekStart, currentDate.getUTCDate(), currentDate.getUTCDay())
                  fetchDataFromBackend(idToken, currentWeekStart);
              } else {
                  // It's the same week, read data from Firestore
                  setPodcastLinks(thisWeek.podcast_links);
                  setPodcastTitles(thisWeek.podcast_titles);
                  setSessionsDone(thisWeek.count);
                  setBlogID(thisWeek.blog_id);
                  setLoading(false)
              }

          } catch (error) {
              console.error('Error updating weekly:', error);
              showBoundary(error)
          }
      };

      const fetchDataFromBackend = async (idToken, currentWeekStart) => {
          try {
              const response = await axios.get(`${URL}/update_weekly_challenge`, {
                  headers: { 'Authorization': `Bearer ${idToken}` },
              });
              const data = response.data;
              setPodcastLinks(data.podcast_links);
              setPodcastTitles(data.podcast_titles);
              setSessionsDone(data.sessions_done);
              setBlogID(data.blog_id);
              setLoading(false)
          } catch (error) {
              console.error('Error fetching data from backend:', error);
              showBoundary(error)
          }
      };

      fetchWeeklyChallenge();
  }, [user]);
  
// Update the main useEffect to use this function
useEffect(() => {
  const fetchData = async () => {
    if (!user) return;
    
    try {
      setLoading(true);
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      
      // Fetch calendar data
      const idToken = await user.getIdToken();
      const calendarData = await fetchCalendarData(idToken);
      
      // Initialize default session data if user document doesn't exist
      if (!userDocSnap.exists()) {
        const defaultSessionData = {
          dailySessions: {},
          weeklyStreak: 0,
          totalSessions: 0,
          averageSessionsPerWeek: 0,
          bestStreak: 0,
          maxSessionsInDay: 0,
          activeDaysThisWeek: 0,
          thisWeekSessions: [],
          prestige: PRESTIGE_LEVELS.BEGINNER
        };
        
        setSessionData(defaultSessionData);
        setPodcastLinks([]);
        setPodcastTitles([]);
        setBlogID(null);
        setLoading(false);
        return;
      }

      const userData = userDocSnap.data();
      const sessionCount = userData.session_count || {};
      const thisWeek = sessionCount.this_week || {};
      const streakData = calculateStreak(sessionCount);
      
      const { dailySessions, maxSessionsInDay } = processSessionsData(
        thisWeek.sessions || []
      );

      // Process calendar data if it exists
      if (calendarData.length > 0) {
        const currentDate = new Date();
        const weekStart = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
        weekStart.setHours(0,0,0,0);

        const thisWeekDocs = calendarData.filter(doc => {
          const docDate = new Date(doc.date);
          return docDate >= weekStart;
        });

        for (const doc of thisWeekDocs) {
          const date = new Date(doc.date).toISOString().split('T')[0];
          if (dailySessions[date] && doc.moodrep) {
            try {
              const moodrepRef = ref(storage, doc.moodrep);
              const moodrepUrl = await getDownloadURL(moodrepRef);
              dailySessions[date].moodRep = moodrepUrl;
            } catch (error) {
              console.warn(`Failed to get download URL for ${doc.moodrep}:`, error);
              // Continue without the mood rep image
              dailySessions[date].moodRep = null;
            }
          }
        }
      }

      const totalSessions = sessionCount.total || 0;
      const prestige = getPrestigeLevel(totalSessions);

      setSessionData({
        dailySessions,
        weeklyStreak: streakData.currentStreak,
        totalSessions: totalSessions,
        averageSessionsPerWeek: streakData.averageSessionsPerWeek,
        bestStreak: streakData.bestStreak,
        maxSessionsInDay,
        activeDaysThisWeek: Object.keys(dailySessions).length,
        thisWeekSessions: thisWeek.sessions || [],
        prestige
      });

      setPodcastLinks(thisWeek.podcast_links || []);
      setPodcastTitles(thisWeek.podcast_titles || []);
      setBlogID(thisWeek.blog_id);
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Initialize with default data even if there's an error
      const defaultSessionData = {
        dailySessions: {},
        weeklyStreak: 0,
        totalSessions: 0,
        averageSessionsPerWeek: 0,
        bestStreak: 0,
        maxSessionsInDay: 0,
        activeDaysThisWeek: 0,
        thisWeekSessions: [],
        prestige: PRESTIGE_LEVELS.BEGINNER
      };
      
      setSessionData(defaultSessionData);
      setPodcastLinks([]);
      setPodcastTitles([]);
      setBlogID(null);
      
      showBoundary(error);
      setLoading(false);
    }
  };

  fetchData();
}, [user, showBoundary, calculateStreak, processSessionsData, getPrestigeLevel]);

const fetchCalendarData = async (idToken) => {
  try {
    // Make sure to use the correct API endpoint
    const response = await fetch(`${URL}/user_calendar`, {  // Updated endpoint
      headers: { 
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    });
    
    if (!response.ok) {
      console.warn(`Calendar API returned status: ${response.status}`);
      if (response.status === 404) {
        console.warn('Calendar endpoint not found. Please check API URL configuration.');
        return [];
      }
      throw new Error(`Calendar API error: ${response.statusText}`);
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.warn('Could not fetch calendar data:', error);
    return [];
  }
};

    useEffect(() => {
        if (sessionData.thisWeekSessions.length >= TOTAL_WEEKLY_TARGET && !achievementAnimation) {
          setAchievementAnimation(true);
          setTimeout(() => setAchievementAnimation(false), 3000);
        }
      }, [sessionData.thisWeekSessions.length, achievementAnimation]);
    
      const WeeklyMilestoneCard = () => {
        const milestone = getWeeklyMilestone(sessionData.thisWeekSessions.length);
        return (
            <Card 
              elevation={3}
              className="milestone-card"
              sx={{ 
                background: milestone.gradient,
                color: 'white',
                mb: 2,
                position: 'relative',
                overflow: 'hidden',
                border: '1px solid rgba(255,255,255,0.2)',
                backdropFilter: 'blur(1px)',
                boxShadow: sessionData.thisWeekSessions.length >= TOTAL_WEEKLY_TARGET ? 
                  '0 0 20px rgba(255, 215, 0, 0.2)' : 'none'
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography variant="h2" sx={{ 
                    fontSize: '2.5rem',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                  }}>
                    {milestone.icon}
                  </Typography>
                  <Box>
                    <Typography variant="h6" fontWeight="bold">
                      {milestone.title}
                    </Typography>
                    <Typography variant="body2">
                      {milestone.message}
                    </Typography>
                  </Box>
                </Box>
                <Box 
                  sx={{ 
                    mt: 2,
                    p: 1,
                    borderRadius: 1,
                    background: 'rgba(255,255,255,0.1)',
                    backdropFilter: 'blur(2px)',
                    border: '1px solid rgba(255,255,255,0.2)',
                  }}
                >
                  <Typography variant="body2" textAlign="center">
                    {sessionData.thisWeekSessions.length} sessions this week
                    {sessionData.activeDaysThisWeek >= TOTAL_WEEKLY_TARGET && (
                      <span style={{ marginLeft: '8px' }}>🎉</span>
                    )}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
        );
      };const SessionCard = () => (
        <Card variant="outlined" className="home-card">
          <CardContent>
            <Box display="flex" flexDirection="column" gap={2}>
              {/* Header with Avatar and Streak */}
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <Tooltip title={`${sessionData.totalSessions} Total Sessions - ${sessionData.prestige.label}`}>
                        <Typography 
                          component="span" 
                          sx={{ 
                            fontSize: '1.2rem',
                            filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                          }}
                        >
                          {sessionData.prestige.icon}
                        </Typography>
                      </Tooltip>
                    }
                  >
                    <Avatar alt="Sessions" src={sessionImg} />
                  </Badge>
                  <Box>
                    <Typography variant="sb24Bold" color={COLORS.primary}>
                      Sessions Progress
                    </Typography>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        background: sessionData.prestige.gradient,
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold',
                      }}
                    >
                      {sessionData.prestige.label} Level
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" gap={1} alignItems="center">
                  <Tooltip title="View full collection">
                    <Button
                      onClick={() => navigate('/my-collection')}
                      sx={{
                        minWidth: 'auto',
                        p: 1,
                        borderRadius: '50%',
                        bgcolor: COLORS.lightBlue,
                        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                        '&:hover': { 
                          bgcolor: COLORS.primary, 
                          '& svg': { color: COLORS.lightBlue },
                          transform: 'translateY(-2px)'
                        }
                      }}
                    >
                      <CollectionsIcon sx={{ color: COLORS.primary }} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Current weekly streak">
                    <Chip
                      icon={<LocalFireDepartmentIcon />}
                      label={`${sessionData.weeklyStreak} Week Streak`}
                      sx={{ 
                        bgcolor: COLORS.lightBlue, 
                        color: COLORS.primary,
                        border: `1px solid ${COLORS.primary}30`,
                        '& .MuiSvgIcon-root': {
                          color: sessionData.weeklyStreak >= 4 ? COLORS.darkBlue : COLORS.red,
                        }
                      }}
                    />
                  </Tooltip>
                  {sessionData.bestStreak > 0 && (
                    <Tooltip title="Best streak">
                      <Chip
                        icon={<MilitaryTechIcon />}
                        label={`Best: ${sessionData.bestStreak}w`}
                        sx={{ 
                          background: GRADIENTS.success,
                          color: 'white',
                          border: `1px solid ${COLORS.success}30`,
                          '& .MuiSvgIcon-root': {
                            color: 'white',
                          }
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              </Box>
    
              <WeeklyMilestoneCard />
    
              {/* Stats Display */}
              <Box 
                display="flex" 
                justifyContent="space-around" 
                mb={2}
                sx={{
                  background: `${COLORS.primary}05`,
                  borderRadius: '16px',
                  p: 2,
                  border: `1px solid ${COLORS.primary}10`,
                  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                  '&:hover': {
                    background: `${COLORS.primary}08`,
                    transform: 'translateY(-2px)'
                  }
                }}
              >
                <Tooltip title="Sessions this week">
                  <Box 
                    textAlign="center"
                    sx={{
                      position: 'relative',

                    }}
                  >
                    <Typography 
                      variant="h5" 
                      sx={{
                        color: sessionData.thisWeekSessions.length >= TOTAL_WEEKLY_TARGET ? 
                          COLORS.darkBlue : COLORS.primary,
                        textShadow: sessionData.thisWeekSessions.length >= TOTAL_WEEKLY_TARGET ? 
                          `0 0 8px ${COLORS.darkBlue}30` : 'none',
                        fontWeight: 'bold'
                      }}
                    >
                      {sessionData.thisWeekSessions.length}
                    </Typography>
                    <Typography variant="body2" color={COLORS.primary}>
                      This Week
                    </Typography>
                  </Box>
                </Tooltip>
    
                <Tooltip title="Active days this week">
                  <Box 
                    textAlign="center"
                    sx={{

                    }}
                  >
                    <Typography 
                      variant="h5" 
                      sx={{
                        color: sessionData.activeDaysThisWeek >= TOTAL_WEEKLY_TARGET ? 
                          COLORS.darkBlue : COLORS.primary,
                        textShadow: sessionData.activeDaysThisWeek >= TOTAL_WEEKLY_TARGET ? 
                          `0 0 8px ${COLORS.darkBlue}30` : 'none',
                        fontWeight: 'bold'
                      }}
                    >
                      {sessionData.activeDaysThisWeek}
                    </Typography>
                    <Typography variant="body2" color={COLORS.primary}>
                      Active Days
                    </Typography>
                  </Box>
                </Tooltip>
    
                <Tooltip title={`Record: ${sessionData.maxSessionsInDay} sessions in a day`}>
                  <Box 
                    textAlign="center"
                    sx={{

                    }}
                  >
                    <Typography 
                      variant="h5" 
                      sx={{
                        color: sessionData.maxSessionsInDay >= 3 ? COLORS.darkBlue : COLORS.primary,
                        textShadow: sessionData.maxSessionsInDay >= 3 ? 
                          `0 0 8px ${COLORS.darkBlue}30` : 'none',
                        fontWeight: 'bold'
                      }}
                    >
                      {sessionData.maxSessionsInDay}
                    </Typography>
                    <Typography variant="body2" color={COLORS.primary}>
                      Best Day
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>{/* Progress Bar */}
          <Box mb={2}>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="body1Medium" color={COLORS.primary}>
                Active Days Progress
              </Typography>
              <Typography variant="body1Medium" color={COLORS.primary}>
                {sessionData.activeDaysThisWeek}/{TOTAL_WEEKLY_TARGET}
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={(sessionData.activeDaysThisWeek / TOTAL_WEEKLY_TARGET) * 100}
              sx={{
                height: 10,
                borderRadius: 5,
                bgcolor: COLORS.lightBlue,
                '& .MuiLinearProgress-bar': {
                  borderRadius: 5,
                  background: sessionData.activeDaysThisWeek >= TOTAL_WEEKLY_TARGET
                    ? GRADIENTS.dark
                    : GRADIENTS.primary,
                  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                  animation: sessionData.activeDaysThisWeek >= TOTAL_WEEKLY_TARGET
                    ? 'glow 2s ease-in-out infinite'
                    : 'none'
                }
              }}
            />
          </Box>

          {/* Achievement Grid */}
          <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
            {Object.entries(sessionData.dailySessions).map(([date, data], index) => {
              const achievement = getDailyAchievement(data.count);
              return (
                <Box
                  key={date}
                  className="achievement-card"
                  sx={{
                    position: 'relative',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    height: '150px',
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    border: `1px solid ${achievement.color}30`,
                    '&:hover': {
                      transform: 'scale(1.02) translateY(-2px)',
                      boxShadow: achievement.glow,
                      border: `1px solid ${achievement.color}60`
                    }
                  }}
                >
                  {data.moodRep ? (
                    <img
                      src={data.moodRep}
                      alt={`Session on ${date}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: achievement.gradient
                      }}
                    >
                      <Typography variant="body2" color="white">
                        {achievement.label}
                      </Typography>
                    </Box>
                  )}
                  
                  <Tooltip 
                    title={`${achievement.label} - ${achievement.rewards}`}
                    placement="top"
                  >
                    <Box
                      className="achievement-badge"
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        borderRadius: '12px',
                        padding: '6px 10px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        boxShadow: achievement.glow,
                        background: achievement.gradient,
                        transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                        border: '1px solid rgba(255,255,255,0.2)',
                        backdropFilter: 'blur(4px)',
                        '&:hover': {
                          transform: 'scale(1.1)',
                        }
                      }}
                    >
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          fontWeight: 'bold',
                          color: 'white',
                          textShadow: '0 1px 2px rgba(0,0,0,0.2)'
                        }}
                      >
                        {achievement.emoji} x{data.count}
                      </Typography>
                    </Box>
                  </Tooltip>

                  <Box
                    sx={{
                      position: 'absolute',
                      top: 8,
                      left: 8,
                      color: 'white',
                      textShadow: '0 1px 3px rgba(0,0,0,0.3)',
                      padding: '4px 8px',
                      borderRadius: '8px',
                      background: 'rgba(0,0,0,0.4)',
                      backdropFilter: 'blur(4px)'
                    }}
                  >
                    <Typography variant="caption">
                      {new Date(date).toLocaleDateString('en-US', { 
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric'
                      })}
                    </Typography>
                  </Box>
                </Box>
              );
            })}{/* Empty Slots */}
            {Array.from({ length: TOTAL_WEEKLY_TARGET - Object.keys(sessionData.dailySessions).length }).map((_, i) => (
              <Box
                key={`empty-${i}`}
                sx={{
                  height: '150px',
                  border: `2px dashed ${COLORS.secondary}`,
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: COLORS.lightBlue,
                  opacity: 0.7,
                  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                  '&:hover': {
                    opacity: 0.9,
                    transform: 'translateY(-2px)'
                  }
                }}
              >
                <Box textAlign="center">
                  <Pending />
                  <Typography variant="body2" color={COLORS.primary} mt={1}>
                    Day {Object.keys(sessionData.dailySessions).length + i + 1}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>

          {/* Action Button */}
          <Box
  sx={{
    mt: 4,
    mb: 2,
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }}
>
  <Button
    variant="contained"
    onClick={() => navigate('/session')}
    sx={{
      width: { xs: '100%', sm: '80%', md: '70%' },
      py: { xs: 1.5, sm: 2 },
      px: { xs: 3, sm: 4 },
      bgcolor: '#5C83BF',
      '&:hover': { 
        bgcolor: '#4C6481', // Slightly darker shade for hover
        transform: 'translateY(-2px)',
        boxShadow: '0 8px 16px rgba(92, 131, 191, 0.25)'
      },
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '200px',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
      boxShadow: '0 4px 12px rgba(92, 131, 191, 0.2)',
      border: '1px solid rgba(255,255,255,0.1)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(180deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%)',
        opacity: 0,
        transition: 'opacity 0.3s ease',
      },
      '&:active': {
        transform: 'translateY(1px)',
        boxShadow: '0 2px 8px rgba(92, 131, 191, 0.15)',
        bgcolor: '#5C83BF',
      },
      '&:hover::after': {
        opacity: 1
      }
    }}
  >
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 1,
      position: 'relative',
      zIndex: 1
    }}>
      <WhatshotIcon sx={{ 
        color: 'white', 
        fontSize: { xs: 20, sm: 24 } 
      }} />
      <Typography 
        variant="sb16Bolder" 
        sx={{ 
          color: 'white',
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
          textTransform: 'none',
          letterSpacing: '0.5px',
          fontWeight: 600
        }}
      >
        {sessionData.activeDaysThisWeek >= TOTAL_WEEKLY_TARGET 
          ? 'Start Bonus Training! 🎉' 
          : 'Start Training'}
      </Typography>
    </Box>
  </Button>
</Box>
        <style>
          {`
            .MuiButton-contained {
              background-color: #5C83BF !important;
            }
            .MuiButton-contained:hover {
              background-color: #4C6481 !important;
            }
            @keyframes shine {
              0% { transform: translateX(-100%); }
              100% { transform: translateX(100%); }
            }
            @keyframes pulse {
              0% { transform: scale(1); opacity: 0.8; }
              50% { transform: scale(1.05); opacity: 1; }
              100% { transform: scale(1); opacity: 0.8; }
            }
            @keyframes float {
              0% { transform: translateY(0px); }
              50% { transform: translateY(-3px); }
              100% { transform: translateY(0px); }
            }
            @keyframes breathe {
              0% { transform: scale(1); opacity: 1; }
              50% { transform: scale(1.02); opacity: 0.9; }
              100% { transform: scale(1); opacity: 1; }
            }
            @keyframes glow {
              0% { box-shadow: 0 0 5px rgba(255, 215, 0, 0.2); }
              50% { box-shadow: 0 0 15px rgba(255, 215, 0, 0.4); }
              100% { box-shadow: 0 0 5px rgba(255, 215, 0, 0.2); }
            }
            .achievement-card {
              transition: all 0.3s ease-in-out;
            }
            .achievement-badge {
            }
            .milestone-card {
            }
            .success-glow {
              animation: glow 2s ease-in-out infinite;
            }
          `}
        </style>
      </Box>
    </CardContent>
  </Card>
);

const PodcastCard = () => {
  // Add debug logging
  useEffect(() => {
    console.log('Podcast Titles:', podcastTitles);
    console.log('Podcast Links:', podcastLinks);
  }, [podcastTitles, podcastLinks]);

  const hasPodcasts = podcastTitles.length > 0 && podcastLinks.length > 0;

  return (
    <Card variant="outlined" className="home-card">
      <CardContent>
        <Box display="flex" alignItems="center" gap={1} mb={4}>
          <Avatar alt="Podcasts" src={micImg} />
          <Typography variant="sb24Bold" color={COLORS.primary}>
            Weekly Podcasts
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={2} minHeight="200px">
          {hasPodcasts ? (
            // Show podcasts when available
            podcastTitles.slice(0, 2).map((title, index) => (
              <Box
                key={index}
                onClick={() => {
                  if (podcastLinks[index]) {
                    window.open(podcastLinks[index], '_blank');
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { 
                    bgcolor: COLORS.lightBlue,
                    transform: 'translateY(-2px)',
                    transition: 'transform 0.2s ease-in-out',
                    boxShadow: `0 4px 12px ${COLORS.primary}10`
                  },
                  borderRadius: '10px',
                  transition: 'all 0.3s ease',
                  border: `1px solid ${COLORS.primary}10`
                }}
                p={2}
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <img
                    src={podcastImg}
                    alt="Podcast"
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'cover',
                      borderRadius: '8px'
                    }}
                  />
                  <Typography variant="body1Medium" color={COLORS.primary}>
                    {title}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            // Show placeholder when no podcasts
            <Box 
              display="flex" 
              flexDirection="column" 
              alignItems="center" 
              justifyContent="center"
              height="100%"
              gap={2}
              sx={{
                bgcolor: COLORS.lightBlue,
                borderRadius: '10px',
                p: 3,
                border: `1px dashed ${COLORS.primary}40`
              }}
            >
              <Typography variant="body1" color={COLORS.primary} textAlign="center">
                New podcasts coming soon!
              </Typography>
              <Typography variant="caption" color={COLORS.primary} textAlign="center">
                Check back later for fresh content
              </Typography>
            </Box>
          )}
        </Box>

        <Button
          variant="outlined"
          fullWidth
          onClick={() => window.location.href = 'https://rss.com/podcasts/pokapod/'}
          sx={{
            mt: 3,
            borderColor: COLORS.primary,
            color: COLORS.primary,
            '&:hover': {
              borderColor: COLORS.secondary,
              bgcolor: COLORS.lightBlue,
              transform: 'translateY(-2px)',
              transition: 'all 0.2s ease-in-out',
              boxShadow: `0 4px 12px ${COLORS.primary}10`
            }
          }}
        >
          <Typography variant="sb16Bolder">
            View All Podcasts
          </Typography>
        </Button>
      </CardContent>
    </Card>
  );
};

if (loading) {
  return (
    <Box 
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.default',
        zIndex: 1000
      }}
    >
      <LoadingSpinner text="Fetching Weekly Milestones" />
    </Box>
  );
}

return (
  <Grid 
    container 
    justifyContent="center" 
    spacing={isMobile ? 2 : 4} 
    sx={{
      minHeight: '100vh',
      width: '100%',
      m: 0,
      p: 3,
      boxSizing: 'border-box',
      overflow: 'auto'
    }}
  >
    <Grid 
      item 
      xs={12} 
      sm={8} 
      md={5.5} 
      lg={7.4} 
      xl={6.4}
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <SessionCard />
    </Grid>
    <Grid 
      item 
      xs={12} 
      sm={8} 
      md={5.5} 
      lg={3.7} 
      xl={3.2}
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <PodcastCard />
    </Grid>

    <style>
      {`
        .home-card {
          height: 100%;
          transition: all 0.3s ease-in-out;
          border: 1px solid ${COLORS.primary}10;
        }
        .home-card:hover {
          box-shadow: 0 8px 24px ${COLORS.primary}10;
        }
      `}
    </style>
  </Grid>
);
};

export default HomeCarousel;