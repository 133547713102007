import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BlogData, intros } from '../data/BlogData';
import {
  Typography,
  Container,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Tabs,
  Tab
} from '@mui/material';
import {
  AccessTime,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';

const Blogs = () => {
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [expandedBlog, setExpandedBlog] = useState(null);
  const navigate = useNavigate();

  const topics = [
    "All Articles",
    "Journaling The Journey Within",
    "From A Powerful Adversary to A Valuable Ally - How to Transform Anxiety",
    "Own Your Rhythm: A Guide To Workplace Well-Being For Young Professionals"
  ];

  const getFilteredBlogs = () => {
    if (selectedTopic === 0) return BlogData;
    return BlogData.filter(blog => blog.tags.includes(topics[selectedTopic]));
  };

  const BlogItem = ({ blog }) => {
    const isExpanded = expandedBlog === blog.id;
    const [readingTime] = useState(Math.floor(Math.random() * 5) + 5);

    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            py: 2,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.02)'
            }
          }}
          onClick={() => setExpandedBlog(isExpanded ? null : blog.id)}
        >
          <Box 
            component="img"
            src={blog.imageUrl}
            alt={blog.title}
            sx={{
              width: 120,
              height: 80,
              objectFit: 'cover',
              borderRadius: 1,
              flexShrink: 0
            }}
          />
          
          <Box sx={{ flex: 1, minWidth: 0 }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 0.5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                <AccessTime sx={{ fontSize: '0.875rem', mr: 0.5 }} />
                <Typography variant="caption">{readingTime} min read</Typography>
              </Box>
              <Chip
                label={blog.tags[0]}
                size="small"
                sx={{
                  height: 20,
                  fontSize: '0.75rem',
                  backgroundColor: 'primary.main',
                  color: 'white'
                }}
              />
            </Box>

            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
              {blog.title}
            </Typography>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                fontSize: '0.875rem',
                lineHeight: 1.5
              }}
            >
              {blog.shortIntro}
            </Typography>
          </Box>
          
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </Box>

        <Collapse in={isExpanded}>
          <Divider />
          <Box sx={{ py: 2 }}>
            {blog.content}
          </Box>
          <Divider />
        </Collapse>
      </Box>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Tabs
        value={selectedTopic}
        onChange={(e, value) => setSelectedTopic(value)}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          mb: 3,
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTab-root': {
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '0.875rem',
            minHeight: 48,
            px: 2,
            color: 'text.secondary',
            '&.Mui-selected': {
              color: 'primary.main'
            }
          }
        }}
      >
        {topics.map((topic, index) => (
          <Tab key={index} label={topic} disableRipple />
        ))}
      </Tabs>

      <Box>
        {getFilteredBlogs().map((blog) => (
          <React.Fragment key={blog.id}>
            <BlogItem blog={blog} />
            {blog.id !== BlogData.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
    </Container>
  );
};

export default Blogs;