import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Webcam from 'react-webcam';

export const AudioVisualizer = ({ micCanvasRef, canvasParentRef, webcamVisible, showAvatar, videoAccess, camRef, onUserMedia, onUserMediaError }) => {
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  
  const baseColors = {
    primary: '#4C6EA2',
    secondary: '#5C83BF',
    accent: '#91A4BC',
    gold: '#D4AF37',
    lightGold: '#F4D03F',
    deepBlue: '#34495E',
  };

  useEffect(() => {
    if (!micCanvasRef.current) return;

    const canvas = micCanvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // Ensure canvas is properly sized
    const setCanvasSize = () => {
      canvas.width = 400;  // Fixed size for testing
      canvas.height = 400;
      console.log('Canvas size set:', canvas.width, canvas.height);
    };
    
    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    let animationFrame;
    let time = 0;
    
    // Create multiple layers of circles
    const createCircleLayer = (radius, color, thickness) => {
      ctx.beginPath();
      ctx.arc(canvas.width / 2, canvas.height / 2, radius, 0, Math.PI * 2);
      ctx.strokeStyle = color;
      ctx.lineWidth = thickness;
      ctx.stroke();
    };

    // Create liquid effect points
    const numPoints = 100;
    const points = Array(numPoints).fill().map((_, i) => ({
      angle: (i / numPoints) * Math.PI * 2,
      radius: 0,
      velocity: 0.5 + Math.random() * 0.5,
      offset: Math.random() * Math.PI * 2
    }));

    const drawVisualization = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const baseRadius = 90; // Match the inner circle size
      
      time += 0.02;

      // Draw outer rings (static part)
      createCircleLayer(baseRadius + 20, 'rgba(145, 164, 188, 0.3)', 2);
      createCircleLayer(baseRadius + 35, 'rgba(145, 164, 188, 0.2)', 1.5);

      // Draw liquid effect
      ctx.beginPath();
      points.forEach((point, i) => {
        // Calculate wave effect
        const wave = Math.sin(time * point.velocity + point.offset) * 8;
        const radius = baseRadius + wave;
        
        const x = centerX + Math.cos(point.angle) * radius;
        const y = centerY + Math.sin(point.angle) * radius;
        
        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          const prevPoint = points[i - 1];
          const prevWave = Math.sin(time * prevPoint.velocity + prevPoint.offset) * 8;
          const prevRadius = baseRadius + prevWave;
          const prevX = centerX + Math.cos(prevPoint.angle) * prevRadius;
          const prevY = centerY + Math.sin(prevPoint.angle) * prevRadius;
          
          // Use quadratic curves for smoother effect
          const cpX = centerX + Math.cos((point.angle + prevPoint.angle) / 2) * (radius + prevRadius) / 2;
          const cpY = centerY + Math.sin((point.angle + prevPoint.angle) / 2) * (radius + prevRadius) / 2;
          
          ctx.quadraticCurveTo(cpX, cpY, x, y);
        }
      });
      
      ctx.closePath();

      // Create gradient for liquid effect
      const gradient = ctx.createRadialGradient(
        centerX, centerY, baseRadius - 10,
        centerX, centerY, baseRadius + 20
      );
      
      gradient.addColorStop(0, baseColors.primary);
      gradient.addColorStop(0.7, baseColors.secondary);
      gradient.addColorStop(1, baseColors.gold);

      ctx.fillStyle = gradient;
      ctx.fill();

      // Add shimmering effect
      const numParticles = 20;
      for (let i = 0; i < numParticles; i++) {
        const particleAngle = Math.random() * Math.PI * 2;
        const particleRadius = baseRadius + Math.random() * 30;
        const particleSize = Math.random() * 2;
        
        ctx.beginPath();
        ctx.arc(
          centerX + Math.cos(particleAngle) * particleRadius,
          centerY + Math.sin(particleAngle) * particleRadius,
          particleSize,
          0,
          Math.PI * 2
        );
        
        const particleOpacity = Math.random() * 0.5;
        ctx.fillStyle = `rgba(212, 175, 55, ${particleOpacity})`; // Gold particles
        ctx.fill();
      }

      animationFrame = requestAnimationFrame(drawVisualization);
    };

    // Start animation
    console.log('Starting visualization');
    drawVisualization();

    return () => {
      cancelAnimationFrame(animationFrame);
      window.removeEventListener('resize', setCanvasSize);
    };
  }, []);

  return (
    <Box ref={canvasParentRef} sx={{ position: "relative", height: "300px", width: "100%", maxWidth: "600px", margin: "20px 0" }}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {/* Main circle container */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "180px",
            height: "180px",
            backgroundColor: "transparent", // Changed to transparent to see effects
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1
          }}
        >
          {(!webcamVisible || showAvatar) && (
            <Box sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "transparent", // Changed to transparent to see effects
              borderRadius: "50%"
            }} />
          )}
        </Box>

        {videoAccess && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              opacity: webcamVisible ? 1 : 0,
              transition: "opacity 0.3s ease"
            }}
          >
            <Webcam
              ref={camRef}
              onUserMedia={onUserMedia}
              onUserMediaError={onUserMediaError}
              audio={false}
              screenshotFormat="image/jpeg"
              style={{
                borderRadius: "50%",
                width: "180px",
                height: "180px",
                objectFit: "cover"
              }}
            />
          </Box>
        )}

        {/* Canvas for visualization */}
        <canvas
          ref={micCanvasRef}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 0
          }}
        />
      </Box>
    </Box>
  );
};

export default AudioVisualizer;