import React from 'react';
import { Typography, Box } from '@mui/material';
import { useUser } from '../../contexts/UserContext';

const Plans = () => {
  const { user } = useUser();

  return (
    <div className="prevent_overflow">
      <div className="content">
        <Typography variant="h2" align="center" gutterBottom>
          Pricing Plans
        </Typography>
        <stripe-pricing-table
          pricing-table-id={String(process.env.REACT_APP_StripePricingTableId)}
          publishable-key={String(process.env.REACT_APP_stripeKey)}
          pricing-table-options='{"pricing_table_data":{"pricing_table_mode":"horizontal"}}'
          client-reference-id={user.uid}
          customer-email={user.email}
        >
        </stripe-pricing-table>
      </div>
    </div>
  );
};

export default Plans;