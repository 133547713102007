
/* global chrome, browser */
export const EXTENSION_IDS = {
    // CHROME: 'baoocoojjibemagblldbhfepnmpklclb',
    // CHROME: 'gbcmdcopligmmjlfmaajannckjjdfapp',
    CHROME: 'biioohgafmlchekfaidljmlihodknloi', //production

    FIREFOX: 'poka@pokamind.com'
};


export const sendMessageToExtension = (message) => {
    return new Promise((resolve, reject) => {
        try {
            // console.log("sending auth token to extension", EXTENSION_IDS.CHROME);
            // Chrome
            if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
                chrome.runtime.sendMessage(
                    EXTENSION_IDS.CHROME,
                    message)
                    .then((response) => {
                        if (chrome.runtime.lastError) {
                            console.log('Chrome extension communication error:', chrome.runtime.lastError);
                            resolve(null);
                        } else {
                            resolve(response);
                        }
                    }
                    ).catch(reject);
            }
            // Firefox
            else if (typeof browser !== 'undefined' && browser.runtime && browser.runtime.sendMessage) {
                browser.runtime.sendMessage(
                    EXTENSION_IDS.FIREFOX,
                    message
                ).then(resolve).catch(reject);
            }
            // No extension API available
            else {
                console.log('No extension API available');
                resolve(null);
            }
        } catch (error) {
            console.error('Error sending message to extension:', error);
            reject(error);
        }
    });
};