import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, Popper, Fade, Paper,
  Divider
} from '@mui/material';
import { alpha } from '@mui/system';

const COLORS = {
  primary: '#5C83BF',
  secondary: '#FFF7F1',
  accent: '#FF8B67',
  success: '#4CAF50',
  warning: '#FFA726',
  error: '#EF5350'
};

const MetricDetailsCard = ({ details, onClose }) => (
  <Paper sx={{ 
    p: 2.5, 
    width: 300,
    borderRadius: 2,
    boxShadow: `0 4px 20px ${alpha(COLORS.primary, 0.15)}`,
    border: `1px solid ${alpha(details.color, 0.3)}`
  }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 600, color: COLORS.primary }}>
        {details.title}
      </Typography>
      <Box sx={{ 
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        bgcolor: alpha(details.color, 0.1),
        color: details.color,
        fontWeight: 600,
        fontSize: '0.875rem'
      }}>
        {details.value}%
      </Box>
    </Box>

    <Box sx={{ mb: 2 }}>
      {details.stats.map((stat, index) => (
        <Box key={index} sx={{ mb: 1 }}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {stat.label}
          </Typography>
          <Box sx={{ 
            height: 6,
            bgcolor: alpha(COLORS.primary, 0.1),
            borderRadius: 3,
            mt: 0.5,
            overflow: 'hidden'
          }}>
            <Box sx={{
              height: '100%',
              width: `${stat.value}%`,
              bgcolor: details.color,
              borderRadius: 3,
              transition: 'width 0.3s ease'
            }} />
          </Box>
          <Typography variant="caption" sx={{ color: COLORS.primary, mt: 0.5, display: 'block' }}>
            {stat.description}
          </Typography>
        </Box>
      ))}
    </Box>

    <Divider sx={{ my: 1.5 }} />
    
    {details.explanation.map((text, index) => (
      <Typography 
        key={index}
        variant="body2" 
        sx={{ 
          color: 'text.secondary',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          mb: 0.5,
          '&:before': {
            content: '""',
            width: 4,
            height: 4,
            borderRadius: '50%',
            bgcolor: details.color,
            flexShrink: 0
          }
        }}
      >
        {text}
      </Typography>
    ))}
  </Paper>
);

const LeaderProgressMetrics = ({ leader, metrics }) => {
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMetricClick = (event, type) => {
    if (selectedMetric === type && anchorEl) {
      setSelectedMetric(null);
      setAnchorEl(null);
    } else {
      setSelectedMetric(type);
      setAnchorEl(event.currentTarget);
    }
  };

  const calculateMonthsDiff = (leader) => {
    if (!leader.sessions.length) return 1;
    
    const sessions = [...leader.sessions].sort((a, b) => 
      new Date(b.session_time) - new Date(a.session_time)
    );
    
    const latestDate = new Date(sessions[0].session_time);
    const oldestDate = new Date(sessions[sessions.length - 1].session_time);
    
    const monthDiff = (latestDate.getMonth() - oldestDate.getMonth()) + 
                     (12 * (latestDate.getFullYear() - oldestDate.getFullYear()));
                     
    return monthDiff + 1; // Add 1 to include current month
  };
  


  const getMetricDetails = (type) => {
    switch(type) {
      case 'consistency':
        return {
          title: 'Session Consistency',
          value: Math.round(metrics.consistency),
          color: alpha(COLORS.primary, 0.3),
          stats: [
            {
              label: 'Monthly Session Rate',
              value: Math.min(100, (metrics.totalSessions / (12 * calculateMonthsDiff(leader))) * 100),
              description: `${metrics.totalSessions} sessions completed`
            }
          ],
          explanation: [
            'Target: 12 sessions per month',
            'Based on session frequency',
            'Contributes 30% to overall progress'
          ]
        };
      case 'energy':
        return {
          title: 'Leadership Session Score',
          value: Math.round(metrics.energy),
          color: alpha(COLORS.primary, 0.6),
          stats: [
            {
              label: 'Average Score',
              value: (leader.stats.avg_energy_level / 5) * 100,
              description: `${leader.stats.avg_energy_level.toFixed(1)} out of 5.0`
            }
          ],
          explanation: [
            'Based on session leadership scores',
            'Scale: 1-5 points per session',
            'Contributes 40% to overall progress'
          ]
        };
      case 'improvement':
        return {
          title: 'Improvement Rate',
          value: Math.round(metrics.improvement),
          color: COLORS.primary,
          stats: [
            {
              label: 'Sessions with Improvement',
              value: (metrics.improvedSessions / metrics.totalSessions) * 100,
              description: `${metrics.improvedSessions} of ${metrics.totalSessions} sessions`
            }
          ],
          explanation: [
            'Based on emotional improvements',
            'Tracks positive changes',
            'Contributes 30% to overall progress'
          ]
        };
      default:
        return null;
    }
  };

  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      mt: 2
    }}>
      <Box sx={{
        width: '400px',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        p: 2,
        bgcolor: alpha(COLORS.primary, 0.02),
        borderRadius: 2,
      }}>
        {/* Metric Buttons */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1
        }}>
          {[
            { type: 'consistency', label: 'Consistency', value: metrics.consistency, color: alpha(COLORS.primary, 0.3) },
            { type: 'energy', label: 'Energy', value: metrics.energy, color: alpha(COLORS.primary, 0.6) },
            { type: 'improvement', label: 'Improvement', value: metrics.improvement, color: COLORS.primary }
          ].map((item) => (
            <Button
              key={item.type}
              onClick={(e) => handleMetricClick(e, item.type)}
              sx={{
                minWidth: 'auto',
                px: 2,
                py: 0.5,
                borderRadius: 2,
                bgcolor: selectedMetric === item.type ? alpha(item.color, 0.1) : 'transparent',
                '&:hover': {
                  bgcolor: alpha(item.color, 0.1)
                }
              }}
            >
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box sx={{ 
                  width: 10, 
                  height: 10, 
                  borderRadius: '50%', 
                  bgcolor: item.color,
                  border: `1px solid ${alpha(COLORS.primary, 0.2)}`
                }} />
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: selectedMetric === item.type ? COLORS.primary : 'text.secondary',
                    fontWeight: selectedMetric === item.type ? 600 : 400
                  }}
                >
                  {item.label}
                </Typography>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: COLORS.primary, 
                    fontWeight: 600,
                  }}
                >
                  {Math.round(item.value)}%
                </Typography>
              </Box>
            </Button>
          ))}
        </Box>

        {/* Progress Bar */}
        <Box sx={{ 
          height: 16,
          bgcolor: alpha(COLORS.primary, 0.1),
          borderRadius: 8,
          position: 'relative',
          overflow: 'hidden',
          border: `1px solid ${alpha(COLORS.primary, 0.2)}`,
          display: 'flex'
        }}>
          {[
            { type: 'consistency', width: metrics.consistency, color: alpha(COLORS.primary, 0.3) },
            { type: 'energy', width: metrics.energy, color: alpha(COLORS.primary, 0.6) },
            { type: 'improvement', width: metrics.improvement, color: COLORS.primary }
          ].map((item) => (
            <Button
              key={item.type}
              onClick={(e) => handleMetricClick(e, item.type)}
              sx={{ 
                width: `${item.width}%`,
                height: '100%',
                bgcolor: item.color,
                transition: 'all 0.3s ease',
                border: 'none',
                borderRadius: 0,
                minWidth: 0,
                p: 0,
                opacity: selectedMetric && selectedMetric !== item.type ? 0.5 : 1,
                '&:hover': {
                  bgcolor: alpha(item.color, 0.9),
                  cursor: 'pointer'
                }
              }}
            />
          ))}
        </Box>

        {/* Info Text */}
        <Typography 
          variant="caption" 
          sx={{ 
            color: 'text.secondary',
            textAlign: 'center',
            mt: 0.5
          }}
        >
          Click on any section to see detailed information
        </Typography>
      </Box>

      {/* Details Popper */}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="top"
        transition
        sx={{ zIndex: 1300 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Box>
              {selectedMetric && (
                <MetricDetailsCard
                  details={getMetricDetails(selectedMetric)}
                  onClose={() => {
                    setSelectedMetric(null);
                    setAnchorEl(null);
                  }}
                />
              )}
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default LeaderProgressMetrics;