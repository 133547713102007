import React from 'react';
import { Button } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { URL } from '../Helpers';

const SlackAuthButton = () => {
  const handleSlackAuth = () => {
    localStorage.setItem('slackAuthRedirect', window.location.pathname);
    window.location.href = `${URL}/slack/oauth/start?source=slack_command`;
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleSlackAuth}
      // startIcon={
      //   <svg width="20" height="20" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      //     <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="currentColor"/>
      //     <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="currentColor"/>
      //     <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="currentColor"/>
      //     <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="currentColor"/>
      //   </svg>
      // }
      sx={{
        width: '100%',
        py: 1,
        borderRadius: 2,
        textTransform: 'none',
        borderColor: theme => alpha(theme.palette.primary.main, 0.3),
        color: theme => theme.palette.primary.main,
        '& .MuiButton-startIcon': {
          color: theme => theme.palette.primary.main,
        },
        '&:hover': {
          borderColor: theme => theme.palette.primary.main,
          backgroundColor: theme => alpha(theme.palette.primary.main, 0.05)
        }
      }}
    >
      Connect with Slack
    </Button>
  );
};

export default SlackAuthButton;