import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSession } from '../../../contexts/SessionContext';
import { useUser } from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useErrorBoundary } from "react-error-boundary";
import { startVisualizer, createSessionDir, tierLimits } from './mySessionUtils';
import Recorder from 'recorder-js';
import { v4 as uuid4 } from 'uuid';
import { URL, getDateString } from "../../../Helpers";

export const useMySessionLogic = () => {
  const navigate = useNavigate();
  const { overviewData, setOverviewData, setSessionContextDate } = useSession();
  const [recording, setRecording] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [scenario, setScenario] = useState("");
  const [showQuestions, setShowQuestions] = useState(false);
  const [webcamVisible, setWebcamVisible] = useState(false);
  const [videoAccess, setVideoAccess] = useState(true);
  const [resultError, setresultError] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [WarningMessage, setWarningMessage] = useState(null);
  const [showAvatar, setShowAvatar] = useState(true);
  const [microphone, setMicrophone] = useState(true);
  const [isFirstQuestion, setIsFirstQuestion] = useState(true);
  const [selectedModule, setSelectedModule] = useState(null);

  const { user, tier, role } = useUser();
  const { showBoundary } = useErrorBoundary();

  // Refs
  const audioStreamRef = useRef(null);
  const tempDirRef = useRef(null);
  const imgTimeoutIDRef = useRef(null);
  const audioTimeoutIDRef = useRef(null);
  const audioContextRef = useRef();
  const camRef = useRef(null);
  const audioRef = useRef(null);
  const audioCanvasRef = useRef(null);
  const micCanvasRef = useRef(null);
  const canvasParentRef = useRef(null);
  const countdownIntervalRef = useRef();
  const timeoutRef = useRef();
  const audioRecorderRef = useRef();
  const audioEndingRef = useRef();
  const sessionDate = useRef(getDateString());
  const sessionIDUnique = useRef(uuid4());

  // Get SESSION_TIMER based on tier
  const SESSION_TIMER = useMemo(() => tierLimits[tier]?.SESSION_TIMER || tierLimits.free.SESSION_TIMER, [tier]);

  const handleWarning = useCallback((message) => {
    setShowWarning(true);
    setWarningMessage(message);
    setTimeout(() => {
      setShowWarning(false);
      setWarningMessage(null);
    }, 1500);
  }, []);

  const uploadData = useCallback(async (data, url) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: data

      });

      if (!response.ok) {
        handleWarning("Face not detected.");
        let msg = await response.json();
        throw new Error('Network response was not ok ' + msg.message);
      }

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }, [user.accessToken, handleWarning]);

  const loadVisualizer = useCallback(() => {
    if (!audioRef.current) return;
    setCountdown(SESSION_TIMER);
    startCountdown(SESSION_TIMER);

    // Only play audio and start visualizer for non-admin users
    if (role !== 'admin') {
      startVisualizer(audioCanvasRef.current, audioRef.current);
    }
  }, [SESSION_TIMER, role]);


  const startCountdown = useCallback((duration) => {
    let remainingTime = duration;
    setCountdown(remainingTime);

    setTimeout(() => {
      countdownIntervalRef.current = setInterval(() => {
        remainingTime -= 1;

        // Play ending audio before end
        if (audioEndingRef.current && remainingTime === Math.floor(audioEndingRef.current.duration) + 2) {
          audioEndingRef.current.play().catch(console.error);
          startVisualizer(audioCanvasRef.current, audioEndingRef.current);
        }

        if (remainingTime <= 0) {
          setCountdown(null);
          setQuestion(null);
          clearInterval(countdownIntervalRef.current);
        } else {
          setCountdown(remainingTime);
        }
      }, 1000);
    }, 8000); // Wait 8 seconds after audio plays
  }, []);

  const handleScreenshots = useCallback(() => {
    const sendImg = () => {
      if (!camRef.current) return;

      const img = camRef.current.getScreenshot()?.split(',')[1];
      if (!img) return;

      const imageData = JSON.stringify({
        image: img,
        timestamp: new Date().getTime()
      });

      uploadData(imageData, `${URL}/img/${sessionDate.current}/${tempDirRef.current}`);
      imgTimeoutIDRef.current = setTimeout(sendImg, 2000);
    };

    imgTimeoutIDRef.current = setTimeout(sendImg, 2000);
  }, [uploadData]);

  const startRecordingAudio = useCallback(async (stream) => {
    audioRecorderRef.current = new Recorder(audioContextRef.current, { numChannels: 1 });
    await audioRecorderRef.current.init(stream);
    try {
      await audioRecorderRef.current.start();
      console.log('Audio recording started');
    } catch (err) {
      console.error('Error starting audio recording:', err);
      handleWarning('Error starting audio recording');
    }
  }, [handleWarning]);



  const sendAudio = useCallback(() => {
    console.log('Gathering Audio');
    if (!audioRecorderRef.current) return;

    audioRecorderRef.current.stop().then(({ blob }) => {
      const formData = new FormData();
      formData.append('audio', blob, 'audio.wav');

      fetch(`${URL}/upload_audio/${sessionDate.current}/${tempDirRef.current}?module_id=${selectedModule}`, {
        headers: {
          'Authorization': `Bearer ${user.accessToken}`
        },
        method: 'POST',
        cache: 'no-cache',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            handleWarning("Voice not detected");
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setQuestion(data.message);
        })
        .catch(console.error);

      audioRecorderRef.current.start();
    });

    audioTimeoutIDRef.current = setTimeout(sendAudio, 20000);
  }, [user.accessToken, handleWarning, selectedModule]);



  const cleanup = useCallback(() => {
    setRecording(false);
    setCountdown(null);
    setLoading(false);
    setQuestion('');
    setShowQuestions(false);
    setWebcamVisible(false);
    setMicrophone(false);
    tempDirRef.current = null;

    // Cleanup function for audio stream and recorder
    if (audioRecorderRef.current) {
      audioRecorderRef.current.stop(); // Stop the recorder
      audioRecorderRef.current = null; // Release the recorder
    }

    // Important: Stop all tracks in the media stream
    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => {
        track.stop(); // Stop all the tracks in the received media stream
      });
      audioStreamRef.current = null; // Release the media stream
    }

    // Close the AudioContext
    if (audioContextRef.current?.state === 'running') {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    // Clear all timeouts and intervals
    clearTimeout(timeoutRef.current);
    clearTimeout(imgTimeoutIDRef.current);
    clearTimeout(audioTimeoutIDRef.current);
    clearInterval(countdownIntervalRef.current);

    setResponseLoading(false);
    setWarningMessage(null);
    setShowWarning(false);
  }, []);

  // Add unmount cleanup
  useEffect(() => {
    return () => {
      cleanup();

      // Additional cleanup specific to unmounting
      window.history.replaceState({}, ""); // Remove uniqueID state from history
    };
  }, [cleanup]);

  // Add cleanup to error effect
  useEffect(() => {
    if (resultError) {
      cleanup();
      setOverviewData(null);
    }
  }, [resultError, cleanup, setOverviewData]);

  const stopRecording = useCallback(async () => {
    setRecording(false);
    cleanup(); // Call cleanup before setting response loading
    setResponseLoading(true);

    try {
      // Send final audio if recorder exists
      if (audioRecorderRef.current) {
        try {
          const { blob } = await audioRecorderRef.current.stop();
          const formData = new FormData();
          formData.append('audio', blob, 'audio.wav');

          const response = await fetch(
            `${URL}/upload_audio/${sessionDate.current}/${tempDirRef.current}?module_id=${selectedModule}`,
            {
              headers: {
                'Authorization': `Bearer ${user.accessToken}`
              },
              method: 'POST',
              cache: 'no-cache',
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
        } catch (error) {
          console.error('Error uploading final audio:', error);
        }
      }

      const response = await fetch(
        `${URL}/overview_response/${user.uid}/${sessionDate.current}?module_id=${selectedModule}`,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
          },
          timeout: 180000,
          body: JSON.stringify({
            "session_duration": SESSION_TIMER - countdown,
            "selected_topic": "Other"
          })
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setOverviewData(data);
      setSessionContextDate(getDateString());

      const currentSessionIdentifier = `${user.uid}-${getDateString()}`;
      const storageKey = `overviewData-${currentSessionIdentifier}`;
      sessionStorage.setItem(storageKey, JSON.stringify(data));

      navigate('/overview', { state: { tempDirRef: sessionIDUnique.current, module_id: selectedModule } });
    } catch (error) {
      console.error('Error during upload process or fetching response:', error);
      setresultError(true);
    }
  }, [cleanup, countdown, SESSION_TIMER, user, navigate, setOverviewData, setSessionContextDate, selectedModule]);


  const startRecording = useCallback(async (selectedModule) => {
    setOverviewData(null);
    if (resultError) setresultError(false);

    if (!navigator.mediaDevices) {
      console.error('getUserMedia not supported.');
      return;
    }

    try {
      setLoading(true);
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

      audioStreamRef.current = stream;
      // Pass selectedTopic to createSessionDir
      const session = await createSessionDir(sessionDate.current, user, selectedModule);
      if (!session.session_Dir_ID) {
        throw new Error('Failed to create session directory');
      }
      tempDirRef.current = session.session_Dir_ID;
      if (role === 'admin') {
        setQuestion(session.question);
        setScenario(session.scenario);
      }
      setLoading(false);
      setRecording(true);

      await startRecordingAudio(stream);

      timeoutRef.current = setTimeout(() => {
        setRecording(false);
        stopRecording();
      }, SESSION_TIMER * 1000);

      audioTimeoutIDRef.current = setTimeout(sendAudio, 20000);
    } catch (err) {
      console.error(err);
      handleWarning('Error starting recording');
      setLoading(false);
    }
  }, [SESSION_TIMER, user, handleWarning, startRecordingAudio, sendAudio, stopRecording, selectedModule]);

  const toggleWebcam = useCallback(() => {
    setWebcamVisible(prev => !prev);
  }, []);
  const toggleQuestions = useCallback(async () => {
    setShowQuestions(prev => !prev);

    if (role === 'admin') {
      if (question) {
        setCurrentQuestion(question);
      }
    } else {
      // Only update if there's a new question
      if (question && question !== currentQuestion) {
        setCurrentQuestion(question);
      } else if (!question) {
        setCurrentQuestion("Not enough topics to formulate a question yet. You can talk for a bit more.");
      }
    }

    setButtonDisabled(true);

    const waitTime = (role === 'admin' && isFirstQuestion) ? 10000 : 40000;

    if (role === 'admin' && isFirstQuestion) {
      setIsFirstQuestion(false);
    }

    await new Promise(resolve => setTimeout(resolve, waitTime));
    setButtonDisabled(false);
  }, [role, question, currentQuestion, isFirstQuestion]);

  // Modify the recording effect
  useEffect(() => {
    if (recording) {
      startVisualizer(micCanvasRef.current, null, audioStreamRef.current);
      const timer = setTimeout(() => {
        setButtonDisabled(false);
        if (role !== 'admin') {
          const initialMessage = "Don't know what to talk about? Click on 'Show a question' to start getting reflection questions to help you talk through the session.";
          setCurrentQuestion(initialMessage);
        }
      }, role === 'admin' && isFirstQuestion ? 5000 : 30000);

      return () => clearTimeout(timer);
    }
  }, [recording, role, isFirstQuestion]);

  // Set initial question based on role when component mounts
  useEffect(() => {
    if (role === 'admin' && scenario) {
      setCurrentQuestion(scenario);
    }
  }, [role, scenario]);

  // Add an effect to log question changes
  useEffect(() => {
    console.log('Question state updated to:', question);
  }, [question]);

  return {
    selectedModule, setSelectedModule,
    loading,
    responseLoading,
    recording,
    startRecording,
    audioCanvasRef,
    audioRef,
    audioEndingRef,
    micCanvasRef,
    camRef,
    canvasParentRef,
    countdown,
    currentQuestion,
    buttonDisabled,
    showQuestions,
    toggleQuestions,
    WarningMessage,
    webcamVisible,
    showAvatar,
    videoAccess,
    setVideoAccess,
    toggleWebcam,
    setShowAvatar,
    overviewData,
    stopRecording,
    resultError,
    handleScreenshots,
    loadVisualizer,
    handleWarning,
    microphone,
  };
};