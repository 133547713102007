import { URL } from "../../../Helpers";
import { Typography, Button, Grid, SvgIcon, Link, Box } from "@mui/material";
import { ReactComponent as MySessionWhite } from '../../data/mysession-white-icon.svg';
import { useMediaQuery } from 'react-responsive';
import { Link as RouterLink } from "react-router-dom";
import "./Footer.scss";
import TranslateComponent from './Translate';
import { ReactComponent as FullWhite } from '../../data/fullwhite.svg';
import { ReactComponent as IconWhite } from '../../data/iconwhite.svg';


const Footer = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' });

  return (
    <Grid width={"100%"} className="footer" position={"relative"} bottom={isSmallScreen ? 130 : 0} marginTop={isSmallScreen ? 20 : 0} bgcolor={"#FFF7F1"}>
      {isSmallScreen ? (
        <Grid width={"100%"}>
          <Grid sx={{ display: "flex", justifyContent: "center", columnGap: 3, mt: 2 }} >
            <Link href={URL + "/terms"} sx={{ color: "secondary.main", textDecoration:"none" }}>
              <Typography variant="sb16Bolder" className="animated-text">Terms and Conditions</Typography>
            </Link>
            <Link href={URL + "/privacy"} sx={{ color: "secondary.main", textDecoration:"none" }}>
              <Typography variant="sb16Bolder" className="animated-text">Privacy Policy</Typography>
            </Link>
            <Link to="/faq" component={RouterLink} sx={{ color: "secondary.main", textDecoration:"none" }}>
              <Typography variant="sb16Bolder" className="animated-text">FAQ</Typography>
            </Link>
            <Link to="/sos" component={RouterLink} sx={{ color: "#FFA1A1", textDecoration:"none" }}>
              <Typography variant="sb16Bolder" className="animated-text">SOS</Typography>
            </Link>
          </Grid>
          <div className="horizontal-line" style={{ background: "#5378B0" }} />
          <Grid sx={{ display: "flex", justifyContent: "space-between"}}>
            <div>
            <Box display={"flex"} height={"35px"} width={"fit-content"} overflow={"hidden"} alignItems={"center"} position={"relative"} right={"14px"}>
              <SvgIcon component={IconWhite} inheritViewBox style={{width:"50px", height:"auto"}}/>
              {/* <SvgIcon component={FullWhite} inheritViewBox style={{width:"125px", height:"120px"}}/> */}
              <Typography
                variant="sb24Bold"
                component={RouterLink}
                to="/get-started"
                sx={{ color: "white", textDecoration: 'none', paddingBottom:"5px" }}
              >
                pokamind
              </Typography>
              {/*<Typography variant="body2SemiBold" sx={{ pb: 5, flexGrow: 1, color: "primary.darkerBlue" }}>BETA</Typography> */}
            </Box>
              <Typography color={"secondary.superLightSand"} variant="body1SemiBold">2023 PokaMind ®</Typography>
            </div>
            <TranslateComponent />

          </Grid>
        </Grid>
      ) : (
        <>
          <Grid>
            <Box display={"flex"} height={"35px"} width={"fit-content"} overflow={"hidden"} alignItems={"center"} position={"relative"} right={"14px"}>
              <SvgIcon component={IconWhite} inheritViewBox style={{width:"50px", height:"auto"}}/>
              {/* <SvgIcon component={FullWhite} inheritViewBox style={{width:"125px", height:"120px"}}/> */}
              <Typography
                variant="sb24Bold"
                component={RouterLink}
                to="/get-started"
                sx={{ color: "white", textDecoration: 'none', paddingBottom:"5px" }}
              >
                pokamind
              </Typography>
              {/*<Typography variant="body2SemiBold" sx={{ pb: 5, flexGrow: 1, color: "primary.darkerBlue" }}>BETA</Typography> */}
            </Box>
            <Grid>
              <Typography color={"secondary.superLightSand"} variant="body1SemiBold">2023 PokaMind ®</Typography>
            </Grid>
          </Grid>
          <Grid sx={{ display: "flex", columnGap: 3 }}>
          <Link href={URL + "/terms"} passHref sx={{textDecoration:"none"}}>
      <Typography 
        variant="sb16Bolder" className="animated-text" 
        component="a" 
        sx={{ 
          color: "secondary.main", 
          textDecoration: 'none',
        }}
      >
        Terms and Conditions
      </Typography>
    </Link>
            <Link href={URL + "/privacy"} target="_blank" sx={{ color: "secondary.main", textDecoration:"none" }}>
              <Typography variant="sb16Bolder" className="animated-text">Privacy Policy</Typography>
            </Link>
            <Link to="/faq" component={RouterLink} sx={{ color: "secondary.main", textDecoration:"none" }}>
              <Typography variant="sb16Bolder" className="animated-text">FAQ</Typography>
            </Link>
            <Link to="/sos" component={RouterLink} sx={{ color: "#FFA1A1", textDecoration:"none" }}>
              <Typography variant="sb16Bolder" className="animated-text">SOS</Typography>
            </Link>
          </Grid>
          <TranslateComponent />
        </>
      )}
    </Grid>
  );
};

export default Footer;
