import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';  // Add components to the import

import { 
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Alert,
  Snackbar,
  CircularProgress,
  Card,
  CardContent,
  Stack,
  Slider,
  useTheme,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  Chip,
  Badge,
  MenuItem,       // Add this line
  LinearProgress  // Add this line
} from '@mui/material';
import { styled, alpha } from '@mui/system';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { doc, onSnapshot } from "firebase/firestore";
import { useLocation } from 'react-router-dom';
import {
  CheckCircle as CheckCircleIcon,
  Star as StarIcon,
  Edit as EditIcon,
  NotificationsActive as NotificationsIcon,
  Person as PersonIcon,
  AccountTree as AccountTreeIcon,
  Transform as TransformIcon,
  Lightbulb as LightbulbIcon,
  Chat as ChatIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
  Psychology as PsychologyIcon,
  TrendingUp as TrendingUpIcon,
  Lock as LockIcon,
  Mail as MailIcon,
  CalendarToday as CalendarIcon,
  PhoneAndroid as PhoneIcon,
  Groups as GroupsIcon,
  AccessTime as AccessTimeIcon,
  Language as LanguageIcon,
} from '@mui/icons-material';


import { db } from "../../Firebase";
import { useUser } from '../../contexts/UserContext';
import { URL } from '../../Helpers';
import SlackAuthButton from '../../components/SlackButton';
import SlackSuccessNotification from '../../components/SlackSuccessNotification';
import chromeLogo from '../data/chrome-logo.png';
import slackLogo from '../data/slack-logo.png';
import playStoreLogo from '../data/google-play.png';
import { SaveAllIcon } from 'lucide-react';



// Styled Components
const ProfileSection = styled(Card)(({ theme }) => ({
  background: '#fff',
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  overflow: 'visible',
  position: 'relative',
  transition: 'transform 0.3s ease-in-out',
  // Base z-index when nothing is open
  zIndex: 1,
  // When section contains a select that's focused/open
  '.select__control--is-focused': {
    // Increase z-index of the container when select is open
    '& ~ &': {
      zIndex: 0
    }
  },
  // Ensure select menu appears above other sections
  '.select__menu': {
    zIndex: 1000
  },
  '&:hover': {
    transform: 'translateY(0px)',
  }
}));


const StyledSelect = styled(Select)(({ theme }) => ({
  '& .select__control': {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    minHeight: 56,
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&--is-focused': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
    }
  },
  '& .select__menu': {
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
    marginTop: '4px',
    overflow: 'visible',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -8,
      left: 16,
      width: 16,
      height: 16,
      backgroundColor: theme.palette.background.paper,
      transform: 'rotate(45deg)',
      border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
      borderRight: 'none',
      borderBottom: 'none',
      zIndex: 0,
    }
  },
  '& .select__menu-list': {
    position: 'relative',
    zIndex: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  '& .select__option': {
    padding: theme.spacing(1.5, 2),
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&--is-focused': {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
    '&--is-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  },
  '& .select__multi-value': {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 0.5, 0.5, 1),
    gap: theme.spacing(0.5),
    '& .select__multi-value__label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    '& .select__multi-value__remove': {
      color: theme.palette.primary.main,
      padding: theme.spacing(0.5),
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.primary.dark,
      }
    }
  }
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  height: 6,
  padding: '15px 0',
  borderRadius: 3,
  
  '& .MuiSlider-rail': {
    background: `linear-gradient(90deg, 
      ${alpha(theme.palette.primary.main, 0.1)} 0%,
      ${alpha(theme.palette.primary.main, 0.2)} 50%,
      ${alpha(theme.palette.primary.main, 0.3)} 100%
    )`,
    opacity: 1,
    height: 6,
  },
  
  '& .MuiSlider-track': {
    background: `linear-gradient(90deg, 
      ${theme.palette.primary.main} 0%,
      ${theme.palette.primary.dark} 100%
    )`,
    border: 'none',
    height: 6,
  },
  
  '& .MuiSlider-thumb': {
    width: 24,
    height: 24,
    backgroundColor: '#fff',
    boxShadow: `0 2px 8px ${alpha(theme.palette.primary.main, 0.2)}`,
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0 0 0 8px ${alpha(theme.palette.primary.main, 0.16)}`,
    },
    '&::before': {
      content: '""',
      width: 12,
      height: 12,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      transition: 'all 0.2s ease',
    },
    '&:hover::before': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-active': {
      boxShadow: `0 0 0 12px ${alpha(theme.palette.primary.main, 0.16)}`,
    },
  },
  
  '& .MuiSlider-mark': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${alpha(theme.palette.primary.main, 0.3)}`,
    boxShadow: `0 1px 4px ${alpha(theme.palette.common.black, 0.05)}`,
    '&.MuiSlider-markActive': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  
  '& .MuiSlider-markLabel': {
    fontSize: '0.75rem',
    fontWeight: 500,
    marginTop: 8,
    color: theme.palette.text.secondary,
    '&.MuiSlider-markLabelActive': {
      color: theme.palette.primary.main,
    },
  },

  // Improve mobile touch target
  '@media (pointer: coarse)': {
    '& .MuiSlider-thumb': {
      width: 28,
      height: 28,
      '&::before': {
        width: 14,
        height: 14,
      },
    },
  },
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  border: `4px solid ${theme.palette.background.paper}`,
  boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
  margin: '-60px auto 20px',
}));

const FeatureBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: alpha(theme.palette.primary.main, 0.04),
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    transform: 'translateX(4px)',
  }
}));


// Constants
const OPTIONS = {
  goals: [
    { value: 'Strategic Vision', label: 'Develop Strategic Vision', icon: <StarIcon /> },
    { value: 'Team Leadership', label: 'Enhance Team Leadership', icon: <PeopleIcon /> },
    { value: 'Decision Making', label: 'Improve Decision Making', icon: <AccountTreeIcon /> },
    { value: 'Change Management', label: 'Master Change Management', icon: <TransformIcon /> },
    { value: 'Innovation', label: 'Drive Innovation', icon: <LightbulbIcon /> },
    { value: 'Communication', label: 'Executive Communication', icon: <ChatIcon /> }
  ],
  competencies: [
    { value: 'Strategic Planning', label: 'Strategic Planning', icon: <BusinessIcon /> },
    { value: 'Executive Presence', label: 'Executive Presence', icon: <PersonIcon /> },
    { value: 'Cross-functional Leadership', label: 'Cross-functional Leadership', icon: <PeopleIcon /> },
    { value: 'Stakeholder Management', label: 'Stakeholder Management', icon: <PsychologyIcon /> },
    { value: 'Business Acumen', label: 'Business Acumen', icon: <TrendingUpIcon /> }
  ],
  notificationPref: [
    { value: 'Email', label: 'Email Updates', icon: <MailIcon /> },
    { value: 'Calendar', label: 'Calendar Invites', icon: <CalendarIcon /> },
    { value: 'Mobile', label: 'Mobile Notifications', icon: <PhoneIcon /> },
    { value: 'Slack', label: 'Slack Alerts', icon: <PhoneIcon /> }
  ],
  gender: [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },
    { value: 'Prefer not to say', label: 'Prefer not to say' }
  ]
};

const stripePromise = loadStripe(process.env.REACT_APP_stripeKey);

const UserProfileComponent = () => {

  const theme = useTheme();
  const { user, userName, tier, premium_status, role, refreshUserClaims } = useUser();
  const location = useLocation();
  const premiumSectionRef = useRef();
  
  // State Management
  const [profileDetails, setProfileDetails] = useState({
    email: user.email,
    phone: '',
    displayName: userName || '',
    goals: [],
    competencies: [],
    notificationPref: [],
    age: '',
    gender: null,
    performanceLevel: 0,
    recurringPayments: true,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [initialProfileDetails, setInitialProfileDetails] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [premiumLoading, setPremiumLoading] = useState(false);


    // Handlers
    const handleChange = (name, value) => {
      setProfileDetails(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    const handlePremium = async () => {
      setPremiumLoading(true);
      try {
        if (role !== "admin") {
          if (tier === "free") {
            window.location.href = "/plans";
          } else if (premium_status === "active" || premium_status !== "active") {
            window.location.href = `${process.env.REACT_APP_StripeCustomerPortal}?prefilled_email=${user.email}`;
          }
        }
      } catch (error) {
        setError('Failed to process premium request. Please try again.');
      } finally {
        setPremiumLoading(false);
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError('');
      setSuccess('');
  
      const profileToSubmit = {
        ...profileDetails,
        age: parseInt(profileDetails.age),
        gender: profileDetails.gender?.value,
        goals: profileDetails.goals.map(option => option.value),
        competencies: profileDetails.competencies.map(option => option.value),
        notificationPref: profileDetails.notificationPref.map(option => option.value),
        recurringPayments: Boolean(profileDetails.recurringPayments),
      };
  
      try {
        const response = await fetch(`${URL}/updateProfile`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
          },
          body: JSON.stringify(profileToSubmit),
        });
  
        if (!response.ok) throw new Error('Failed to update profile');
  
        sessionStorage.setItem(`userProfile_${user.uid}`, JSON.stringify(profileToSubmit));
        setSuccess('Profile updated successfully');
        refreshUserClaims();
      } catch (error) {
        setError('Failed to update profile. Please try again.');
      } finally {
        setLoading(false);
      }
    };

      // Utilities
  const formatProfileData = (data) => ({
    displayName: data.displayName || '',
    age: data.age || '',
    gender: OPTIONS.gender.find(option => option.value === data.gender) || null,
    performanceLevel: data.performanceLevel || 0,
    recurringPayments: Boolean(data.recurringPayments),
    goals: Array.isArray(data.goals) 
      ? data.goals.map(goal => ({ value: goal, label: goal })) 
      : [],
    competencies: Array.isArray(data.competencies) 
      ? data.competencies.map(competencies => ({ value: competencies, label: competencies })) 
      : [],
    notificationPref: Array.isArray(data.notificationPref) 
      ? data.notificationPref.map(pref => ({ value: pref, label: pref })) 
      : [],
  });

  const hasChanges = JSON.stringify(profileDetails) !== JSON.stringify(initialProfileDetails);
  
    // Effects
    useEffect(() => {
      let unsubscribe;
  
      if (user?.uid) {
        const docRef = doc(db, "users", user.uid);
        const storage = JSON.parse(sessionStorage.getItem(`userProfile_${user.uid}`));
        
        if (storage) {
          const userProfile = formatProfileData(storage);
          setProfileDetails(userProfile);
          setInitialProfileDetails(userProfile);
        } else {
          unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
              const userProfile = formatProfileData(doc.data());
              setProfileDetails(userProfile);
              setInitialProfileDetails(userProfile);
            }
          });
        }
      }
  
      return () => unsubscribe?.();
    }, [user]);
  
    useEffect(() => {
      if (location?.hash === '#premium_subscription' && premiumSectionRef.current) {
        premiumSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, [location]);
  
    useEffect(() => {
      if (error || success) {
        setSnackbarMessage(error || success);
        setSnackbarSeverity(error ? 'error' : 'success');
        setOpenSnackbar(true);
      }
    }, [error, success]);

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <SlackSuccessNotification />
      
      <Stack spacing={6}>
        {/* Profile Header */}
        <ProfileSection>
          <Box
            sx={{
              height: 200,
              background: theme => `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
              borderRadius: '16px 16px 0 0',
            }}
          />
          
          <CardContent sx={{ textAlign: 'center', mt: -8, pb: 4 }}>
            <ProfileAvatar>
              {profileDetails.displayName?.[0]?.toUpperCase() || <PersonIcon fontSize="large" />}
            </ProfileAvatar>
            
            <Typography variant="h4" gutterBottom>
              {profileDetails.displayName || 'Your Name'}
            </Typography>
            
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              mb={4}
            >
              <Chip
                icon={<StarIcon />}
                label={tier === "free" ? "Free Plan" : "Premium Member"}
                color={tier === "free" ? "default" : "primary"}
                sx={{ px: 2, height: 32 }}
              />
            <Chip
              icon={<NotificationsIcon />}
              label={`${profileDetails?.notificationPref?.length || 0} Notifications`}
              variant="outlined"
              sx={{ px: 2, height: 32 }}
            />
            </Stack>

            {/* Quick Stats */}
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={4}>
                <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" color="primary">
                  {profileDetails?.goals?.length || 0}
                </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Active Goals
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" color="primary">
                      {profileDetails?.competencies?.length || 0}
                    </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Competencies
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" color="primary">
                    Level {profileDetails.performanceLevel}/5
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  Current Performance Level
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </ProfileSection>


{/* Integrations Section */}
<ProfileSection>
  <CardContent sx={{ p: { xs: 2, md: 4 } }}>
    <Stack spacing={3}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 2 
      }}>
        <Typography variant="h5" color="primary">
          Platform Integrations
        </Typography>
        <Chip 
          label={`${profileDetails?.integrations?.length || 0} Connected`}
          size="small"
          color="primary"
          variant="outlined"
        />
      </Box>

      {/* Integration Cards Container */}
      <Grid container spacing={3}>
        {/* Slack Integration */}
        <Grid item xs={12} md={4}>
          <Card
            elevation={0}
            sx={{
              p: 3,
              height: '100%',
              border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              borderRadius: 2,
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                borderColor: theme.palette.primary.main,
                backgroundColor: alpha(theme.palette.primary.main, 0.02)
              }
            }}
          >
            <Stack spacing={2}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2 
              }}>
                <Box
                  component="img"
                  src={slackLogo}
                  sx={{ 
                    width: 32, 
                    height: 32,
                    borderRadius: 1
                  }}
                  alt="Slack"
                />
                <Typography variant="subtitle1" fontWeight={600}>
                  Slack
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ minHeight: 40 }}>
                Connect with your team and receive notifications directly in Slack.
              </Typography>
              <SlackAuthButton />
            </Stack>
          </Card>
        </Grid>

        {/* Chrome Extension */}
        <Grid item xs={12} md={4}>
          <Card
            elevation={0}
            sx={{
              p: 3,
              height: '100%',
              border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              borderRadius: 2,
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                borderColor: theme.palette.primary.main,
                backgroundColor: alpha(theme.palette.primary.main, 0.02)
              }
            }}
          >
            <Stack spacing={2}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2 
              }}>
                <Box
                  component="img"
                  src= {chromeLogo}
                  sx={{ 
                    width: 32, 
                    height: 32,
                    borderRadius: 1
                  }}
                  alt="Chrome"
                />
                <Typography variant="subtitle1" fontWeight={600}>
                  Chrome Extension
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ minHeight: 40 }}>
                Access your workspace directly from your browser with our Chrome extension.
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                // startIcon={<img src= {chromeLogo} alt="" style={{ width: 20, height: 20 }} />}
                onClick={() => window.open('https://chromewebstore.google.com/detail/pokamind/biioohgafmlchekfaidljmlihodknloi', '_blank')}
                sx={{
                  width: '100%',
                  py: 1,
                  borderRadius: 2,
                  textTransform: 'none',
                  borderColor: alpha(theme.palette.primary.main, 0.3),
                  '&:hover': {
                    borderColor: theme.palette.primary.main,
                    backgroundColor: alpha(theme.palette.primary.main, 0.05)
                  }
                }}
              >
                Add to Chrome
              </Button>
            </Stack>
          </Card>
        </Grid>

        {/* Android App */}
        <Grid item xs={12} md={4}>
          <Card
            elevation={0}
            sx={{
              p: 3,
              height: '100%',
              border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              borderRadius: 2,
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                borderColor: theme.palette.primary.main,
                backgroundColor: alpha(theme.palette.primary.main, 0.02)
              }
            }}
          >
            <Stack spacing={2}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2 
              }}>
                <Box
                  component="img"
                  src= {playStoreLogo}
                  sx={{ 
                    width: 32, 
                    height: 32,
                    borderRadius: 1
                  }}
                  alt="Google Play"
                />
                <Typography variant="subtitle1" fontWeight={600}>
                  Mobile App
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ minHeight: 40 }}>
                Take your workspace on the go with our Android mobile application.
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                // startIcon={<img src= {playStoreLogo} alt="" style={{ width: 20, height: 20 }} />}
                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.pokamind.app.twa', '_blank')}
                sx={{
                  width: '100%',
                  py: 1,
                  borderRadius: 2,
                  textTransform: 'none',
                  borderColor: alpha(theme.palette.primary.main, 0.3),
                  '&:hover': {
                    borderColor: theme.palette.primary.main,
                    backgroundColor: alpha(theme.palette.primary.main, 0.05)
                  }
                }}
              >
                Get on Play Store
              </Button>
            </Stack>
          </Card>
        </Grid>
      </Grid>

      {/* Integration Note */}
      <Box sx={{ 
        mt: 3, 
        p: 2, 
        borderRadius: 2,
        backgroundColor: alpha(theme.palette.info.main, 0.05),
        border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
      }}>
        <Typography variant="body2" color="text.secondary">
          Connect with your favorite platforms to enhance your workspace experience. 
          More integrations coming soon.
        </Typography>
      </Box>
    </Stack>
  </CardContent>
</ProfileSection>

 {/* Personal Information Section */}
<ProfileSection>
  <CardContent sx={{ p: { xs: 2, md: 4 } }}>
    <Stack spacing={4}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        pb: 2,
        borderBottom: 1,
        borderColor: theme => alpha(theme.palette.divider, 0.1)
      }}>
        <Box>
          <Typography variant="h5" color="primary" gutterBottom>
            Personal Information
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Manage your personal and contact information
          </Typography>
        </Box>
        <Tooltip title="Save Changes">
          <IconButton 
            onClick={handleSubmit}
            color="primary"
            sx={{ 
              bgcolor: theme => alpha(theme.palette.primary.main, 0.05),
              '&:hover': {
                bgcolor: theme => alpha(theme.palette.primary.main, 0.1)
              }
            }}
          >
            <SaveAllIcon/>
          </IconButton>
        </Tooltip>
      </Box>

      <Grid container spacing={3}>
        {/* Basic Info Group */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
            Basic Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Display Name"
                name="displayName"
                value={profileDetails.displayName}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                InputProps={{
                  startAdornment: (
                    <Box
                      sx={{
                        mr: 1,
                        p: 0.5,
                        borderRadius: 1,
                        bgcolor: theme => alpha(theme.palette.primary.main, 0.05),
                        color: 'primary.main'
                      }}
                    >
                      <PersonIcon fontSize="small" />
                    </Box>
                  )
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Contact Info Group */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
            Contact Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              {/* uneditable grayed out field */}
              <TextField
                disabled
                fullWidth
                label="Email Address"
                name="email"
                
                value={profileDetails.email}
                InputProps={{
                  startAdornment: (
                    <Box
                      sx={{
                        mr: 1,
                        p: 0.5,
                        borderRadius: 1,
                        bgcolor: theme => alpha(theme.palette.primary.main, 0.05),
                        color: 'primary.main'
                      }}
                    >
                      <MailIcon fontSize="small" />
                    </Box>
                  )
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>

      
      </Grid>
    </Stack>
  </CardContent>
</ProfileSection>

{/* Goals and Preferences Section */}
<ProfileSection>
  <CardContent sx={{ p: { xs: 2, md: 4 } }}>
    <Stack spacing={4}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 2 
      }}>
        <Typography variant="h5" color="primary">
          Goals and Competencies
        </Typography>
        <Tooltip title="Save Changes">
          <IconButton 
            onClick={handleSubmit}
            color="primary"
            sx={{ 
              bgcolor: theme => alpha(theme.palette.primary.main, 0.05),
              '&:hover': {
                bgcolor: theme => alpha(theme.palette.primary.main, 0.1)
              }
            }}
          >
            <SaveAllIcon/>
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ 
        backgroundColor: alpha(theme.palette.primary.main, 0.03), 
        borderRadius: 2,
        p: 3
      }}>
        <Grid container spacing={4}>
          {/* Leadership Goals */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="primary" gutterBottom fontWeight={600}>
              Leadership Goals
            </Typography>
            <StyledSelect
              isMulti
              options={OPTIONS.goals}
              value={profileDetails?.goals}
              onChange={(value) => handleChange('goals', value)}
              placeholder="Select Leadership Goals"
              components={{
                Option: ({ children, ...props }) => (
                  <components.Option {...props}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                      {props.data.icon}
                      <Typography variant="body2">{children}</Typography>
                    </Box>
                  </components.Option>
                )
              }}
            />
          </Grid>

          {/* Core Competencies */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="primary" gutterBottom fontWeight={600}>
              Core Competencies
            </Typography>
            <StyledSelect
              isMulti
              options={OPTIONS.competencies}
              value={profileDetails?.competencies}
              onChange={(value) => handleChange('competencies', value)}
              placeholder="Select Core Competencies"
              components={{
                Option: ({ children, ...props }) => (
                  <components.Option {...props}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                      {props.data.icon}
                      <Typography variant="body2">{children}</Typography>
                    </Box>
                  </components.Option>
                )
              }}
            />
          </Grid>

          {/* Performance Level */}
          <Grid item xs={12}>
            <Box sx={{ 
              backgroundColor: 'background.paper', 
              p: 3, 
              borderRadius: 2,
              border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`
            }}>
              <Typography variant="subtitle1" color="primary" gutterBottom fontWeight={600}>
                Performance Level
              </Typography>
              <StyledSlider
                value={profileDetails?.performanceLevel || 0}
                onChange={(e, value) => handleChange('performanceLevel', value)}
                min={0}
                max={5}
                step={1}
                marks={[
                  { value: 0, label: 'Beginner' },
                  { value: 1, label: 'Basic' },
                  { value: 2, label: 'Intermediate' },
                  { value: 3, label: 'Advanced' },
                  { value: 4, label: 'Expert' },
                  { value: 5, label: 'Master' }
                ]}
                
                sx={{ mt: 3, mb: 0 }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

    </Stack>
  </CardContent>
</ProfileSection>

{/* Premium Section */}
<ProfileSection ref={premiumSectionRef} id="premium_subscription">
          <CardContent sx={{ p: 0 }}>
            {/* Premium Header */}
            <Box
              sx={{
                background: theme => `linear-gradient(135deg, 
                  ${theme.palette.primary.dark} 0%,
                  ${theme.palette.primary.main} 100%)`,
                p: 4,
                borderRadius: '16px 16px 0 0',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'radial-gradient(circle at top right, rgba(255,255,255,0.2) 0%, transparent 60%)',
                }
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                  <Typography variant="h5" color="white">
                    Current Plan
                  </Typography>
                  <Chip
                    label={tier === "free" ? "Free" : "Premium"}
                    icon={tier === "free" ? <LockIcon /> : <StarIcon />}
                    sx={{
                      bgcolor: tier === "free" ? 'rgba(255,255,255,0.2)' : 'warning.light',
                      color: 'white',
                      '& .MuiChip-icon': {
                        color: 'inherit'
                      },
                      px: 1
                    }}
                  />
                </Stack>
                <Typography variant="body1" color="rgba(255,255,255,0.8)">
                  {tier === "free" 
                    ? "Upgrade to unlock premium features and enhance your experience"
                    : "Thank you for being a premium member"}
                </Typography>
              </Box>
            </Box>

            {/* Plan Comparison */}
            <Box sx={{ p: 4 }}>
              <Grid container spacing={4}>
                {/* Current Plan Features */}
                <Grid item xs={12} md={6}>
                  <Card 
                    elevation={0}
                    sx={{ 
                      bgcolor: 'white',
                      p: 3,
                      borderRadius: 2,
                      height: '100%'
                    }}
                  >
                    <Stack spacing={3}>
                      <Typography variant="h6" color="text.primary" gutterBottom>
                        Your Plan Includes:
                      </Typography>
                      {[
                        { text: '3 Minutes Per Session', icon: '⏱️' },
                        { text: '1 Session Per Week', icon: '📅' },
                        { text: 'Limited Access to Self-Improvement Content', icon: '📚' }
                      ].map((feature, index) => (
                        <FeatureBox key={index}>
                          <Box
                            sx={{
                              width: 40,
                              height: 40,
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              bgcolor: 'primary.light',
                              color: 'primary.main',
                              flexShrink: 0
                            }}
                          >
                            {feature.icon}
                          </Box>
                          <Typography variant="body1">{feature.text}</Typography>
                        </FeatureBox>
                      ))}
                    </Stack>
                  </Card>
                </Grid>

                {/* Premium Features */}
                <Grid item xs={12} md={6}>
                  <Card 
                    elevation={0}
                    sx={{ 
                      bgcolor: 'white',
                      p: 3,
                      borderRadius: 2,
                      height: '100%',
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -15,
                        right: -15,
                        bgcolor: 'rgba(255,200,0,1)',
                        color: 'warning.contrastText',
                        p: 1,
                        px: 3,
                        transform: 'rotate(45deg)',
                        transformOrigin: 'right bottom',
                        boxShadow: 2,
                        zIndex: 1
                      }}
                    >
                      <Typography variant="caption" fontWeight="bold">
                        PREMIUM
                      </Typography>
                    </Box>

                    <Stack spacing={3}>
                      <Typography variant="h6" color="text.primary" gutterBottom>
                        With Premium:
                      </Typography>
                      {[
                        { text: '10 Minutes Per Session', icon: '⚡' },
                        { text: 'Unlimited Sessions Per Day*', icon: '🎯' },
                        { text: 'Full Access to Self-Improvement Content', icon: '🌟' }
                      ].map((feature, index) => (
                        <FeatureBox 
                          key={index}
                          sx={{ 
                            // gold color yellow 
                            bgcolor: 'rgba(255,200,0,1)',
                            '&:hover': {
                              bgcolor: 'rgba(250,200,0,1)',
                            }
                          }}
                        >
                          <Box
                            sx={{
                              width: 40,
                              height: 40,
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              bgcolor: 'rgba(200,150,0,1)',
                              color: 'warning.contrastText',
                              flexShrink: 0
                            }}
                          >
                            {feature.icon}
                          </Box>
                          <Typography variant="body1" color="black">
                            {feature.text}
                          </Typography>
                        </FeatureBox>
                      ))}
                      <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                        *Limited to maximum 14 sessions per week*
                      </Typography>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>

              {/* Premium Action Button */}
              {role !== "admin" && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
                  <Button
                    variant="contained"
                    color="warning"
                    size="large"
                    disabled={premiumLoading}
                    onClick={handlePremium}
                    sx={{
                      px: 6,
                      py: 2,
                      borderRadius: 3,
                      textTransform: 'none',
                      fontSize: '1.1rem',
                      boxShadow: theme => `0 8px 16px ${alpha(theme.palette.warning.main, 0.3)}`,
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: theme => `0 12px 20px ${alpha(theme.palette.warning.main, 0.4)}`,
                      }
                    }}
                    startIcon={premiumLoading ? <CircularProgress size={24} color="inherit" /> : <StarIcon />}
                  >
                    {premiumLoading ? 'Processing...' :
                      tier === "free" && !premium_status ? 'Upgrade to Premium' :
                      tier === "free" && premium_status ? 'Fix Subscription' :
                      'Manage Subscription'}
                  </Button>
                </Box>
              )}
            </Box>
          </CardContent>
        </ProfileSection>

        {/* Snackbar for notifications */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          TransitionComponent={Fade}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarSeverity}
            variant="filled"
            elevation={6}
            sx={{
              borderRadius: 2,
              width: '100%',
              boxShadow: theme => `0 8px 16px ${alpha(
                theme.palette[snackbarSeverity].main,
                0.2
              )}`,
              '& .MuiAlert-icon': {
                fontSize: '1.5rem'
              },
              '& .MuiAlert-message': {
                fontSize: '1rem'
              }
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Stack>
    </Container>
  );
};

const UserProfile = () => (
  <Elements stripe={stripePromise}>
    <UserProfileComponent />
  </Elements>
);

export default UserProfile;