import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { getAuth, verifyBeforeUpdateEmail } from 'firebase/auth';
import Avatar from 'boring-avatars';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Toolbar,
  Typography,
  Divider
} from '@mui/material';
import { Menu as MenuIcon } from 'lucide-react';
import { deleteUser } from '../../../Helpers';
import { useUser } from "../../../contexts/UserContext";
import { URL } from "../../../Helpers";
import TranslateComponent from '../shared/Translate';
import SidebarToggle from '../home/SidebarToggle';

// Import SVG components
import { ReactComponent as Home } from '../../data/home-icon.svg';
import { ReactComponent as MySessionWhite } from '../../data/mysession-white-icon.svg';
import { ReactComponent as MySessionBlue } from '../../data/mysession-blue-icon.svg';
import { ReactComponent as MyMood } from '../../data/mymood-icon.svg';
import { ReactComponent as Articles } from '../../data/blog-icon.svg';
import { ReactComponent as PokaTherapy } from '../../data/psychology.svg';
import { ReactComponent as IconBlue } from '../../data/iconblue.svg';

const DRAWER_WIDTH = 240;
const COLLAPSED_WIDTH = 72;
const HOVER_DELAY = 200;

const FOOTER_LINKS = [
  { to: `${URL}/terms`, text: "Terms and Conditions", external: true },
  { to: `${URL}/privacy`, text: "Privacy Policy", external: true },
  { to: "/faq", text: "FAQ", external: false }
];

const styles = {
  drawer: {
    transition: theme => theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter
    }),
    paper: {
      bgcolor: '#5C83BF',
      borderRight: 'none',
      boxShadow: '2px 0 8px rgba(0, 0, 0, 0.1)',
      overflowX: 'hidden'
    }
  },
  navItem: {
    borderRadius: '24px',
    transition: 'all 0.2s',
    '&:hover': {
      bgcolor: 'rgba(255,255,255,0.1)'
    }
  },
  iconContainer: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s'
  },
  premiumButton: {
    bgcolor: '#FFD700',
    color: '#5C83BF',
    fontWeight: 'bold',
    '&:hover': {
      bgcolor: '#FFE44D'
    }
  }
};

const Navbar = () => {
  const [isClickExpanded, setIsClickExpanded] = useState(false);
  const [isHoverExpanded, setIsHoverExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [email, setEmail] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [isPinned, setIsPinned] = useState(false);
  

  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userName, role, tier, premium_status } = useUser();
  
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const isXsMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const isExpanded = isClickExpanded || isHoverExpanded;

  const navigationItems = [
    {
      title: 'Home',
      path: '/get-started',
      icon: Home,
      isActive: location.pathname === '/get-started'
    },

    {
      title: 'Report',
      path: '/admin-report',
      icon: MyMood,
      isActive: location.pathname === '/mymood'
    },
    ...(role === 'user' ? [{
      title: 'MyTherapy',
      path: '/book-therapist',
      icon: PokaTherapy,
      isActive: location.pathname === '/book-therapist'
    }] : []),
    ...(role === 'therapist' ? [{
      title: 'MyTherapy',
      path: '/therapist-view',
      icon: PokaTherapy,
      isActive: location.pathname === '/therapist-view'
    }] : [])
  ];

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
  }, [user]);

  const handleMouseEnter = useCallback(() => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setIsHoverExpanded(true);
  }, [hoverTimeout]);

  const handleToggle = () => {
    setIsPinned(!isPinned);
    setIsClickExpanded(!isClickExpanded);
  };

  const handleMouseLeave = useCallback(() => {
    if (!isClickExpanded) {
      const timeout = setTimeout(() => {
        setIsHoverExpanded(false);
      }, HOVER_DELAY);
      setHoverTimeout(timeout);
    }
  }, [isClickExpanded]);

  const toggleDrawer = () => {
    setIsClickExpanded(!isClickExpanded);
    if (!isClickExpanded) {
      setIsHoverExpanded(false);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem('user');
      localStorage.removeItem('role');
      sessionStorage.clear();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleUpdateEmail = async () => {
    if (!user) {
      alert('You must be logged in to update your account');
      return;
    }

    const newEmail = prompt('Please enter your new email address:');
    if (!newEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
      alert('Please enter a valid email address');
      return;
    }

    try {
      await verifyBeforeUpdateEmail(user, newEmail);
      alert('Verification email sent. Please check your inbox.');
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        alert('Please sign out and sign in again to update your email.');
        handleLogout();
      } else {
        alert(error.message || 'Failed to update email');
      }
    }
  };

  const handleDeleteAccount = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone."
    );
    if (confirmation) {
      try {
        await deleteUser(auth.currentUser.accessToken);
        handleLogout();
      } catch (error) {
        console.error('Error deleting account:', error);
      }
    }
  };

  const handleExternalLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const renderLanguageSelector = (isMobile = false) => (
    <Box 
      sx={{ 
        width: isExpanded ? '100%' : COLLAPSED_WIDTH - 16,
        mx: 'auto',
        mb: 2
      }}
    >
      <TranslateComponent 
        customStyles={{
          button: {
            color: 'white',
            width: '100%',
            borderRadius: '24px',
            py: 1.5,
            px: isExpanded ? 2 : 0,
            justifyContent: isExpanded ? 'flex-start' : 'center',
            '&:hover': {
              bgcolor: 'rgba(255,255,255,0.1)'
            }
          },
          text: {
            ml: isExpanded ? 2 : 0,
            opacity: isExpanded ? 1 : 0,
            display: isExpanded ? 'block' : 'none'
          }
        }}
      />
    </Box>
  );

  const renderMobileNavigation = () => (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "#FFF7F1",
        bottom: 0,
        top: 'auto',
        borderTop: '1px solid rgba(0,0,0,0.1)',
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.05)',
        zIndex: 1100
      }}
    >
      <Toolbar sx={{ minHeight: '64px' }}>
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
          {navigationItems.slice(0, 4).map((item) => (
            <Button
              key={item.path}
              component={Link}
              to={item.path}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 0.5,
                color: item.isActive ? 'primary.main' : 'text.secondary',
                '&:hover': { bgcolor: 'transparent' }
              }}
            >
              <Box
                sx={{
                  ...styles.iconContainer,
                  bgcolor: item.isActive ? 'primary.main' : 'primary.backgroundBlue'
                }}
              >
                <SvgIcon
                  component={item.icon}
                  inheritViewBox
                  sx={{
                    color: item.isActive ? 'white' : 'primary.main',
                    ...(item.title === 'Training' && { color: 'white' })
                  }}
                />
              </Box>
              <Typography 
                variant="body2Bold" 
                sx={{ 
                  fontSize: '0.75rem',
                  color: item.isActive ? 'primary.main' : 'text.secondary'
                }}
              >
                {item.title}
              </Typography>
            </Button>
          ))}
          <IconButton
            onClick={handleMenuOpen}
            sx={{ 
              ...styles.iconContainer,
              color: 'primary.main'
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );

  const renderMobileHeader = () => (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "#5C83BF",
        color: "white",
        top: 0,
        left: 0,
        right: 0,
        height: { xs: '56px', sm: '64px' },
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 1100
      }}
    >
      <Toolbar sx={{ minHeight: { xs: '56px', sm: '64px' }, px: { xs: 1.5, sm: 2 } }}>
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <SvgIcon
              component={IconBlue}
              inheritViewBox
              sx={{
                width: { xs: 28, sm: 32 },
                height: { xs: 28, sm: 32 },
                fill: 'white',
                color: 'white',
                filter: 'brightness(0) invert(1)',
                flexShrink: 0
              }}
            />
            <Typography
              variant="h6"
              component={Link}
              to="/get-started"
              sx={{
                color: 'white',
                textDecoration: 'none',
                fontWeight: 600,
                fontSize: { xs: '1rem', sm: '1.25rem' }
              }}
            >
              pokamind
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <Box sx={{ width: 'auto', mr: 1 }}>
              <TranslateComponent
                customStyles={{
                  button: {
                    color: 'white',
                    minWidth: 40,
                    width: 40,
                    height: 40,
                    padding: 0,
                    borderRadius: '50%',
                    '&:hover': {
                      bgcolor: 'rgba(255,255,255,0.1)'
                    }
                  },
                  text: {
                    display: 'none'
                  }
                }}
              />
            </Box>

            {tier === "free" && !premium_status && (
              <Button
                component={Link}
                to="/plans"
                variant="contained"
                sx={{
                  ...styles.premiumButton,
                  px: 2,
                  height: 36,
                  minWidth: 'auto',
                  borderRadius: '18px'
                }}
              >
                <Typography 
                  variant="body2Bold"
                  sx={{
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  }}
                >
                  Try Premium
                </Typography>
              </Button>
            )}

            <Avatar
              name={email || 'default'}
              size={32}
              variant="beam"
              onClick={handleMenuOpen}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );

  const renderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          minWidth: 240,
          bgcolor: 'white',
          mt: 1,
          borderRadius: '20px',
          overflow: 'hidden'
        }
      }}
    >
      <Box sx={{ px: 2, py: 1.5 }}>
        <Typography variant="body2Bold" color="text.secondary">
          {userName || email}
        </Typography>
      </Box>
      
      <Divider />

      <MenuItem
        onClick={() => {
          navigate('/user-profile');
          handleMenuClose();
        }}
        sx={{ py: 1.5 }}

          >
            <ListItemText primary="My Profile" />
          </MenuItem>
    
          <MenuItem
            onClick={() => {
              handleUpdateEmail();
              handleMenuClose();
            }}
            sx={{ py: 1.5 }}
          >
            <ListItemText primary="Update Email" />
          </MenuItem>
    
          <Divider />
    
          {FOOTER_LINKS.map((link, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                if (link.external) {
                  handleExternalLink(link.to);
                } else {
                  navigate(link.to);
                }
                handleMenuClose();
              }}
              sx={{ py: 1.5 }}
            >
              <ListItemText primary={link.text} />
            </MenuItem>
          ))}
    
          <Divider />
    
          <MenuItem
            onClick={() => {
              handleLogout();
              handleMenuClose();
            }}
            sx={{ py: 1.5 }}
          >
            <ListItemText primary="Sign Out" />
          </MenuItem>
    
          <MenuItem
            onClick={handleDeleteAccount}
            sx={{
              color: 'error.main',
              py: 1.5
            }}
          >
            <ListItemText primary="Delete Account" />
          </MenuItem>
        </Menu>
      );
    
      const renderDesktopNavigation = () => (
        <Drawer
          variant="permanent"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            width: isExpanded ? DRAWER_WIDTH : COLLAPSED_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: isExpanded ? DRAWER_WIDTH : COLLAPSED_WIDTH,
              transition: theme => theme.transitions.create(['width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
              }),
              ...styles.drawer.paper,
              overflowX: 'hidden',
              position: 'fixed',  // Add this
              left: 0,           // Add this
            },
            '& + *': {          // Add this - targets the next sibling
              marginLeft: isExpanded ? `${DRAWER_WIDTH}px` : `${COLLAPSED_WIDTH}px`,
              transition: theme => theme.transitions.create(['margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
              }),
            }
          }}
        >
          <SidebarToggle 
            isExpanded={isExpanded}
            isPinned={isPinned}
            onClick={handleToggle}
            IconComponent={IconBlue}
          />
          <List sx={{ mt: 2, px: 1 }}>
            {navigationItems.map((item) => (
              <ListItem
                key={item.path}
                component={Link}
                to={item.path}
                sx={{
                  py: 1.5,
                  px: isExpanded ? 2 : 0,
                  borderRadius: 24,
                  mb: 0.5,
                  ...styles.navItem,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: isExpanded ? 'flex-start' : 'center',
                  color: item.isActive ? 'white' : 'rgba(255,255,255,0.7)'
                }}
              >
                <ListItemIcon 
                  sx={{ 
                    minWidth: isExpanded ? 40 : '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 0
                  }}
                >
                  <Box
                    sx={{
                      ...styles.iconContainer,
                      bgcolor: item.isActive ? 'primary.main' : 'rgba(255,255,255,0.1)'
                    }}
                  >
                    <SvgIcon
                      component={item.icon}
                      inheritViewBox
                      sx={{
                        color: 'white',
                        transition: '0.2s',
                        fontSize: 20
                      }}
                    />
                  </Box>
                </ListItemIcon>
                {isExpanded && (
                  <ListItemText
                    primary={item.title}
                    sx={{
                      m: 0,
                      ml: 2,
                      '& .MuiTypography-root': {
                        fontWeight: 600,
                        color: 'inherit'
                      }
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
    
          <Box
            sx={{
              mt: 'auto',
              p: 2,
              borderTop: '1px solid rgba(255,255,255,0.1)'
            }}
          >
            {renderLanguageSelector()}
    
            <Box
              sx={{
                ...styles.navItem,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
                p: 1,
                borderRadius: '24px'
              }}
              onClick={handleMenuOpen}
            >
              <Avatar
                name={email || 'default'}
                size={32}
                variant="beam"
              />
              {isExpanded && (
                <Typography
                  variant="body2"
                  sx={{
                    color: 'white',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 500
                  }}
                >
                  {userName || email}
                </Typography>
              )}
            </Box>
    
            {isExpanded && tier === "free" && !premium_status && (
              <Button
                component={Link}
                to="/plans"
                fullWidth
                variant="contained"
                sx={{
                  ...styles.premiumButton,
                  mt: 2,
                  borderRadius: '24px'
                }}
              >
                <Typography variant="body2Bold">Try Premium</Typography>
              </Button>
            )}
          </Box>
        </Drawer>
      );
    
      return (
        <>
          {isMobile || isXsMobile ? (
            <>
              {renderMobileHeader()}
              {renderMobileNavigation()}
            </>
          ) : (
            renderDesktopNavigation()
          )}
          {renderMenu()}
        </>
      );
    };
    
    export default Navbar;