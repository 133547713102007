import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import {
  Alert,
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress
} from '@mui/material';
import { URL } from '../../Helpers';

const SlackIntegration = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState({
    hasToken: false,
    hasUser: false,
    stage: 'initial',
    apiUrl: null,
    requestDetails: null,
    errorDetails: null
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { user, loading: userLoading } = useUser();

// In SlackIntegration.js, add these logs:

useEffect(() => {
  const linkSlackAccount = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      console.log('Starting Slack integration with token:', token ? 'present' : 'missing');

      if (!token) {
        throw new Error('No linking token provided');
      }

      if (!user) {
        console.log('No user found, storing token and redirecting to auth');
        localStorage.setItem('slackLinkToken', token);
        localStorage.setItem('slackAuthRedirect', '/link-slack');
        navigate('/auth', { state: { from: '/link-slack' } });
        return;
      }

      // Get Firebase token
      const idToken = await user.getIdToken();
      console.log('Got Firebase token, making API request');

      const response = await fetch(`${URL}/verify-slack-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({ token })
      });

      console.log('API Response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error:', errorData);
        throw new Error(errorData?.error || `API error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Success:', data);

      navigate('/user-profile', {
        state: { 
          slackSuccess: true,
          message: 'Slack account linked successfully! You can now use /poka commands.'
        }
      });

    } catch (err) {
      console.error('Full error details:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (!userLoading) {
    linkSlackAccount();
  }
}, [user, userLoading, location, navigate]);

  // Render function (returns the UI)
  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFF7F1'
      }}
    >
      <Paper 
        elevation={3}
        sx={{ 
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#5C83BF',
          color: 'white',
          borderRadius: 2,
          width: '100%',
          maxWidth: '500px',
          mx: 2
        }}
      >
        {loading ? (
          <>
            <CircularProgress sx={{ color: 'white', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Linking your Slack account...
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
              Stage: {debugInfo.stage}
            </Typography>
          </>
        ) : error ? (
          <>
            <Typography variant="h5" gutterBottom>
              Connection Failed
            </Typography>
            <Alert 
              severity="error" 
              sx={{ 
                mb: 3,
                width: '100%',
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                color: 'white',
                border: '1px solid rgba(255, 0, 0, 0.3)'
              }}
            >
              {error}
            </Alert>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                sx={{ 
                  backgroundColor: 'white',
                  color: '#5C83BF',
                  '&:hover': {
                    backgroundColor: '#f5f5f5'
                  }
                }}
                onClick={() => navigate('/user-profile')}
              >
                Return to Profile
              </Button>
              <Button
                variant="outlined"
                sx={{ 
                  borderColor: 'white',
                  color: 'white',
                  '&:hover': {
                    borderColor: '#f5f5f5',
                    backgroundColor: 'rgba(255,255,255,0.1)'
                  }
                }}
                onClick={() => {
                  localStorage.removeItem('slackLinkToken');
                  window.location.reload();
                }}
              >
                Try Again
              </Button>
            </Box>
          </>
        ) : null}
        
        {/* Debug information in development */}
        {process.env.NODE_ENV === 'development' && (
          <Box sx={{ mt: 3, p: 2, backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 1, width: '100%' }}>
            <Typography variant="caption" component="pre" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              Debug Info:
              {JSON.stringify({
                ...debugInfo,
                // Clean up any sensitive data
                requestDetails: debugInfo.requestDetails ? {
                  ...debugInfo.requestDetails,
                  headers: {
                    ...debugInfo.requestDetails.headers,
                    Authorization: '[REDACTED]'
                  }
                } : null
              }, null, 2)}
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default SlackIntegration;